import MessageBusService from '@4r/mf-host/dist/shared/src/services/MessageBusService';
import { IFileService } from '@4r/mf-contracts-4services';
import { getService } from '@4r/mf-host';
import { Settings } from '../../contexts/types';
import { FileClient, FileCreateRequest, FileParameter } from '../../api/api';
import ApiConfig from '../../api/ApiConfig';
import { IAuthService } from '../../authentication/AuthService';
import { FileMessageTypes, IFileAttributes } from '../FileEvents';
import MessageBusEvent from '../MessageBusEvents';

export default class ApiFileService implements IFileService {
	private fileClient: FileClient;

	private messageBusService: MessageBusService;

	constructor(private readonly authService: IAuthService, settings: Settings) {
		this.fileClient = new FileClient(new ApiConfig(this.authService), settings.API_URL);
		this.messageBusService = getService('MessageBusService');
	}

	async uploadFile(fileId: string, file: Blob, url: string, attributes: IFileAttributes): Promise<void> {
		this.onFileStateUpdated(fileId, FileMessageTypes.FileUploadStartedEvent);
		try {
			const attributesStringified = JSON.stringify(attributes);
			const fileParameter: FileParameter = {
				fileName: fileId,
				data: file,
			};
			await this.fileClient.uploadFile(fileId, fileParameter, attributesStringified);
		} catch (error) {
			const response = error as Response;
			if (response.status) {
				this.onFileStateUpdated(fileId, FileMessageTypes.FileUploadErrorEvent);
			}
			throw error;
		}
		this.onFileStateUpdated(fileId, FileMessageTypes.FileUploadResultEvent);
	}


	async createFile(fileId: string, attributes: IFileAttributes): Promise<void> {
		const attributesStringified = JSON.stringify(attributes);
		const request: FileCreateRequest = { attributes: attributesStringified, fileId };
		await this.fileClient.createFile(request);
	}

	async uploadFileContent(fileId: string, file: Blob): Promise<void> {
		this.onFileStateUpdated(fileId, FileMessageTypes.FileUploadStartedEvent);
		try {
			const fileParameter: FileParameter = {
				fileName: fileId,
				data: file,
			};
			await this.fileClient.uploadFileContent(fileId, fileParameter);
		}
		catch (error) {
			const response = error as Response;
			if (response.status) {
				this.onFileStateUpdated(fileId, FileMessageTypes.FileUploadErrorEvent);
			}
			throw error;
		}
		this.onFileStateUpdated(fileId, FileMessageTypes.FileUploadResultEvent);
	}

	private onFileStateUpdated(fileId: string, type: FileMessageTypes): void {
		this.messageBusService.emit(MessageBusEvent.FileUploadStateUpdated, { fileId, type });
	}

	async deleteFile(fileId: string): Promise<void> {
		await this.fileClient.deleteFile(fileId);
	}
}
