// eslint-disable-next-line @typescript-eslint/ban-types
function debounce<T extends Function>(cb: T, waitMs = 200) {
	let h: NodeJS.Timeout | undefined;

	const callable = (...args: unknown[]) => {
		if (h) {
			clearTimeout(h);
		}
		h = setTimeout(() => cb(...args), waitMs);
	};
	return <T>(<unknown>callable);
}

export default debounce;
