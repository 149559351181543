/* eslint-disable no-undef */
/* eslint-disable max-classes-per-file */
import { AttributeModel } from './apiAssets';
// eslint-disable-next-line max-classes-per-file
import * as generated from './apiAssets';

// eslint-disable-next-line import/prefer-default-export
export class AssetClient extends generated.AssetClient {
	attributes: AttributeModel[] | null | undefined;

	protected transformOptions = (options: any): Promise<RequestInit> => {
		const formData = options.body as FormData;
		const transformedOptions = {
			...options,
			headers: {
				...options.headers,
				Authorization: this.config.getAuthorization(),
			},
			credentials: 'include',
		};

		if (formData instanceof FormData && formData.has('Attributes') && this.attributes) {
			formData.delete('Attributes');

			this.attributes
				.filter(({ value }) => value !== undefined)
				.forEach(({ key, value }, index) => {
					formData.append(`Attributes[${index}].key`, key);
					formData.append(`Attributes[${index}].value`, value!);
				});

			this.attributes = undefined;

			transformedOptions.body = formData;
		}

		return Promise.resolve(transformedOptions);
	};
}
