import { Action } from './Actions';
import { AppState } from './Store';

const reducer = (state: AppState, action: Action): AppState => {
	switch (action.type) {
		case 'setPageName':
			return { ...state, pageName: action.pageName };
		case 'setOnlineStatus':
			return { ...state, online: action.status };
		default:
			return state;
	}
};

export default reducer;
