import { IAuthService } from '../authentication/AuthService';

export interface IApiConfig {
	getAuthorization(): string;
}

export default class ApiConfig implements IApiConfig {
	private authService: IAuthService;

	constructor(authService: IAuthService) {
		this.authService = authService;
	}

	getAuthorization(): string {
		return `Bearer ${this.authService.getAccessToken()}`;
	}
}
