import React, { useContext } from 'react';
import { MFContext, getSharedComponent } from '@4r/mf-host';
import { withRouter } from 'react-router-dom';
import { useConfig } from '@4r/mf-app';
import { AccessContext } from '@4r/module-common-authorization';

import { useFeatureFlag } from '@4r/module-common-feature-flags-mf-host';
import useLifecycleAvailability from "./useLifecycleAvailability";
import { FeatureFlags } from '../../common/consts';
import { useGlobalState } from '../../state/Store';
import OfflineMessage from '../../components/OfflineMessage/OfflineMessage';
import { PageStatus } from '../../state/Actions';


const PropertyLifecyclePage: React.FC = () => {
	const { FormsMicrofrontend } = React.useContext(MFContext);
	const { user } = useContext(AccessContext);
	const [onlineStatus] = useGlobalState('online');
	const isSuperUser = useFeatureFlag(FeatureFlags.PropertyLifecycles);
	const attributes = isSuperUser ? {} : {CreatedDate: {'$range': {'from': '2024-06-15' }}};
	const isAvailableForUser = useLifecycleAvailability();
	if (!isAvailableForUser) {
		return null;
	}

	if (onlineStatus === PageStatus.offline) {
		return <OfflineMessage pageTitle="Property Lifecycles"/>;
	}

	if (!FormsMicrofrontend) {
		return null;
	}
	const StandaloneDashboardView = getSharedComponent('DashboardView');
	if (!StandaloneDashboardView) {
		return null;
	}

	const config = useConfig('FormsMicrofrontend');
	return <StandaloneDashboardView config={config[0]} dashboardName="LifecyclesMaintenanceDashboard"
																	currentUser={user} attributes={attributes}/>;
};

export default withRouter(PropertyLifecyclePage);
