import { AccessContext } from '@4r/module-common-authorization';
import { useCurrentLocation } from '@4r/module-common-geolocation';
import React, { useContext, useEffect, useState } from 'react';
import { useHub } from '@4r/module-common-signalr';
import { useApiClient, utcNow } from '@4r/module-common-mf-app';
import { CurrentGeocoordinatesHub } from '../hubs/CurrentGeocoordinatesHub';
import { AuthContext } from '../authentication/AuthProvider';
import PermissionType from '../authorization/PermissionType';
import { PageStatus } from '../state/Actions';
import { useGlobalState } from '../state/Store';
import { UserClient } from '../api/api';

const GeoCoordinatesLogger: React.FunctionComponent = ({ children }) => {
	const GEOCOORDINATES_LOGGING_FREQUENCY_API = 60000;
	const GEOCOORDINATES_LOGGING_FREQUENCY_HUB = 35000;

	const { currentLocation, isSuccess: isCurrentLocationAvailable, isEnabled, setIsEnabled } = useCurrentLocation(false);
	const [onlineStatus] = useGlobalState('online');
	const { isAuthenticated } = React.useContext(AuthContext);
	const [counter, setCounter] = useState(0);

	const access = useContext(AccessContext);

	const hub = useHub(CurrentGeocoordinatesHub);

	const hasSendCurrentGeoCoordinates = access.has(PermissionType.SendCurrentGeoCoordinates);

	const userClient = useApiClient(UserClient);

	useEffect(() => setIsEnabled(hasSendCurrentGeoCoordinates), [hasSendCurrentGeoCoordinates]);

	useEffect(() => {
		if (!isEnabled) return undefined;

		const timer = setInterval(
			() => setCounter((prevCounter) => prevCounter + 1),
			hub ? GEOCOORDINATES_LOGGING_FREQUENCY_HUB : GEOCOORDINATES_LOGGING_FREQUENCY_API,
		);
		return () => clearInterval(timer);
	}, [hub, isEnabled]);

	useEffect(() => {
		const setCurrentGeoCoordinates = async () => {
			if (currentLocation?.latitude != null && currentLocation?.longitude != null) {
				if (hub) {
					await hub.updateCurrentGeoCoordinates(currentLocation?.latitude, currentLocation.longitude);
				} else {
					await userClient.updateCurrentGeoCoordinates({
						latitude: currentLocation?.latitude,
						longitude: currentLocation?.longitude,
						timestamp: utcNow(),
					});
				}
			}
		};

		if (
			isAuthenticated &&
			isEnabled &&
			currentLocation &&
			currentLocation.latitude != null &&
			currentLocation.longitude != null &&
			isCurrentLocationAvailable &&
			onlineStatus === PageStatus.online &&
			hasSendCurrentGeoCoordinates
		) {
			setCurrentGeoCoordinates();
		}
	}, [counter, isAuthenticated, onlineStatus, hasSendCurrentGeoCoordinates, hub]);

	return <>{children}</>;
};

export default GeoCoordinatesLogger;
