import { UserManager, User } from 'oidc-client-ts';
import { handleCommonError } from '@4r/module-common-error-handler';
import { isExpired as checkTokenFreshness } from 'react-jwt';
import { IAuthStorage } from './AuthStorage';
import { AuthRedirectState } from './AuthRedirectState';

export interface IAuthService {
	getAccessToken(forceSignIn?: boolean): string | undefined;
	/**
	 * Returns token, id_token, expiration data, profile
	 */
	getUser(): Promise<User | null>;
	/**
	 * Redirect user to 3rd party identity server.
	 *
	 * redirectUri: url where we will back after successfull login
	 */
	login(redirectUri: string): Promise<void>;
	/**
	 * Logout with user manager.
	 *
	 * redirectUri: url where we will back after successfull logout
	 */
	logout(redirectUri: string): Promise<void>;

	signInRedirectCallback(): Promise<User>;
}

export default class AuthService implements IAuthService {
	constructor(
		private userManager: UserManager,
		private storage: IAuthStorage,
		private updateIsAuthenticationInProgress: (value: boolean) => void,
	) {
		this.userManager = userManager;
		this.storage = storage;
		this.updateIsAuthenticationInProgress = updateIsAuthenticationInProgress;
		window.addEventListener('storage', async (event: StorageEvent) => {
			if (event.storageArea === localStorage && storage.isUserTokenKey(event.key) && !event.newValue) {
				await this.userManager.clearStaleState();
				await this.logout(`${window.location.origin}/logout`);
			}
		});
	}

	getUser(): Promise<User | null> {
		return this.userManager.getUser();
	}

	getAccessToken(forceSignIn?: boolean): string | undefined {
		const token = this.storage.getAccessToken();
		if (token && checkTokenFreshness(token)) {
			if (forceSignIn ?? false) {
				this.login(window.location.pathname);
			}
			return undefined;
		}
		return token;
	}

	async login(redirectUri: string): Promise<void> {
		await this.userManager.clearStaleState();
		handleCommonError(async () => {
			try {
				this.updateIsAuthenticationInProgress(true);
				const redirectState: AuthRedirectState = { path: redirectUri };
				await this.userManager.signinRedirect({ state: redirectState });
			} finally {
				this.updateIsAuthenticationInProgress(false);
			}
		});
	}

	async logout(redirectUri: string): Promise<void> {
		await this.userManager.clearStaleState();
		try {
			this.updateIsAuthenticationInProgress(true);
			await this.userManager.signoutRedirect({
				post_logout_redirect_uri: redirectUri,
			});
		} finally {
			this.updateIsAuthenticationInProgress(false);
		}
	}

	signInRedirectCallback(url?: string): Promise<User> {
		return this.userManager.signinRedirectCallback(url);
	}
}
