import { AccessContext } from '@4r/module-common-authorization';
import React, { useContext, useEffect, useState } from 'react';
import { getService } from '@4r/mf-host';
import { useApiClient, useQueryString } from '@4r/module-common-mf-app';
import { AuthContext } from '../authentication/AuthProvider';
import { UserClient, UserModel } from '../api/api';

export interface IUserData {
	current: UserModel | null;
	onVendorChange: (id: string) => void;
}

const initialData: IUserData = {
	current: null,
	onVendorChange: () => null,
};

const UserContext = React.createContext<IUserData>(initialData);

const UserProvider: React.FunctionComponent = ({ children }) => {
	const [data, setData] = useState<IUserData>(initialData);
	const userClient = useApiClient(UserClient);
	const access = useContext(AccessContext);
	const auth = useContext(AuthContext);
	const [currentUser, setCurrentUser] = useState<UserModel | null>(null);
	const [vendorIdUrl, setVendorIdUrl] = useQueryString('vendor');
	const onVendorChange = (id: string) => {
		userClient.getCurrent(id).then((user) => {
			access.setUser(user);
			setCurrentUser(user);
		});
		setVendorIdUrl(id);
	};

	useEffect(() => {
		if (auth.authService) {
			const token = auth.authService.getAccessToken();
			if (token) {
				// Load user current information
				userClient.getCurrent(null).then((user) => {
					access.setUser(user);
					setCurrentUser(user);
				});

				// Announce token to all MFs
				getService('TokenService').setToken(token);
			}
		}
	}, [auth]);

	useEffect(() => {
		const vendorSwitched = data.current?.vendor?.id !== currentUser?.vendor?.id;
		setData(currentUser ? { onVendorChange, current: currentUser } : initialData);
		if (vendorSwitched) {
			getService('MessageBusService').emit('vendor-profile-switched', null);
		}
	}, [currentUser]);

	useEffect(() => {
		if (vendorIdUrl) {
			onVendorChange(vendorIdUrl);
		}
	}, [vendorIdUrl]);

	return <UserContext.Provider value={data}>{children}</UserContext.Provider>;
};

export { UserProvider, UserContext };
