enum PermissionType {
	ViewAssets = '15d7de8c-8600-445d-a87c-8691dccd5531',
	AddAsset = '8c5fdd02-8d49-4d37-8022-4129fc32a1ea',
	EditAsset = '165a457e-8bca-4cff-8af3-1ec314061689',

	ViewScopeManagement = '5f2e0b6d-575d-4cf8-ba91-0b4c218832c6',
	ViewTerritoryData = '88145eb7-83aa-4483-a27b-4e437d7cedbf',
	CreateMarketTerritory = '76c32c32-de4a-4b29-8679-55d7607af2a5',
	EditMarketTerritory = '776ba61f-d12d-4445-ad50-40dd5a6aab5b',
	CreatePod = 'e8a2fe83-b67e-4f8b-9ba9-39209252c1b2',
	EditPod = '47412e38-5097-4526-9efd-aef6923cc628',
	DeletePod = 'db5c1c3c-65b3-4d53-a5fa-d71dfb5e7eda',
	CreateServiceTerritory = '1de54d18-2cf5-4724-bb48-fe9d26f3e793',
	EditServiceTerritory = 'd225f523-bd0d-4b2e-b18a-425d2ef86065',
	DeleteServiceTerritory = '564e246a-30a0-46ee-9274-37fe6c8da19d',
	TransferProperties = 'b1d7e853-60b7-4b2f-917c-43506da9cd01',
	AssignRegionTerritoryUser = '2d70fcbf-bfae-4fea-b23b-8828fe62411c',
	AssignDistrictTerritoryUser = 'aec1bd0a-9177-4c69-b30c-32c2204c8c56',
	AssignMarketTerritoryUser = '1bcae0e0-8393-48a2-9a99-9f3ef270d9cd',
	AssignPodUser = '2baa47af-2bf5-4574-bb88-a159a13f7362',
	AssignServiceTerritoryUser = 'cf4763f2-147a-467f-8871-5f3a0d36b408',

	ViewResourceManagement = '06aea16d-3cd9-453c-bbed-952728ca9ce0',
	EditResource = '1b2793b0-d3c4-48c8-8524-f000185792a7',

	ViewScheduling = 'ce135fbe-d2d7-445a-92d4-50379d44948f',
	SendCurrentGeoCoordinates = '1f7bc338-660e-4bbb-81fc-1899178fc8b1',

	AccessProperties = 'd164939f-e7f2-4fa4-86c7-592cc22a57e2',
	RequestAccessCode = '93e22e8c-26f0-4a89-84e4-707d401c7fc5',

	ViewScheduledWorkOrders = '1940afb3-03f3-4ffc-b7a9-5d47516fea29',
	ViewTimeCard = '30795c84-e1e0-4cc5-a631-50b2c83a95e0',
	ViewInventory = '630dedf6-279a-4a9b-bdcc-508fc22cf6a5',
	ViewVendors = '63ba2580-f223-469a-bee6-36878eecef45',
	ViewAssociatedVendor = 'b9bd0697-5ed4-4f7f-961d-6115e5717086',
	ViewApprovals = '8299271f-cab9-43c8-be61-15a574d0eb73',
	CreateReview = '07073a5e-3c52-4c86-900e-3c27402089ed',
	ReviewApproval = 'd563d7a5-e872-4236-98c8-4d304c500862',

	ViewLifecycles = 'c6e36881-0aba-419d-ab7e-f6b772c19d35',
	ViewPodLifecycles = '2029d2ae-74c5-4762-8c66-055fc20da662',
	ViewLifecycleDashboard = '2c8e31ad-ff19-49f5-950b-c682b13e6c69',

	CreateConditionIssue = '704822fa-34cf-4584-a8a6-41c67838900e',
	ReadConditionIssue = '378bcdee-e0c8-405b-8d1f-8527e4a29e18',
	EditConditionIssue = 'f6674536-0b43-4caf-863c-33503844c442',
	RemoveConditionIssue = '067a6778-ab9e-4980-89f9-93fc5388cbe1',
	UpdateConditionIssueStatus = '8099883a-f480-447f-b2af-9d8b40b66709',

	CreateRemedy = '6ff40831-f34c-41af-be39-4f91f25c7965',
	ReadRemedy = '73dd0df2-6751-47ee-b28f-713d9c9def0a',
	EditRemedy = 'ad1907f9-7439-4675-842e-05951d45be77',
	RemoveRemedy = 'a9ea7a41-76ed-4228-8add-206574a1b5e3',
	UpdateRemedyStatus = '22465f78-61b4-4a9a-add0-d47518aa67a0',

	CreateLocationType = 'e1236bbe-82d1-4a61-b0f1-f121e564d9d8',
	ReadLocationType = '826f76e9-83c0-4f89-ada2-4c2be023a234',
	EditLocationType = '6ab8ee8b-2361-47eb-ae45-7918fdd50f84',
	RemoveLocationType = '04136b1c-e88f-42a8-a616-892cdbbc370b',
	UpdateLocationTypeStatus = 'fdf1d34d-c25f-4342-a036-37b198c3cf5d',

	CreateAssetType = '8ff37780-68c6-48cd-9417-0cd867f4c78c',
	ReadAssetType = 'bb971471-10ab-42a0-95f9-5f78a7e6f751',
	EditAssetType = '4516d793-8991-40f1-8dfd-e9afa84c2f89',
	RemoveAssetType = '7402d64d-c86a-4ab3-a571-0802aaacba26',
	UpdateAssetTypeStatus = '26c1fc50-94e7-46a6-8541-5a233c3dd107',

	PublishChanges = 'a690b490-2364-4a47-b635-caa13069d4de',

	ViewTeams = '84704759-af74-45ef-852c-7513bb09eee5',
	CreateTeam = 'd841626e-61bd-46a5-a1c8-649c96a348e0',
	EditTeam = 'bfffbe63-e493-4bed-a604-7d48961e2665',

	PersonaExternalTechnician = '7be3f2bc-92ce-4770-9d18-0b215c3051bd',
	PersonaExternalAdmin = 'ef31986d-72a5-46d4-b7e2-fc12a075bbde',
}

export default PermissionType;
