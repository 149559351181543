import dayjs, { Dayjs } from 'dayjs';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

function useSessionStorage(key: string, defaultValue: Dayjs): [Dayjs, Dispatch<SetStateAction<Dayjs>>] {
	const stored = sessionStorage.getItem(key);
	const initial = stored ? dayjs(stored).tz('UTC') : defaultValue;
	const [value, setValue] = useState<Dayjs>(initial);

	useEffect(() => {
		sessionStorage.setItem(key, dayjs(value).toJSON());
	}, [key, value]);

	return [value, setValue];
}

export default useSessionStorage;
