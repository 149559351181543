import { IDBPDatabase } from 'idb';
import { v4 } from 'uuid';
import { AssetReferences, IAssetService } from '@4r/mf-contracts-4services';
import { CreateAssetCommand, DeleteFileCommand, UpdateAssetCommand } from '../../api/api';
import BaseOfflineCapableService from '../Offline/BaseOfflineCapableService';
import { ICommandHandlerCollection } from '../Offline/ICommandHandlerCollection';
import { ICommand } from '../Offline/ICommand';
import { CommandTitle, CommandType } from '../CommandTypes';
import {
	AssetModel,
	CreateAssetRequest,
	FileParameter,
	PropertyModel,
	SearchAssetRequest,
	SearchAssetResponse,
	UpdateAssetRequest,
} from '../../api/apiAssets';
import StoreName from '../StoreName';
import { ASSET_TYPES_KEY, ALL_ASSET_TYPES_ATTRIBUTES } from '../../common/consts';
import { IOrderCommandService } from '../OrderCommandService/IOrderCommandService';
import { CreateAndUploadFileCommand } from '../FileService/OfflineFileServiceDecorator';
import { ICurrentOrderIdService } from '../CurrentOrderIdService';

export default class OfflineAssetServiceDecorator extends BaseOfflineCapableService implements IAssetService {
	constructor(
		private target: IAssetService,
		private commandService: IOrderCommandService,
		private orderIdService: ICurrentOrderIdService,
		db: IDBPDatabase,
		handlers: ICommandHandlerCollection,
	) {
		super(db);

		handlers.add(CommandType.AssetCreate, async (command: ICommand<CreateAssetCommand>) => {
			await this.commandService.submitCommand(command);
		});

		handlers.add(CommandType.AssetUpdate, async (command: ICommand<UpdateAssetCommand>) => {
			this.commandService.submitCommand(command);
		});
	}

	async addAsset(payload: CreateAssetRequest): Promise<string> {
		const assetId = payload.id ?? v4();
		const orderId = this.orderIdService.getCurrentOrderId();
		if (orderId !== null) {
			await this.addCommand<CreateAssetCommand>(
				{
					payload: {
						...payload,
						id: assetId,
					},
					commandType: CommandType.AssetCreate,
					commandTitle: CommandTitle.AssetCreate,
				},
				StoreName.Commands,
			);
		} else {
			await this.target.addAsset(payload);
		}

		return assetId;
	}

	async updateAsset(payload: UpdateAssetRequest): Promise<void> {
		const orderId = this.orderIdService.getCurrentOrderId();
		if (orderId != null) {
			await this.addCommand<UpdateAssetCommand>({
				payload,
				commandType: CommandType.AssetUpdate,
				commandTitle: CommandTitle.AssetUpdate,
			});

			if (payload.removedPhotoIds && payload.removedPhotoIds.length > 0) {
				await Promise.all(
					payload.removedPhotoIds.map(async (photo) => {
						await this.addCommand<DeleteFileCommand>(
							{
								payload: { id: photo },
								orderId,
								commandType: CommandType.FileDelete,
								commandTitle: CommandTitle.FileDelete,
							},
							StoreName.Commands,
						);
					}),
				);
			}
		} else {
			await this.target.updateAsset(payload);
		}
	}

	async getAssets(request: SearchAssetRequest): Promise<SearchAssetResponse> {
		try {
			const result = await this.target.getAssets(request);
			await Promise.all(result.assets.map((asset) => this.db?.put(StoreName.Assets, asset)));
			return result;
		} catch (error) {
			const assets = (await this.db?.getAll(StoreName.Assets)) as AssetModel[];
			const { pageIndex, pageSize } = request.paging ?? {};
			let hasNextPage = false;
			let filtered: AssetModel[] = assets;

			if (request.propertyId) {
				filtered = assets?.filter((asset) => asset.property?.id === request.propertyId);
			}
			if (pageIndex !== undefined && pageSize) {
				const start = (pageIndex - 1) * pageSize;
				const end = pageIndex * pageSize;
				hasNextPage = end < filtered.length;
				filtered = filtered.slice(start, end);
			}
			return { assets: filtered || [], hasNextPage, totalCount: filtered.length };
		}
	}

	async getPropertyInfo(propertyId: string): Promise<PropertyModel> {
		try {
			const propertyInfo = await this.target.getPropertyInfo(propertyId);
			await this.db?.put(StoreName.PropertyInfo, propertyInfo, propertyId);
			return propertyInfo;
		} catch (error) {
			const propertyInfo = await this.db?.get(StoreName.PropertyInfo, propertyId);
			return propertyInfo;
		}
	}

	async getAssetReferences(): Promise<AssetReferences> {
		try {
			const references = await this.target.getAssetReferences();
			await this.db?.put(StoreName.AssetTypesAllAttributes, references.allAssetTypesAttributes, ALL_ASSET_TYPES_ATTRIBUTES);
			await this.db?.put(StoreName.AssetTypes, references.assetTypes, ASSET_TYPES_KEY);
			return references;
		} catch (error) {
			const assetTypes = await this.db?.get(StoreName.AssetTypes, ASSET_TYPES_KEY);
			const allAssetTypesAttributes = await this.db?.get(StoreName.AssetTypesAllAttributes, ALL_ASSET_TYPES_ATTRIBUTES);
			return {
				assetTypes,
				allAssetTypesAttributes,
			};
		}
	}

	async uploadAssetFile(
		assetId: string | undefined,
		assetTypeId: string | undefined,
		propertyId: string | undefined,
		photos: FileParameter[] | null | undefined,
	): Promise<void> {
		const orderId = this.orderIdService.getCurrentOrderId();
		if (orderId && photos && photos.length > 0) {
			await Promise.all(
				photos.map(async (photo) => {
					await this.addCommand<CreateAndUploadFileCommand>(
						{
							payload: {
								fileId: v4(),
								file: photo.data,
								attributes: { AssetId: assetId, AssetTypeId: assetTypeId, PropertyId: propertyId },
								url: URL.createObjectURL(photo.data),
								orderId,
							},
							orderId,
							commandType: CommandType.FileCreate,
							commandTitle: CommandTitle.FileCreate,
						},
						StoreName.Commands,
					);
				}),
			);
		}
	}
}
