import { IDBPDatabase } from 'idb';
import MessageBusService from '@4r/mf-app/dist/shared/src/services/MessageBusService';
import { getService } from '@4r/mf-app';
import { timestampSorter } from '@4r/module-common-mf-app';
import { v1 as uuidv1 } from 'uuid';
import StoreName from '../StoreName';
import { OfflineEvents } from './BaseOfflineCapableService';
import { ICommand } from './ICommand';
import MessageBusEvent from '../MessageBusEvents';
import debounce from '../../common/debounce';
import backoffDelay from '../../common/backoffDelay';
import delay from '../../common/delay';
import LoggerService, { LogLevel } from '../LoggerService';
import { ApiException } from '../../api/api';
import CommandHandlerCollection from './CommandHandlerCollection';
import { CommandType } from '../CommandTypes';

const RETRY_COUNT_MAX = 6;
const RETRY_BACKOFF_CYCLE = 3;
const SYNC_INTERVAL_MS = 10000;
const SYNC_DEBOUNCE_MS = 500;

export default class CommandQueueService {
	private readonly messageBus: MessageBusService;

	private started: boolean;

	private running: boolean;

	private retriggerSync: boolean;

	private lastError?: string;

	private onStop?: () => void = undefined;

	private logger: LoggerService;

	constructor(public db: IDBPDatabase, public readonly storeName: StoreName, private handlers: CommandHandlerCollection) {
		this.messageBus = getService('MessageBusService');
		this.started = false;
		this.running = false;
		this.retriggerSync = false;
		this.logger = new LoggerService(`CommandQueue<${storeName}>`, LogLevel.INFO);
		this.logger.logInfo(`Created instance ${uuidv1()}`);
	}

	isStarted(): boolean {
		return this.started;
	}

	isRunning(): boolean {
		return this.running;
	}

	getLastError(): string | undefined {
		return this.lastError;
	}

	start(): void {
		if (this.started) {
			return;
		}
		this.started = true;

		// Keep caling sync loop in interval
		const timer = setInterval(() => this.run(), SYNC_INTERVAL_MS);

		// Trigger sync loop when command is added (do eliminate the lag), wrap in debouncing
		const subscription = this.messageBus.on(
			OfflineEvents.CommandAddedEvent,
			debounce(() => this.run(true), SYNC_DEBOUNCE_MS),
		);

		this.onStop = () => {
			clearInterval(timer);
			subscription.removeListener();
		};

		this.messageBus.emit(OfflineEvents.QueueStartEvent, this.storeName);
	}

	stop(): void {
		if (!this.started) {
			return;
		}

		if (this.onStop) {
			this.onStop();
			this.onStop = undefined;
		}
		this.started = false;
		this.messageBus.emit(OfflineEvents.QueueStopEvent, this.storeName);
	}

	async getCommands(): Promise<ICommand<unknown>[]> {
		return this.db.getAll(this.storeName);
	}

	private async handleCommandWithRetry(command: ICommand<unknown>, error: unknown = undefined, attemptNo = 0): Promise<boolean> {
		if (attemptNo < RETRY_COUNT_MAX) {
			if (attemptNo === 0) {
				// first command handling, notify
				this.messageBus.emit(OfflineEvents.CommandPendingEvent, command.commandId);
			}
			try {
				const handler = this.handlers.handlers[command.commandType];
				if (handler) {
					this.logger.logInfo(`Handling command: [${command.commandType}], attempt: ${attemptNo}`, command);
					await handler(command);
				}
				await this.db.delete(this.storeName, `${command.commandType}-${command.commandId}`);
				this.messageBus.emit(OfflineEvents.CommandRemovedEvent, command.commandId);

				// continue to next command
				return true;
			} catch (e) {
				if (!navigator.onLine) {
					// Note: Do not retry if offline, stop the sync for now
					return false;
				}

				const apiError = e as Response;
				// Note: Do NOT increment attemptNo if the token was expired (during refresh), or the app was offline at the time of the call
				const idleRetry = apiError.status === undefined || apiError.status === 401;

				if (!idleRetry) {
					this.captureLastError(e);
				}

				const delayTask = idleRetry
					? delay(2000) // In the 401 scenario we want a constant delay
					: backoffDelay(attemptNo % RETRY_BACKOFF_CYCLE); // Run delay function before next attempt - with cyclic backoff

				await delayTask;

				return this.handleCommandWithRetry(command, e, attemptNo + (idleRetry ? 0 : 1));
			}
		}

		// Note: the command failed still after several retries

		// Removed because it caused to lose multiple commands in queue and blocked logging, might be reverted when commands are moved to ASB queue
		// this.stop();

		// If all attepts end with fail flag was left it false state, so event is emit with notification data
		this.messageBus.emit(MessageBusEvent.OfflineCommandSyncingFailed, command.commandTitle);

		if (command.commandType !== CommandType.LogCreate) {
			this.messageBus.emit(MessageBusEvent.UploadLogsEvent, { command, error });
		}

		// Changed to unblock queue and , might be reverted when commands are moved to ASB queue
		await this.db.delete(this.storeName, `${command.commandType}-${command.commandId}`);
		this.messageBus.emit(OfflineEvents.CommandRemovedEvent, command.commandId);
		return true;
	}

	private captureLastError(e: unknown) {
		const apiException = e as ApiException;
		if (apiException.status) {
			this.lastError = `Server Error: ${apiException.status}: ${apiException.name}`;
		} else {
			this.lastError = `Error: ${e}`;
		}
	}

	private async handleCommands(nextCommands: ICommand<unknown>[]): Promise<boolean> {
		const command = nextCommands.shift();
		if (command) {
			const cont = await this.handleCommandWithRetry(command);
			if (cont) {
				return this.handleCommands(nextCommands);
			}
		}
		return false;
	}

	protected async run(canRetriggerSync = false): Promise<void> {
		// if not started or not online
		if (!this.started || !navigator.onLine) {
			return;
		}

		// if already running
		if (this.running) {
			if (canRetriggerSync) {
				this.retriggerSync = true;
			}
			return;
		}

		this.running = true;
		this.lastError = undefined;
		try {
			const commands = await this.getCommands();
			// async operations in order one by one
			if (commands.length) {
				this.logger.logInfo(`Running sync loop with ${commands.length}`);

				this.messageBus.emit(OfflineEvents.QueueRunStartEvent, this.storeName);
				try {
					const orderedCommands = commands.sort(timestampSorter);
					await this.handleCommands(orderedCommands);
				} finally {
					this.messageBus.emit(OfflineEvents.QueueRunStopEvent, this.storeName);
				}
			}
		} finally {
			this.running = false;
		}

		// retrigger sync loop when some new commands arrived during prev loop run
		if (this.retriggerSync) {
			this.retriggerSync = false;
			// do not await
			await this.run();
		}
	}
}
