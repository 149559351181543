import React, { useRef } from 'react';
import { Pill, PriorityPill, COLOR, OpenInNew, usePriorityMapping } from '@4r/module-common-ant-components';
import { Popover } from 'antd';
import { Link } from 'react-router-dom';

import { ConditionIssueInstanceModel } from '../../api/api';

import './ConditionIssuesTooltip.scss';
import styles from './ConditionIssueTooltip.module.scss';
import ConditionIssueTooltipCard from './ConditionIssueTooltipCard';
import useTaxonomy from '../../common/useTaxonomy';
import { TaxonomyNames } from '../../common/consts';

interface ConditionIssuesTooltipProps {
	orderId: string;
	conditionIssues: ConditionIssueInstanceModel[];
	count: number;
}

const POPOVER_WIDTH = '372px'; // 16 padding on each side, 340px content width
const BACKGROUND_COLOR = '#041624';
const MAX_NUMBER_OF_VISIBLE_CIIS = 5;

const ConditionIssuesTooltip = ({ orderId, conditionIssues, count }: ConditionIssuesTooltipProps) => {
	const popoverRef = useRef<HTMLDivElement>(null);
	const buttonRef = useRef<HTMLButtonElement>(null);
	const [priorityTaxonomy] = useTaxonomy(TaxonomyNames.PRIORITY);
	const { getPriority } = usePriorityMapping(priorityTaxonomy?.items);

	return (
		<Popover
			overlayClassName="work-order-list__cii-popover"
			overlayStyle={{ width: POPOVER_WIDTH }}
			overlayInnerStyle={{ width: POPOVER_WIDTH }}
			arrowPointAtCenter
			autoAdjustOverflow
			placement="topRight"
			trigger="click"
			color={BACKGROUND_COLOR}
			content={
				<div ref={popoverRef}>
					<div className="work-order-list__popover-content">
						{conditionIssues.length === 0 ? (
							<ConditionIssueTooltipCard title="No issues" />
						) : (
							conditionIssues.slice(0, MAX_NUMBER_OF_VISIBLE_CIIS).map((conditionIssue) => (
								<ConditionIssueTooltipCard
									key={conditionIssue.id}
									title={<span title={conditionIssue.name}>{conditionIssue.name}</span>}
									extra={
										<div className={styles.extras}>
											<Pill.Generic label={conditionIssue.statusName} size="small" fillColor={COLOR.Blue} />
											<PriorityPill className={styles.priority} priority={getPriority(conditionIssue.priorityId)} isOnlyIcon size="small" />
										</div>
									}
								>
									{conditionIssue.notes}
								</ConditionIssueTooltipCard>
							))
						)}
						{conditionIssues.length > MAX_NUMBER_OF_VISIBLE_CIIS ? (
							<span className={styles.more}>{`+ ${conditionIssues?.length - MAX_NUMBER_OF_VISIBLE_CIIS} more...`}</span>
						) : (
							''
						)}
					</div>
					<div className={styles.link}>
						<Link to={`/orders/${orderId}?tabId=condition-issues`}>
							<div className={styles['link-content']}>
								<OpenInNew size="small" />
								<span>View Details</span>
							</div>
						</Link>
					</div>
				</div>
			}
		>
			<button ref={buttonRef} type="button">
				{count}
			</button>
		</Popover>
	);
};

export default ConditionIssuesTooltip;
