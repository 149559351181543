import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Map, Pin } from '../../components/Map';

export type OrderMapProps = {
	orders: IOrderOnMap[];
	selectedId: string | null;
	onSelect: (id: string | null) => void;
	isMapVisible: boolean;
	isMobileOrTable: boolean;
};

export interface IOrderOnMap {
	id: string;
	latitude: number;
	longitude: number;
}

const defaultMapHeight = '16rem';

const OrderMap = (props: OrderMapProps) => {
	const { orders, selectedId, onSelect, isMapVisible, isMobileOrTable } = props;
	const [pins, setPins] = useState<Pin[]>([]);

	const mapDivRef = useRef<HTMLDivElement>(null);

	const updateMapHeight = (height: string) => {
		// update height using ref for performance when dragging
		if (mapDivRef.current) mapDivRef.current.style.height = height;
	};

	useEffect(() => {
		const newPins = orders.map<Pin>((x) => ({
			id: x.id,
			lat: x.latitude,
			lng: x.longitude,
			isActive: false,
		}));

		setPins(newPins);
	}, [orders]);

	useEffect(() => {
		updateMapHeight(isMapVisible ? defaultMapHeight : '0');
	}, [isMapVisible]);

	let mapDivData = { height: 0, bottom: 0, transitionProperty: '' };
	const drag = (e: MouseEvent) => updateMapHeight(`${e.clientY - mapDivData.bottom + mapDivData.height}px`);
	const stopDrag = () => {
		window.removeEventListener('mousemove', drag);
		if (mapDivRef.current) mapDivRef.current.style.transitionProperty = mapDivData.transitionProperty;
	};
	const startDrag = () => {
		if (mapDivRef.current) {
			const rect = mapDivRef.current.getBoundingClientRect();
			// disable transitions before drag&drop to improve performance and restore it afterwards
			mapDivData = { height: rect.height, bottom: rect.bottom, transitionProperty: mapDivRef.current.style.transitionProperty };
			mapDivRef.current.style.transitionProperty = 'none';
			window.addEventListener('mousemove', drag);
			window.addEventListener('mouseup', stopDrag, { once: true });
		}
	};

	return (
		<div
			className={`flex flex-col bg-white relative map-container ease-in-out duration-300 ${isMobileOrTable && isMapVisible ? 'mt-4' : ''}`}
			style={{ height: defaultMapHeight, transitionProperty: 'height', borderBottom: isMapVisible ? '1px solid #E5E7E9' : 'none' }}
			ref={mapDivRef}
		>
			<div className="p-4 h-full">
				<Map center={{ lat: 50.049683, lng: 19.944544 }} zoom={11} pins={pins} selectedId={selectedId} onClick={onSelect} />
			</div>

			{isMapVisible && (
				<Button
					className="absolute"
					style={{ bottom: 16, left: '50%', transform: 'translate(-50%, 50%)' }}
					shape="circle"
					size="small"
					onMouseDown={startDrag}
					icon={
						<div className="flex flex-col">
							<CaretUpOutlined style={{ fontSize: '10px' }} />
							<CaretDownOutlined style={{ fontSize: '10px', marginTop: '-3px' }} />
						</div>
					}
				/>
			)}
		</div>
	);
};

export default OrderMap;
