import delay from './delay';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function backoffDelay(attemptNo: number): Promise<void> {
	// Waiting time will be increasing based on logarithm function
	const seconds = (Math.log(attemptNo) + 1) * 2.5;
	return delay(seconds * 1000);
}

export default backoffDelay;
