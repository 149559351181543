import { AccessContext } from '@4r/module-common-authorization';
import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { MFContext } from '@4r/mf-host';
import { useGlobalState } from '../../state/Store';
import { PageStatus } from '../../state/Actions';
import OfflineMessage from '../../components/OfflineMessage/OfflineMessage';
import { ReferenceDataContext } from '../../contexts/ReferenceDataContext';
import { TaxonomyNames } from '../../common/consts';

const AssetsPage: React.FunctionComponent = () => {
	const PAGE_TITLE = 'Assets';

	const { AssetMicrofrontend } = useContext(MFContext);
	const access = useContext(AccessContext);
	const { globalData } = useContext(ReferenceDataContext);
	const [onlineState] = useGlobalState('online');
	const assetTypes = globalData.taxonomies?.[TaxonomyNames.ASSET_TYPE].items ?? null;

	if (onlineState === PageStatus.offline) {
		return <OfflineMessage pageTitle={PAGE_TITLE} />;
	}

	if (!AssetMicrofrontend) {
		return null;
	}

	return (
		<AssetMicrofrontend
			currentUser={access.user}
			assetTypes={assetTypes}
			onlineState={onlineState === PageStatus.online}
			globalData={globalData}
		/>
	);
};

export default withRouter(AssetsPage);
