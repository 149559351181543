import React from 'react';

const PinRed: React.FC = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="26"
      height="32"
      viewBox="0 0 26 32"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <defs>
        <path
          id="IconPinRed-path-1"
          d="M13 .5C6.096.5.5 6.173.5 13.164l.004.275c.111 4.038 2.364 7.93 6.97 13.34l1.713 1.968c.726.828 1.439 1.638 1.468 1.67a3.137 3.137 0 004.597.156l.198-.216 2.074-2.342c.288-.328.55-.628.806-.927 4.661-5.42 7.028-9.462 7.164-13.588l.006-.336C25.5 6.172 19.904.5 13 .5z"
         />
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g>
          <use fill="#FFF" xlinkHref="#IconPinRed-path-1" />
        </g>
        <path
          fill="#27475F"
          fillRule="nonzero"
          d="M13 1c6.627 0 12 5.447 12 12.164 0 1.592-.397 3.185-1.058 4.755L21 15.99l.001-3.992a1 1 0 00-1-1h-3a1 1 0 00-1 1v.714l-2.077-1.36a2 2 0 00-2.182-.007l-9.773 6.316C1.361 16.174 1 14.67 1 13.164 1 6.447 6.374 1 13 1z"
         />
        <path
          fill="#E02947"
          fillRule="nonzero"
          d="M13.262 12.124l.114.065 3.648 2.391v-1.99a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v3.956l3.5 2.294c-2 4.108-5.65 8.016-8.513 11.259a2.638 2.638 0 01-3.98-.01c-2.958-3.39-6.677-7.36-8.651-11.5l9.905-6.404.114-.063a1 1 0 01.863.002zm-1.402 9.23h-1.423l-.093.006a.697.697 0 00-.602.683v1.41l.007.093c.046.334.34.596.688.596h1.423l.093-.006a.697.697 0 00.602-.683v-1.41l-.007-.093a.699.699 0 00-.688-.596zm3.703 0H14.14l-.093.006a.697.697 0 00-.602.683v1.41l.007.093c.046.334.34.596.688.596h1.423l.093-.006a.697.697 0 00.601-.683v-1.41l-.006-.093a.699.699 0 00-.688-.596zm0-3.606H14.14l-.093.006a.697.697 0 00-.602.683v1.41l.007.093c.046.334.34.596.688.596h1.423l.093-.006a.697.697 0 00.602-.683v-1.41l-.007-.093a.699.699 0 00-.688-.596zm-3.703-.01h-1.423l-.093.006a.697.697 0 00-.602.682v1.41l.007.093c.046.334.34.596.688.596h1.423l.093-.006a.697.697 0 00.602-.683v-1.41l-.007-.093a.699.699 0 00-.688-.596z"
         />
      </g>
    </svg>
  )

export default PinRed;