import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { getService } from '@4r/mf-app';
import { ReactComponent as OnlineSVG } from '../../assets/svg/online.svg';
import { ReactComponent as OfflineSVG } from '../../assets/svg/offline.svg';
import { ReactComponent as SyncingSVG } from '../../assets/svg/syncing.svg';
import { ReactComponent as SyncingErrorSVG } from '../../assets/svg/syncing_error.svg';
import { PageStatus } from '../../state/Actions';
import { OfflineEvents } from '../../services/Offline/BaseOfflineCapableService';

const getOnlineStatus = () => (navigator.onLine ? PageStatus.online : PageStatus.offline);

const OnlineStatus = () => {
	const [onlineStatus, setOnlineStatus] = useState<PageStatus>(getOnlineStatus());

	const tryNextState = (targetState?: PageStatus) => {
		setOnlineStatus((prevState) => {
			if (targetState === PageStatus.error || prevState === PageStatus.error) {
				return PageStatus.error;
			}
			if (targetState === PageStatus.syncing) {
				return PageStatus.syncing;
			}
			return getOnlineStatus();
		});
	};

	// track the sync loop progress
	useEffect(() => {
		const bus = getService('MessageBusService');

		const sub1 = bus.on(OfflineEvents.QueueRunStartEvent, () => tryNextState(PageStatus.syncing));
		const sub2 = bus.on(OfflineEvents.QueueRunStopEvent, () => tryNextState(PageStatus.online));
		const sub3 = bus.on(OfflineEvents.QueueStopEvent, () => tryNextState(PageStatus.error));

		return () => {
			sub1.removeListener();
			sub2.removeListener();
			sub3.removeListener();
		};
	}, []);

	// track the online / offline status changes
	useEffect(() => {
		const onlineHandler = () => tryNextState();

		window.addEventListener('online', onlineHandler);
		window.addEventListener('offline', onlineHandler);

		return function cleanup() {
			window.removeEventListener('online', onlineHandler);
			window.removeEventListener('offline', onlineHandler);
		};
	}, []);

	switch (onlineStatus) {
		case PageStatus.offline:
			return (
				<>
					<Row justify="center">
						<OfflineSVG />
					</Row>
					<Row justify="center" className="left-menu__column__status left-menu__column__status--offline">
						Offline
					</Row>
				</>
			);

		case PageStatus.syncing:
			return (
				<>
					<Row justify="center" className="left-menu__column__status--syncing">
						<SyncingSVG />
					</Row>
					<Row justify="center" className="left-menu__column__status">
						Syncing
					</Row>
				</>
			);

		case PageStatus.error:
			return (
				<>
					<Row justify="center" className="left-menu__column__status">
						<SyncingErrorSVG />
					</Row>
					<Row justify="center" className="left-menu__column__status">
						Error
					</Row>
				</>
			);

		default:
			return (
				<>
					<Row justify="center" className="left-menu__column__status">
						<OnlineSVG />
					</Row>
					<Row justify="center" className="left-menu__column__status">
						Online
					</Row>
				</>
			);
	}
};

export default OnlineStatus;
