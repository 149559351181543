import React from 'react';
import { Button, Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import { ReactComponent as OfflineSVG } from '../../assets/svg/offline.svg';
import './OfflineMessage.scss';

interface IOfflineMessage {
	pageTitle: string;
}

const OfflineMessage: React.FC<IOfflineMessage> = ({ pageTitle }) => {
	const links = [
		{ title: 'Work Orders', route: '/orders' },
	];

	return (
		<div className="offline-message">
			<div className="offline-message__content">
				<Row />
				<Row justify="center">
					<Col>
						<Row justify="center" className="offline-message__icon">
							<OfflineSVG />
						</Row>
						<Row justify="center" className="offline-message__title">
							You are currently in offline mode.
						</Row>
						<Row justify="center">
							<Col>
								<strong>{pageTitle}</strong> is only available in online mode.
							</Col>
						</Row>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="offline-message__footer">
							Go <strong>online</strong> or use one of the <strong>functionalities available in offline mode</strong>:
						</div>
						<Row justify="center" gutter={8}>
							{links.map((link) => (
								<Col>
									<Button>
										<Link key={link.route} to={link.route}>
											{link.title}
										</Link>
									</Button>
								</Col>
							))}
						</Row>
					</Col>
				</Row>
			</div>
		</div>
	);
};

export default OfflineMessage;
