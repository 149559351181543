import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { AccessContext } from '@4r/module-common-authorization';
import { useConfig, useApiClient } from '@4r/module-common-mf-app';
import { CloseOutlined } from '@4r/module-common-ant-components';
import { WarningOutlined } from '@ant-design/icons';
import { getService } from '@4r/mf-host';

import PermissionType from '../authorization/PermissionType';
import { AppointmentClient, AppointmentModel, OrderClient, OrderDetailsResponse } from '../api/api';
import { AuthContext } from '../authentication/AuthProvider';
import StoreName from '../services/StoreName';
import OrderViewModelPersisterService from '../services/Offline/OrderViewModelPersisterService';
import IOrderViewModelPersisterService from '../services/Offline/IOrderViewModelPersisterService';
import fetchWithRetries from '../common/fetchWithRetries';

import { NotificationContext } from './NotificationContext';
import { OfflineContext } from './OfflineContext';

interface IOfflineOrderContext {
	orders: OrderDetailsResponse[];
	orderPersisterService: IOrderViewModelPersisterService;
	appointments: AppointmentModel[];
	reload: () => Promise<void>;
}

const UpdateBackgroundDataEvent = 'upgrade-background-data';

const OfflineOrderContext = React.createContext<IOfflineOrderContext>({} as any);

const OfflineOrderProvider: React.FunctionComponent = ({ children }) => {
	const [isInitialized, setIsInitialized] = useState(false);
	const { db, setIsLoaded, setIsLoading } = useContext(OfflineContext);
	const { isAuthenticated } = useContext(AuthContext);
	const notificationContext = useContext(NotificationContext);
	const orderApiClient = useApiClient(OrderClient);
	const appointmentClient = useApiClient(AppointmentClient);
	const [data, setData] = useState<OrderDetailsResponse[]>([]);
	const [appointments, setAppointments] = useState<AppointmentModel[]>([]);
	const messageBusService = getService('MessageBusService');

	const settings = useConfig();
	const access = useContext(AccessContext);

	const today = dayjs().startOf('day');

	const updateData = async (mutation: OrderDetailsResponse[]) => {
		// Update Local Data
		setData(mutation);
		/* TODO: Should it be here or maybe moved to decorator? */
		// Update Indexed DB
		const storedOrders = await db?.getAll(StoreName.Orders);
		await Promise.all(mutation.map((order) => db?.put(StoreName.Orders, order)));

		if (storedOrders && storedOrders.length > 0) {
			const ordersToRemove = (storedOrders as OrderDetailsResponse[]).filter((x) => !mutation.some((m) => m.order.id === x.order.id));
			if (ordersToRemove.length > 0) {
				await Promise.all(ordersToRemove.map((entry) => db?.delete(StoreName.Orders, entry.order.id)));
			}
		}
	};

	const updateAppointments = async (updatedAppointments: AppointmentModel[]) => {
		setAppointments(updatedAppointments);

		const dbAppointmentResult = (await db?.getAll(StoreName.Appointments)) ?? [];
		await Promise.all(updatedAppointments.map((appointment) => db?.put(StoreName.Appointments, appointment)));

		const appointmentsToRemove = (dbAppointmentResult as AppointmentModel[]).filter(
			(dbAppointment) => !updatedAppointments.some((x) => x.id === dbAppointment.id),
		);
		await Promise.all(appointmentsToRemove.map((appointmentToRemove) => db?.delete(StoreName.Appointments, appointmentToRemove.id)));
	};

	const boxFailNotification = useCallback(
		() =>
			notificationContext?.open({
				message: `Failed to load photo`,
				description: `Connection issue with Box. Please try reloading.`,
				duration: 10,
				closeIcon: <CloseOutlined />,
				className: 'toastNotification',
				icon: <WarningOutlined style={{ fontSize: '18px', color: '#f8bb2d' }} />,
			}),
		[],
	);

	const addFilesToData = async (orderData: OrderDetailsResponse[]) => {
		const items = await orderApiClient
			.queryOrderFiles(today.toISOString())
			.then((result) => {
				if (!result.urlsGeneratedSuccessfully) {
					boxFailNotification();
				}
				return result.items;
			})
			.catch(() => {
				boxFailNotification();
			});

		const dataWithFiles = orderData.map((order) => {
			const files = items?.find((orderFile) => orderFile.orderId === order.order.id)?.files;

			return files ? { ...order, files: { ...order.files, ...files } } : order;
		});
		messageBusService.emit(UpdateBackgroundDataEvent, dataWithFiles);
		await updateData(dataWithFiles);
	};

	const reload = async () => {
		try {
			if (
				!(
					access.has(PermissionType.ViewScheduledWorkOrders) ||
					access.has(PermissionType.PersonaExternalTechnician) ||
					access.has(PermissionType.PersonaExternalAdmin)
				)
			) {
				setIsLoaded(true);
			} else {
				setIsLoading?.(true);
				const dbResult = (await db?.getAll(StoreName.Orders)) ?? [];
				const dbAppointmentResult = (await db?.getAll(StoreName.Appointments)) ?? [];
				setData(dbResult);
				setAppointments(dbAppointmentResult);
				setIsLoaded(true);
				const appointmentsResult = await fetchWithRetries(() => appointmentClient.getUserAppointments(today.toISOString()));
				const result = await fetchWithRetries(() => orderApiClient.query(today.toISOString(), false));
				await updateAppointments(appointmentsResult ?? dbAppointmentResult);
				await addFilesToData(result ?? dbResult);
				setIsLoading?.(false);
			}
		} catch (error) {
			// Fetch from DB if offline
			const result = await db?.getAll(StoreName.Orders);
			const appointmentResult = await db?.getAll(StoreName.Appointments);
			setData(result as OrderDetailsResponse[]);
			setAppointments(appointmentResult as AppointmentModel[]);
			setIsLoading?.(false);
			setIsLoaded(true);
		}
	};

	useEffect(() => {
		const fetch = async () => {
			if (!isInitialized && isAuthenticated && access.loaded) {
				setIsInitialized(true);
				reload();
			} else if (isInitialized && !isAuthenticated) {
				setIsInitialized(false);
			}
		};

		fetch();
	}, [isAuthenticated, settings, access]);

	const orderPersisterService = useMemo(() => new OrderViewModelPersisterService(db!, updateData), [db]);

	const value = useMemo(
		() => ({
			orders: data,
			appointments,
			orderPersisterService,
			reload,
		}),
		[data, reload],
	);

	useEffect(() => orderPersisterService.setOrders(data), [data]);

	return <OfflineOrderContext.Provider value={value}>{children}</OfflineOrderContext.Provider>;
};

export { OfflineOrderProvider, OfflineOrderContext };
