const getEnvironment = (url?: string): { name: string; color: string; isProduction?: boolean } | null => {
	const match = url?.match(/https?:\/\/[a-z]*.(.*).(ah4r|amh)/);
	if (match) {
		const [, name] = match;
		switch (name) {
			case 'private':
				return { name: 'priv', color: '#6D57A5' };

			case 'sit':
				return { name, color: '#F1C537' };

			case 'qa':
				return { name, color: '#F39313' };

			case 'uat':
				return { name, color: '#0099A6' };

			default:
				return { name, color: '#004879', isProduction: true };
		}
	}

	return null;
};

export default getEnvironment;
