import { isAndroid } from 'react-device-detect';
import { AddressModel } from '../api/api';

function getMapUrlByAddress(address: AddressModel): string {
	// Google docs - https://developers.google.com/maps/documentation/urls/get-started
	// Apple docs https://developer.apple.com/library/archive/featuredarticles/iPhoneURLScheme_Reference/MapLinks/MapLinks.html#//apple_ref/doc/uid/TP40007899-CH5-SW1

	const query = encodeURI(`${address.street ? `${address.street}, ` : ''}${address.city}, ${address.state} ${address.zipCode}`);

	return isAndroid ? `https://www.google.com/maps?q=${query})}` : `http://maps.apple.com/?dirflg=d&daddr=${query}`;
}

export default getMapUrlByAddress;