/* eslint-disable no-underscore-dangle */
import { IDBPDatabase } from 'idb';
import { IFileAttributes, IFileService } from '@4r/mf-contracts-4services';
import { CreateFileCommand, DeleteFileCommand, UploadFileContentCommand } from 'src/api/api';
import BaseOfflineCapableService from '../Offline/BaseOfflineCapableService';
import { CommandTitle, CommandType } from '../CommandTypes';
import StoreName from '../StoreName';
import { ICommand } from '../Offline/ICommand';
import { ICommandHandlerCollection } from '../Offline/ICommandHandlerCollection';
import { IOrderCommandService } from '../OrderCommandService/IOrderCommandService';


export type CreateAndUploadFileCommand = {
	fileId: string;
	file: Blob;
	attributes: IFileAttributes;
	url: string;
	orderId: string;
}

export default class OfflineFileServiceDecorator extends BaseOfflineCapableService implements IFileService {
	constructor(private target: IFileService, private commandService: IOrderCommandService, db: IDBPDatabase, handlers: ICommandHandlerCollection) {
		super(db);

		handlers.add(CommandType.FileContentUpload, (command: ICommand<UploadFileContentCommand>) =>
			this.commandService.submitCommand(command)
		);

		handlers.add(CommandType.FileCreate, async (command: ICommand<CreateAndUploadFileCommand>) => {

			const createFileCommand: ICommand<CreateFileCommand> = {
				commandId: command.commandId,
				_status: command._status,
				_timestamp: command._timestamp,
				commandTitle: CommandTitle.FileCreate,
				commandType: CommandType.FileCreate,
				orderId: command.orderId,
				payload: { fileId: command.payload.fileId, attributes: JSON.stringify(command.payload.attributes) }
			}

			await this.commandService.submitCommand<CreateFileCommand>(createFileCommand);

			if (command.payload.file && command.payload.url) {
				await this.addCommand<CreateAndUploadFileCommand>(
					{
						payload: command.payload,
						orderId: command.orderId,
						commandType: CommandType.FileContentUpload,
						commandTitle: CommandTitle.FileContentUpload,
					}, StoreName.FileCommands);
			}
		});

		handlers.add(CommandType.FileContentUpload, async (command: ICommand<CreateAndUploadFileCommand>) => {
			await this.commandService.submitCommandWithFile(command);
		});

		handlers.add(CommandType.FileDelete, (command: ICommand<DeleteFileCommand>) => this.commandService.submitCommand(command));
	}

	async uploadFile(fileId: string, file: Blob, url: string, attributes: IFileAttributes): Promise<void> {
		await this.addCommand<CreateAndUploadFileCommand>(
			{
				payload: {
					fileId,
					file,
					url,
					attributes,
					orderId: attributes.OrderId ? attributes.OrderId : null
				},
				commandType: CommandType.FileCreate,
				commandTitle: CommandTitle.FileCreate,
			},
			StoreName.Commands,
		);
	}

	async createFile(fileId: string, attributes: IFileAttributes): Promise<void> {
		await this.addCommand<CreateFileCommand & { orderId: string | null }>(
			{
				payload: {
					fileId,
					attributes: JSON.stringify(attributes),
					orderId: attributes.OrderId ? attributes.OrderId : null
				},
				commandType: CommandType.FileCreate,
				commandTitle: CommandTitle.FileCreate,
			},
			StoreName.Commands,
		);
	}

	async uploadFileContent(fileId: string, file: Blob, url: string): Promise<void> {
		await this.addCommand<UploadFileContentCommand & { url: string }>(
			{
				payload: {
					fileId,
					file: file as any,
					url
				},
				commandType: CommandType.FileContentUpload,
				commandTitle: CommandTitle.FileContentUpload,
			},
			StoreName.FileCommands,
		);
	}

	async deleteFile(fileId: string): Promise<void> {
		await this.addCommand<DeleteFileCommand>(
			{
				payload: { id: fileId },
				commandType: CommandType.FileDelete,
				commandTitle: CommandTitle.FileDelete,
			},
			StoreName.Commands,
		);
	}
}
