import React, { useEffect, useState } from 'react';
import { getService } from '@4r/mf-host';
import { ENTERPRISE_CONTRACT_EVENT, I4EnterpriseContracts } from '@4r/mf-contracts';
import { useFlags } from '@4r/module-common-feature-flags-mf-host';
import { mergeMenus, validateMenus } from './mergeMenus';
import PermissionType from '../authorization/PermissionType';
import { ReactComponent as PinSVG } from '../assets/svg/pin.svg';

interface IContractsContext {
	contracts: I4EnterpriseContracts;
	setLocalContract?: (contract: I4EnterpriseContracts) => void;
}

// static menu options for MicroFrontends (in v2)
const staticContacts: I4EnterpriseContracts = {
	SchedulingMicrofrontend: {
		enabled: true,
		priority: 0,
		route: '/scheduling',
		channels: ['4Services'],
		leftMenus: {
			scheduling: {
				anyPermissions: [PermissionType.ViewScheduling],
				icon: <PinSVG style={{ marginRight: 6 }} />,
				route: '/scheduling',
				title: 'Scheduling',
				priority: 0,
			},
		},
	},
};

const ContractsContext = React.createContext<IContractsContext>({ contracts: {} });

const ContractsProvider: React.FC = ({ children }) => {
	const flags = useFlags();
	const [contracts, setContracts] = useState<I4EnterpriseContracts>(staticContacts);

	const messageBusService = getService('MessageBusService');

	useEffect(() => {
		const localEvent = messageBusService?.on(ENTERPRISE_CONTRACT_EVENT, ({ detail }: CustomEvent) => {
			// filter out the contracts that are not targeting the 4Services channel
			const newMfContractEntries = Object.entries(detail as I4EnterpriseContracts).filter(
				([, value]) => value.channels && value.channels.indexOf('4Services') !== -1,
			);
			if (newMfContractEntries.length > 0) {
				const newMfContract = Object.fromEntries(newMfContractEntries);
				// merge existing menu with a new one and validate it against LD flags
				setContracts((s) => validateMenus(mergeMenus(s, newMfContract), flags));
			}
		});

		return () => localEvent?.removeListener();
	}, [messageBusService, flags]);

	useEffect(() => {
		// validate existing menu against updated LD flags
		setContracts((s) => validateMenus(s, flags));
	}, [flags]);

	const setLocalContract = (contract: I4EnterpriseContracts) => {
		setContracts((s) => validateMenus(mergeMenus(s, contract), flags));
	};

	return <ContractsContext.Provider value={{ contracts, setLocalContract }}>{children}</ContractsContext.Provider>;
};

export { ContractsProvider, ContractsContext };
