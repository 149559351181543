import React, { FunctionComponent } from 'react';
import { Breadcrumb } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import useBreadcrumbs, { BreadcrumbsRoute } from 'use-react-router-breadcrumbs';
import { Link } from 'react-router-dom';

interface IBreadcrumbsProps {
	dynamicRoutes?: BreadcrumbsRoute[];
}

// You add dynamic breadcrumbs by passing the param
// Example: [{ path: '/order/:id', breadcrumb: 'title' }]
const Breadcrumbs: FunctionComponent<IBreadcrumbsProps> = ({ dynamicRoutes }) => {
	const breadcrumbs = useBreadcrumbs(dynamicRoutes, { excludePaths: ['/'] });

	return (
		<Breadcrumb>
			<Breadcrumb.Item>
				<Link to="/">
					<HomeOutlined />
				</Link>
			</Breadcrumb.Item>
			{breadcrumbs.map(({ breadcrumb }) => (
				<Breadcrumb.Item key={`breadcrumb-${breadcrumb}`}>{breadcrumb}</Breadcrumb.Item>
			))}
		</Breadcrumb>
	);
};

export default Breadcrumbs;
