import { PushpinFilled, PushpinOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row, Space, Typography } from 'antd';
import { useApiClient } from '@4r/module-common-mf-app';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { NotificationClient, NotificationModel } from '../../api/api';
import Badge from '../../components/Badge';
import './Notification.scss';

interface NotificationProps {
	notification: NotificationModel;
}

const Notification: React.FC<NotificationProps> = ({ notification }) => {
	const apiClient = useApiClient(NotificationClient);

	const [isPinnedByUser, setIsPinnedByUser] = useState<boolean>(notification.isPinnedByUser ?? false);

	const togglePin = (model: NotificationModel) => {
		if (isPinnedByUser) {
			apiClient.unpin(model.id);
		} else {
			apiClient.pin(model.id);
		}
		setIsPinnedByUser(!isPinnedByUser);
		// eslint-disable-next-line no-param-reassign
		model.isPinnedByUser = !model.isPinnedByUser;
	};

	return (
		<Card
			size="small"
			style={{ padding: '8px' }}
			headStyle={{ border: 0 }}
			className="notification native-table-wrapper"
			title={
				<Row justify="space-between" align="middle">
					<Col>{dayjs(notification.created).format('MM/DD/YYYY, h:mm A')}</Col>
					<Col>
						<Space direction="horizontal">
							<Badge text={notification.badgeText} colorClass={notification.badgeColorClass} />
							<Button
								type="link"
								size="large"
								disabled={notification.isPinned}
								icon={notification.isPinned || isPinnedByUser ? <PushpinFilled /> : <PushpinOutlined />}
								onClick={() => togglePin(notification)}
							/>
						</Space>
					</Col>
				</Row>
			}
		>
			<Typography.Title level={4}>{notification.title}</Typography.Title>
			{/* eslint-disable-next-line */}
			<Typography children={<div dangerouslySetInnerHTML={{ __html: notification.body ?? '' }} />} />
		</Card>
	);
};

export default Notification;
