import { IDBPDatabase } from 'idb';
import { CommandTitle, CommandType } from 'src/services/CommandTypes';
import { CreateLogCommand, CreateLogRequest } from 'src/api/api';

import { ILogService } from './ILogService';
import BaseOfflineCapableService from '../Offline/BaseOfflineCapableService';
import { ICommand } from '../Offline/ICommand';
import { IOrderCommandService, } from '../OrderCommandService/IOrderCommandService';
import { ICommandHandlerCollection } from '../Offline/ICommandHandlerCollection';

export default class OfflineLogServiceDecorator extends BaseOfflineCapableService implements ILogService {
	constructor(private target: ILogService, private commandService: IOrderCommandService, db: IDBPDatabase, handlers: ICommandHandlerCollection) {
		super(db);

		handlers.add(CommandType.LogCreate, (command: ICommand<CreateLogCommand>) =>
			this.commandService.submitCommand(command)
		);
	}

	async addLog(request: CreateLogRequest) {
		await this.addCommand<CreateLogCommand>({
			payload: request,
			commandType: CommandType.LogCreate,
			commandTitle: CommandTitle.LogCreate,
		});
	}
}
