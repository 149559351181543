// TODO: Consider to move to mf-contracts

enum ServiceNames {
	File = 'FileService',
	Order = 'OrderService',
	ServiceTask = 'ServiceTaskService',
	ConditionIssue = 'ConditionIssueService',
	Asset = 'AssetService',
	Log = 'LogService',
	CurrentOrderIdService = 'CurrentOrderIdService'
}

export default ServiceNames;
