import { AssetReferences, IAssetService } from '@4r/mf-contracts-4services';
import { Settings } from '../../contexts/types';
import ApiConfig from '../../api/ApiConfig';
import { IAuthService } from '../../authentication/AuthService';
import {
	FileParameter,
	PropertyClient,
	PropertyModel,
	SearchAssetRequest,
	ReferenceClient,
	SearchAssetResponse,
	CreateAssetRequest,
	UpdateAssetRequest,
} from '../../api/apiAssets';
import { AssetClient } from '../../api/apiAssetExtension';
import { TaxonomyNames } from '../../common/consts';

class ApiAssetService implements IAssetService {
	private assetClient: AssetClient;

	private propertyClient: PropertyClient;

	private referencClient: ReferenceClient;

	constructor(private authService: IAuthService, settings: Settings) {
		this.assetClient = new AssetClient(new ApiConfig(this.authService), settings.ASSET_API_URL);
		this.propertyClient = new PropertyClient(new ApiConfig(this.authService), settings.ASSET_API_URL);
		this.referencClient = new ReferenceClient(new ApiConfig(this.authService), settings.ASSET_API_URL);
	}

	async addAsset(payload: CreateAssetRequest): Promise<string> {
		await this.assetClient.createAsset(payload);
		return payload.id ?? '';
	}

	async updateAsset(payload: UpdateAssetRequest): Promise<void> {
		return this.assetClient.updateAsset(payload);
	}

	async getAssets(request: SearchAssetRequest): Promise<SearchAssetResponse> {
		const result = await this.assetClient.search(request);
		return result;
	}

	async getPropertyInfo(propertyId: string): Promise<PropertyModel> {
		const result = await this.propertyClient.get(propertyId);
		return result;
	}

	async getAssetReferences(): Promise<AssetReferences> {
		const { assetTypeAttributes, taxonomies } = await this.referencClient.getGlobalData();
		return {
			allAssetTypesAttributes: assetTypeAttributes ?? {},
			assetTypes: taxonomies?.[TaxonomyNames.ASSET_TYPE].items ?? [],
		};
	}

	async uploadAssetFile(
		assetId: string | undefined,
		assetTypeId: string | undefined,
		propertyId: string | undefined,
		photos: FileParameter[] | null | undefined,
	): Promise<void> {
		await this.assetClient.uploadAssetFile(assetId, assetTypeId, propertyId, photos);
	}
}

export default ApiAssetService;
