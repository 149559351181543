import {useApiClient, useApiLoad, useConfig} from '@4r/module-common-mf-app';
import {useContext} from 'react';
import {AccessContext} from '@4r/module-common-authorization';
import {useFeatureFlag} from '@4r/module-common-feature-flags-mf-host';
import {LifecyclesDashboardClient} from '../../api/apiMaintenance';
import {FeatureFlags} from '../../common/consts';
import PermissionType from '../../authorization/PermissionType';
import {AuthContext} from '../../authentication/AuthProvider';

/**
 * Custom hook to determine the availability of the Lifecycle Dashboard.
 *
 * The conditions for availability are:
 * - The user must have `ViewLifecycleDashboard` permission.
 * - The feature flag for the Lifecycles dashboard must be enabled or the user's territory tree must be fetched and not empty.
 *
 * This hook is meant to be used during per Market rollout of the Lifecycle Dashboard.
 */
const useLifecycleTabAvailability = () => {
	const {MAINTENANCE_API_URL} = useConfig();
	const lifecyclesDashboardClient = useApiClient(LifecyclesDashboardClient, () => MAINTENANCE_API_URL);
	const { isAuthenticated } = useContext(AuthContext);

	const [territoryTree, isFetching] = useApiLoad(async () => {
		if (!isAuthenticated) {
			return [];
		}
		return lifecyclesDashboardClient.territoryTree();
	}, [isAuthenticated]);

	const access = useContext(AccessContext);
	const lifecyclesDashboardEnabled = useFeatureFlag(FeatureFlags.PropertyLifecycles);
	const hasAccessToLifecycles = access.has(PermissionType.ViewLifecycleDashboard);

	if (!hasAccessToLifecycles) {
		return false;
	}

	if (lifecyclesDashboardEnabled) {
		return true;
	}

	return !isFetching && territoryTree?.length !== 0;
};

export default useLifecycleTabAvailability;
