import React, { useContext } from 'react';
import { MFContext } from '@4r/mf-host';
import { AccessContext } from '@4r/module-common-authorization';
import { useConfig } from '@4r/module-common-mf-app';
import { useGlobalState } from '../../state/Store';
import { PageStatus } from '../../state/Actions';
import OfflineMessage from '../../components/OfflineMessage/OfflineMessage';
import { AuthContext } from '../../authentication/AuthProvider';

const ResourceManagmentPage: React.FC = () => {
	const { ResourceManagementMicrofrontend } = useContext(MFContext);
	const settings = useConfig();
	const [onlineStatus] = useGlobalState('online');
	const auth = React.useContext(AuthContext);
	const access = useContext(AccessContext);
	const PAGE_TITLE = 'Resource Management';

	if (onlineStatus === PageStatus.offline) {
		return <OfflineMessage pageTitle={PAGE_TITLE} />;
	}

	if (!ResourceManagementMicrofrontend) {
		return null;
	}

	return (
		<ResourceManagementMicrofrontend
			isAuthenticated={auth.isAuthenticated}
			googleApiKey={settings?.GOOGLE_MAP_KEY ?? ''}
			currentUser={access.user}
		/>
	);
};

export default ResourceManagmentPage;
