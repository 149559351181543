import React from 'react';
import { Col, Row, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { ReactComponent as LogoSVG } from '../../assets/svg/logo_full.svg';
import './LoadingPlaceholder.scss';

const LoadingPlaceholder: React.FC = () => (
	<Row justify="center" align="middle" className="loading-placeholder">
		<Col>
			<Row justify="center">
				<Col className="loading-placeholder__logo">
					<LogoSVG />
				</Col>
			</Row>
			<Row justify="center" align="middle" gutter={16}>
				<Col>
					<Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
				</Col>
				<Col className="loading-placeholder__text">Please wait, 4Services is being loaded...</Col>
			</Row>
		</Col>
	</Row>
);

export default LoadingPlaceholder;
