import { OrderModel } from '@4r/mf-contracts-4services';
import { AppointmentModel, ConditionIssueInstanceModel } from '../../api/api';

export const TIME_FORMAT = 'h:mm A';
export const DATE_FORMAT = 'MM/DD/YYYY';
export type MappedCIs = Record<string, ConditionIssueInstanceModel[]>;
export const NONE = '-';

export const mapAppointmentToOrderModel = (appointment: AppointmentModel): OrderModel => ({
	id: appointment.id,
	name: appointment.typeName,
	typeId: appointment.typeId,
	typeName: NONE,
	property: {
		id: appointment.id,
		address: {},
		geoCoordinates: {
			latitude: appointment.latitude ?? 0,
			longitude: appointment.longitude ?? 0,
		},
	},
	description: appointment.description,
	statusId: NONE,
	statusName: NONE,
	priceListId: '',
	serviceTasks: [],
	notes: [],
	tags: [],
	preferredTimes: [],
	created: '',
	updated: '',
	bookings: [
		{
			id: appointment.id,
			resourceName: '',
			resourceId: '',
			userId: appointment.userId,
			startTime: appointment.startTime,
			endTime: appointment.endTime,
			statusId: '',
		},
	],
});
