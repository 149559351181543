import React, { useCallback, useContext, useEffect } from 'react';
import { IOrderService, ServiceNames } from '@4r/mf-contracts-4services';
import { useHub } from '@4r/module-common-signalr';
import { getService } from '@4r/mf-app';
import { OrderDetailsResponse } from '../api/api';
import { useGlobalState } from '../state/Store';
import { PageStatus } from '../state/Actions';

import { BookingChangedNotificationModel, UserOrdersHub } from '../hubs/UserOrdersHub';
import { OfflineOrderContext } from './OfflineOrderContext';

const SignalRUpdatesProvider: React.FunctionComponent = ({ children }) => {
	const [onlineStatus] = useGlobalState('online');
	const hub = useHub(UserOrdersHub);
	const { orders: data, orderPersisterService } = useContext(OfflineOrderContext);
	const orderService = getService<IOrderService>(ServiceNames.Order);

	const onNotifyBookingChanged = useCallback(
		async (message: BookingChangedNotificationModel) => {
			if (data.findIndex((datum) => datum.order.id === message.orderId) !== -1) {
				return;
			}
			const updatedOrder = await orderService.findById(message.orderId);
			let newData: OrderDetailsResponse[] = [];
			const updateWorkOrderIndex = data.findIndex((datum) => datum.order.id === updatedOrder.order.id);
			if (updateWorkOrderIndex === -1) {
				newData = [...data, updatedOrder];
				orderPersisterService.setOrders(newData);
			}
		},
		[orderService, data],
	);

	useEffect(() => {
		const turnOffNotifyBookingChanged =
			onlineStatus === PageStatus.online ? hub?.onNotifyBookingChanged(onNotifyBookingChanged) : undefined;

		return () => {
			turnOffNotifyBookingChanged?.();
		};
	}, [hub, onNotifyBookingChanged, onlineStatus]);

	return <>{children}</>;
};

export default SignalRUpdatesProvider;
