import React, {useContext} from 'react';
import {MFContext} from '@4r/mf-host';
import {AccessContext} from '@4r/module-common-authorization';

const VendorExp: React.FC = () => {
	const {VendorExpMicrofrontend} = useContext(MFContext);
	const {user} = useContext(AccessContext);

	if (!VendorExpMicrofrontend) return null;

	return (
		<VendorExpMicrofrontend currentUser={user}/>
	);
};

export default VendorExp;
