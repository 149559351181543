import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { AccessContext } from '@4r/module-common-authorization';
import { MFContext } from '@4r/mf-host';

const AccessPropertiesPage: React.FunctionComponent = () => {
	const access = useContext(AccessContext);
	const { AccessMicrofrontend } = useContext(MFContext);

	if (!AccessMicrofrontend) return null;

	return (
		<div className="relative page dashboard-page">
			<AccessMicrofrontend currentUser={JSON.stringify(access.user)} />
		</div>
	);
};

export default withRouter(AccessPropertiesPage);
