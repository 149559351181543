import React, { useEffect, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Avatar, Row, Button, Col } from 'antd';
import { IdTokenClaims, User } from 'oidc-client-ts';
import { displayProgress } from '@4r/module-common-ant-components';
import { handleCommonError } from '@4r/module-common-error-handler';
import { AuthContext } from './AuthProvider';
import { LocationState } from '../routes/types';
import { MenuState } from '../components/LeftMenu/LeftMenu';

interface LoginProps {
	isMobile: boolean;
	menuState: MenuState;
}

type UserProfile = IdTokenClaims & {
	emails: string[];
};

const Login: React.FunctionComponent<LoginProps & RouteComponentProps<any, any, LocationState>> = (props) => {
	const { location, isMobile, menuState } = props;
	const auth = React.useContext(AuthContext);
	const [errorMessage, setErrorMessage] = React.useState<string | undefined>();
	const [profile, setProfile] = useState<User | null | undefined>(null);

	const handleSignInFlow = () =>
		displayProgress(async () => {
			let redirectPath = location.pathname;
			if (location.state && location.state.from) {
				redirectPath = location.state.from.pathname;
			}

			handleCommonError(
				async () => auth.authService?.login(redirectPath),
				(e) => setErrorMessage((e as { message: string }).message),
			);
		});

	const currentUser = auth.isAuthenticated ? auth.getUser() : undefined;

	let initials = '';

	if (currentUser) {
		initials = `${currentUser?.given_name?.substring(0, 1)}${currentUser?.family_name?.substring(0, 1)}`;
	}

	useEffect(() => {
		async function getProfile() {
			let userProfile: User | undefined | null;
			handleCommonError(async () => {
				await auth.authService?.getUser().then((result) => {
					userProfile = result;
				});
			});
			setProfile(userProfile);
		}
		getProfile();
	}, []);

	return (
		<Row
			style={{
				background: isMobile ? '#FBFBFB' : '#fff',
				borderBottom: isMobile ? '1px solid #EBEAED' : 0,
			}}
		>
			<Col>
				<Row align="middle" gutter={16} style={{ padding: isMobile ? 24 : 0 }}>
					{currentUser ? (
						<Col>
							<button type="button" disabled={auth.isAuthenticationInProgress}>
								<Row align="middle" gutter={8} wrap={false}>
									<Col>
										<Avatar size={isMobile ? 80 : 'default'}>{initials}</Avatar>
									</Col>
									{menuState !== MenuState.Collapsed && !isMobile && (
										<Col className="left-menu__profile">
											<div className="left-menu__profile__name">
												{currentUser.given_name} {currentUser.family_name}
											</div>
											<span>{(profile?.profile as UserProfile)?.emails[0]}</span>
										</Col>
									)}
								</Row>
							</button>
						</Col>
					) : (
						<Col>
							<Button type="link" onClick={handleSignInFlow} disabled={auth.isAuthenticationInProgress}>
								Sign in
							</Button>
						</Col>
					)}

					{errorMessage && <div>{errorMessage}</div>}

					{auth.isAuthenticated && isMobile && (
						<Col>
							<div>
								<div className="left-menu__profile__name">
									{currentUser?.given_name} {currentUser?.family_name}
								</div>
								<span>{(profile?.profile as UserProfile)?.emails[0]}</span>
							</div>
						</Col>
					)}
				</Row>
			</Col>
		</Row>
	);
};

export default withRouter(Login);
