import React, { useEffect, useState, useContext, useMemo } from 'react';
import './App.scss';
import { useHistory } from 'react-router-dom';
import { AccessProvider } from '@4r/module-common-authorization';
import { GeolocationProvider } from '@4r/module-common-geolocation';
import { init, MFProvider } from '@4r/mf-host';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import ReactDOM from 'react-dom';
import * as antd from 'antd';
import { FeatureFlagProvider } from '@4r/module-common-feature-flags-mf-host';
import { ConfigContextProvider, useConfig, expandSettings } from '@4r/module-common-mf-app';
import { UserContext, UserProvider } from '../contexts/UserContext';
import { Settings } from '../contexts/types';
import { AuthProvider } from '../authentication/AuthProvider';
import AppContent from './AppContent';
import { ReferenceDataProvider } from '../contexts/ReferenceDataContext';
import GeoCoordinatesLogger from './GeoCoordinatesLogger';
import { OfflineProvider } from '../contexts/OfflineContext';
import { ContractsProvider } from '../contexts/ContractsContext';
import ImagePreloader from './ImagePreloader';
import HubConfigurationProvider from './HubConfigurationProvider';
import { NotificationProvider } from '../contexts/NotificationContext';
import SignalRUpdatesProvider from '../contexts/SignalRUpdatesProvider';

const settingsPath = `${process.env.PUBLIC_URL}/config.json`;

function FeatureFlagAdapter({ children }: React.PropsWithChildren<object>) {
	const settings = useConfig();
	const user = useContext(UserContext);
	const userId = useMemo(() => user.current?.id || null, [user]);

	return (
		<FeatureFlagProvider clientSideId={settings.LAUNCH_DARKLY_CLIENT_ID} identityProviderUserId={userId}>
			{children}
		</FeatureFlagProvider>
	);
}

const App: React.FunctionComponent = () => {
	const [settings, setSettings] = useState<Settings>();
	const history = useHistory();

	function log(msg: string, ...args: any[]) {
		// eslint-disable-next-line no-console
		console.info(`App: ${msg}`, ...args);
	}

	// load settings
	useEffect(() => {
		const loadSettings = async () => {
			const response = await fetch(settingsPath);
			if (response.ok) {
				const contentType = response.headers.get('content-type');
				if (contentType !== null && contentType.indexOf('json') !== -1) {
					const json = await response.json();
					const s = expandSettings(json) as Settings;
					log('Settings loaded', s);

					// Load Google API with key
					if (window && document) {
						const script = document.createElement('script');
						const body = document.getElementsByTagName('body')[0];
						script.src = `https://maps.googleapis.com/maps/api/js?key=${s.GOOGLE_MAP_KEY}&libraries=places,drawing,geometry`;
						body.appendChild(script);
					}

					init(
						// filter out v1 MFs
						s.APPS.filter((x) => x.ISV1 === 'true').map((x) => ({
							config: x.CONFIG,
							css: x.CSS,
							js: x.JS,
							externalStyles: x.EXTERNAL_STYLES,
							enabled: x.ENABLED === 'true',
						})),
						history,
						[
							{ name: 'React', value: React },
							{ name: 'ReactDOM', value: ReactDOM },
							{ name: 'antd', value: antd },
						],
					);

					setSettings(s);
				}
			}
		};
		loadSettings();
	}, [settingsPath]);

	useEffect(() => {
		if (settings && settings.APP_INSIGHTS_CONNECTION_STRING) {
			const appInsights = new ApplicationInsights({
				config: {
					// See https://learn.microsoft.com/en-us/azure/azure-monitor/app/javascript-sdk-configuration#sdk-configuration
					connectionString: settings.APP_INSIGHTS_CONNECTION_STRING,
					disableTelemetry: true,
				},
			});
			appInsights.loadAppInsights();
			appInsights.trackPageView();
			// See https://learn.microsoft.com/en-us/azure/azure-monitor/app/api-filtering-sampling?tabs=npmpackage#javascript-telemetry-initializers
			appInsights.addTelemetryInitializer((envelope) => {
				const { tags } = envelope;
				if (tags) {
					tags['ai.cloud.role'] = 'app-4services';
					tags['ai.cloud.roleInstance'] = 'app-4services';
				}
			});
		}
	}, [settings]);

	if (!settings) return null;

	return (
		<ConfigContextProvider outerSettings={settings} config={{}}>
			<MFProvider>
				<AuthProvider settings={settings}>
					<AccessProvider>
						<UserProvider>
							<FeatureFlagAdapter>
								<HubConfigurationProvider>
									<ReferenceDataProvider>
										<NotificationProvider>
											<OfflineProvider>
												<SignalRUpdatesProvider>
													<ContractsProvider>
														<GeolocationProvider>
															<GeoCoordinatesLogger>
																<ImagePreloader>
																	<AppContent />
																</ImagePreloader>
															</GeoCoordinatesLogger>
														</GeolocationProvider>
													</ContractsProvider>
												</SignalRUpdatesProvider>
											</OfflineProvider>
										</NotificationProvider>
									</ReferenceDataProvider>
								</HubConfigurationProvider>
							</FeatureFlagAdapter>
						</UserProvider>
					</AccessProvider>
				</AuthProvider>
			</MFProvider>
		</ConfigContextProvider>
	);
};

export default App;
