import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { initHandleOperation } from '@4r/module-common-ant-components';
import 'antd/dist/antd.less';
import '@4r/ant-theme-config/dist/index.css';
import './index.scss';
import './toast.scss';
import './index.css';
import './typographyOverrides.scss';
import App from './pages/App';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import BASE_PATH from './common/base-path';

initHandleOperation();

ReactDOM.render(
	<Router basename={BASE_PATH}>
		<App />
	</Router>,
	document.getElementById('root'),
);

serviceWorkerRegistration.register();
