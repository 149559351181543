import React, { createContext } from 'react';
import { notification } from 'antd';
import { ArgsProps } from 'antd/lib/notification';

interface InitialValuesInterface {
	open: (args: ArgsProps) => void;
	error: (args: ArgsProps) => void;
	success: (args: ArgsProps) => void;
	info: (args: ArgsProps) => void;
	warning: (args: ArgsProps) => void;
}

/* eslint-disable @typescript-eslint/no-unused-vars  */
/* eslint-disable @typescript-eslint/no-empty-function  */
const initialValue: InitialValuesInterface = {
	open: (args: ArgsProps) => {},
	error: (args: ArgsProps) => {},
	success: (args: ArgsProps) => {},
	info: (args: ArgsProps) => {},
	warning: (args: ArgsProps) => {},
};

const NotificationContext = createContext(initialValue);

const NotificationProvider = ({ children }: { children: React.ReactElement }) => {
	const [api, contextHolder] = notification.useNotification();
	return (
		<NotificationContext.Provider value={api}>
			{contextHolder}
			{children}
		</NotificationContext.Provider>
	);
};

export { NotificationProvider, NotificationContext };
