import React, { useContext } from 'react';
import { Col, Row } from 'antd';
import { ReactComponent as DownloadSVG } from '../../assets/svg/download.svg';
import { OfflineContext } from '../../contexts/OfflineContext';

const BackgroundDownloading = () => {
	const { isLoading } = useContext(OfflineContext);

	if (!isLoading) return null;

	return (
		<Col style={{ marginBottom: 8 }}>
			<Row justify="center" className="left-menu__column__status">
				<DownloadSVG />
			</Row>
			<Row justify="center" className="left-menu__column__status left-menu__column__status--overflow">
				<span className="left-menu__column__overflowed-status">Downloading</span>
			</Row>
		</Col>
	);
};

export default BackgroundDownloading;
