import React from 'react';

const mount = (fn: () => void, unmountFn?: () => void) => () => {
	fn();
	const unmount = () => {
		if (typeof unmountFn === 'function') {
			unmountFn();
		}
	};
	return unmount;
};

const useMountEffect = (fn: () => void, unmountFn?: () => void): void =>
	React.useEffect(mount(fn, unmountFn), []);

export default useMountEffect;
