enum CommandType {
	AssetCreate = 'asset-create',
	AssetUpdate = 'asset-update',
	ConditionIssueDelete = 'condition-issue-delete',
	ConditionIssueCreate = 'condition-issue-create',
	ConditionIssueUpdateStatus = 'condition-issue-update-status',
	ConditionIssueInstanceUpdate = 'condition-issue-update',
	ConditionIssueInstanceEstimatedDurationBatchUpdate = 'condition-issue-estimated-duration-batch-update',
	ConditionIssueEscalate = 'condition-issue-escalate',
	RemedyCreate = 'remedy-create',
	RemedyDelete = 'remedy-delete',
	ServiceTaskCreate = 'service-task-create',
	ServiceTaskUpdate = 'service-task-update',
	ServiceTaskEvaluateStatus = 'service-task-evaluate-status',
	LogCreate = 'log-create',
	FileCreate = 'file-create',
	FileDelete = 'file-delete',
	FileContentUpload = 'file-content-upload',
	OrderMutateState = 'order-mutate-state',
	OrderNoteCreate = 'order-note-create',
	OrderNoteUpdate = 'order-note-update',
	OrderNoteStatusUpdate = 'order-note-status-update',
	InventoryIssue = 'inventory-issue',
	InventoryReceive = 'inventory-receive',
}

enum CommandTitle {
	AssetCreate = 'Create asset',
	AssetUpdate = 'Update asset',
	ConditionIssueDelete = 'Delete condition issue',
	ConditionIssueCreate = 'Create condition issue',
	ConditionIssueUpdateStatus = 'Update condition issue status',
	ConditionIssueInstanceUpdate = 'Condition issue instance update',
	ConditionIssueInstanceEstimatedDurationBatchUpdate = 'Condition issue instance estimated duration batch update',
	ConditionIssueEscalate = 'Esclate condition issue',
	RemedyCreate = 'Create remedy',
	RemedyDelete = 'Delete remedy',
	ServiceTaskCreate = 'Create service task',
	ServiceTaskUpdate = 'Update service task',
	ServiceTaskEvaluateStatus = 'Evaluate service task status',
	LogCreate = 'Create log',
	FileCreate = 'Create file',
	FileDelete = 'Delete file',
	FileContentUpload = 'Upload file content',
	OrderMutateState = 'Mutate order state',
	OrderNoteCreate = 'Create order note',
	OrderNoteUpdate = 'Update order note',
	OrderNoteStatusUpdate = 'Update order note status',
	InventoryIssue = 'Issue inventory',
	InventoryReceive = 'Receive inventory',
}

export { CommandType, CommandTitle };
