import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ROUTES from '../routes/constants';
import OIDCRedirectCallbackView from './authentication-flow/OIDCRedirectCallbackView';

const AuthRoutes: React.FunctionComponent = () => (
	<Switch>
		<Route exact path={ROUTES.auth.redirectView} component={OIDCRedirectCallbackView} />
	</Switch>
);

export default AuthRoutes;
