import React, { useContext, useEffect, useState } from 'react';
import { OfflineOrderContext } from '../contexts/OfflineOrderContext';
import { PageStatus } from '../state/Actions';
import { AuthContext } from '../authentication/AuthProvider';
import { useGlobalState } from '../state/Store';
import LoggerService from '../services/LoggerService';
import delay from '../common/delay';

const MAX_TIMEOUT_SECONDS = 90;
const MAX_RETRIES = 10;

interface ImageSet {
	[key: string]: boolean;
}

const logger = new LoggerService('ImagePreloader');

const fetchImage = async (thumbnailUrl: string, timeElapsedSeconds = 0, retryNumber = 0) => {
	try {
		const response = await fetch(thumbnailUrl, { method: 'GET', headers: { 'Access-Control-Allow-Origin': '*' } });
		if (response.status === 200) {
			// file was fetched fine
			return;
		}
		if (response.status === undefined) {
			// its offline, no sense in retrying
			return;
		}

		const retryAfterSeconds = response.status === 202 ? +(response.headers.get('retry-after') ?? 5) : 5;

		const newTimeElapsedSeconds = timeElapsedSeconds + retryAfterSeconds;
		if (newTimeElapsedSeconds >= MAX_TIMEOUT_SECONDS || retryNumber > MAX_RETRIES) {
			logger.logWarn('Image max retry limit reached', thumbnailUrl);
			return;
		}

		await delay(retryAfterSeconds * 1000);
		await fetchImage(thumbnailUrl, newTimeElapsedSeconds, retryNumber + 1);
	} catch (e) {
		logger.logWarn('Image loading error', thumbnailUrl, e);
	}
};

const ImagePreloader: React.FunctionComponent = ({ children }) => {
	const [onlineStatus] = useGlobalState('online');
	const { isAuthenticated } = React.useContext(AuthContext);
	const { orders } = useContext(OfflineOrderContext);
	const [, setImages] = useState<ImageSet>({});

	const ensureImageAdded = (key: string, thumbnailUrl: string) => {
		setImages((prev) => {
			if (prev[key]) {
				// image already added
				return prev;
			}
			// do not await
			fetchImage(`${thumbnailUrl}&preloading=true`);

			return { ...prev, [key]: true };
		});
	};

	const getImageKey = (fileId: string, variant: string) => `${fileId}_${variant}`;

	useEffect(() => {
		if (isAuthenticated && onlineStatus === PageStatus.online && orders?.length) {
			orders
				.map((order) => order.files)
				.flatMap((files) => Object.values(files))
				.forEach((file) => {
					const thumbnailUrl = file.variants.Thumbnail?.url;
					if (thumbnailUrl) {
						const key = getImageKey(file.id, 'thumbnail');
						ensureImageAdded(key, thumbnailUrl);
					}
				});
		}
	}, [isAuthenticated, onlineStatus, orders]);

	return <>{children}</>;
};

export default ImagePreloader;
