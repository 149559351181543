import { AccessContext } from '@4r/module-common-authorization';
import React, { useContext, useMemo } from 'react';
import { useFeatureFlag } from '@4r/module-common-feature-flags-mf-host';
import { HubConfigurationBuilder, HubProvider, HubAuthorization } from '@4r/module-common-signalr';
import { useConfig } from '@4r/module-common-mf-app';
import { UserOrdersHub } from '../hubs/UserOrdersHub';
import { useGlobalState } from '../state/Store';
import { PageStatus } from '../state/Actions';
import { AuthContext } from '../authentication/AuthProvider';
import PermissionType from '../authorization/PermissionType';
import { CurrentGeocoordinatesHub } from '../hubs/CurrentGeocoordinatesHub';
import { FeatureFlags } from '../common/consts';

const HubConfigurationProvider: React.FunctionComponent = ({ children }) => {
	const settings = useConfig();
	const auth = useContext(AuthContext);
	const access = useContext(AccessContext);
	const [onlineStatus] = useGlobalState('online');
	const hasSendCurrentGeoCoordinates = access.has(PermissionType.SendCurrentGeoCoordinates);
	const hasViewScheduledWorkOrders = access.has(PermissionType.ViewScheduledWorkOrders);
	const flagSignalREnabled = useFeatureFlag(FeatureFlags.SignalR);

	const configuration = useMemo(() => {
		const hubsEnabled =
			(onlineStatus !== PageStatus.offline &&
				auth &&
				auth.isAuthenticated &&
				settings &&
				flagSignalREnabled &&
				hasSendCurrentGeoCoordinates) ||
			false;

		const hubauthorization: HubAuthorization = {
			updateCurrentGeoCoordinates: hasSendCurrentGeoCoordinates,
			notifyBookingChanged: hasViewScheduledWorkOrders,
		};

		const builder = new HubConfigurationBuilder();
		const config = builder
			.add(CurrentGeocoordinatesHub, `${settings?.API_URL}/hubs/current-geocoordinates`, hubauthorization, hasSendCurrentGeoCoordinates)
			.add(UserOrdersHub, `${settings?.API_URL}/hubs/user-orders-updates`, hubauthorization, hasViewScheduledWorkOrders)
			.registerAccessTokenFactory(() => auth.authService?.getAccessToken() ?? null)
			.enableHubs(hubsEnabled)
			.setDebugMode(true)
			.build();

		return config;
	}, [settings, auth, access, flagSignalREnabled, hasSendCurrentGeoCoordinates, onlineStatus]);

	return <HubProvider configuration={configuration}>{children}</HubProvider>;
};

export default HubConfigurationProvider;
