import { IConditionIssueService } from '@4r/mf-contracts-4services';
import { Settings } from '../../contexts/types';
import {
	BatchUpdateConditionIssueInstanceEstimatedDurationRequest,
	ConditionIssueInstanceClient,
	CreateConditionIssueInstanceRequest,
	EscalateConditionIssueInstanceRequest,
	UpdateConditionIssueInstanceStatusRequest,
	RemedyClient,
	RemedyInstanceClient,
	CreateRemedyInstanceRequest,
	OrderClient,
	UpdateConditionIssueInstanceRequest,
} from '../../api/api';
import { ReferenceClient, TaxonomyItemModel } from '../../api/apiAssets';
import ApiConfig from '../../api/ApiConfig';
import { IAuthService } from '../../authentication/AuthService';

import { TaxonomyNames } from '../../common/consts';

export default class ApiConditionIssueService implements IConditionIssueService {
	private conditionIssueClient: ConditionIssueInstanceClient;

	private remedyInstanceClient: RemedyInstanceClient;

	private remedyClient: RemedyClient;

	private orderClient: OrderClient;

	private referencClient: ReferenceClient;

	constructor(private readonly authService: IAuthService, settings: Settings) {
		this.conditionIssueClient = new ConditionIssueInstanceClient(new ApiConfig(this.authService), settings.API_URL);
		this.remedyInstanceClient = new RemedyInstanceClient(new ApiConfig(this.authService), settings.API_URL);
		this.remedyClient = new RemedyClient(new ApiConfig(this.authService), settings.API_URL);
		this.orderClient = new OrderClient(new ApiConfig(this.authService), settings.API_URL);
		this.referencClient = new ReferenceClient(new ApiConfig(this.authService), settings.ASSET_API_URL);
	}

	async addConditionIssue(request: CreateConditionIssueInstanceRequest): Promise<void> {
		return this.conditionIssueClient.createConditionIssueInstance(request);
	}

	async deleteConditionIssue(id: string): Promise<void> {
		return this.conditionIssueClient.deleteConditionIssueInstance(id);
	}

	async updateConditionIssueInstanceStatus(id: string, request: UpdateConditionIssueInstanceStatusRequest): Promise<void> {
		return this.conditionIssueClient.updateConditionIssueInstanceStatus(id, request);
	}

	async updateConditionIssueInstance(id: string, request: UpdateConditionIssueInstanceRequest): Promise<void> {
		return this.conditionIssueClient.updateConditionIssueInstance(id, request);
	}

	async batchUpdateConditionIssueInstanceEstimatedDuration(request: BatchUpdateConditionIssueInstanceEstimatedDurationRequest): Promise<void> {
		return this.conditionIssueClient.batchUpdateConditionIssueInstanceEstimatedDuration(request);
	}

	async escalateConditionIssueInstance(id: string, request: EscalateConditionIssueInstanceRequest): Promise<void> {
		return this.conditionIssueClient.escalateConditionIssueInstance(id, request);
	}

	async addRemedy(request: CreateRemedyInstanceRequest): Promise<void> {
		return this.remedyInstanceClient.createRemedyInstance(request);
	}

	async deleteRemedy(id: string, orderId: string): Promise<void> {
		return this.remedyInstanceClient.deleteRemedyInstance(id, orderId);
	}

	async getAssetReferences(): Promise<TaxonomyItemModel[]> {
		const result = await this.referencClient.getGlobalData();
		return result.taxonomies?.[TaxonomyNames.ASSET_TYPE].items ?? [];
	}
}
