import { LogClient, CreateLogRequest } from "src/api/api";
import { IAuthService } from "src/authentication/AuthService";
import ApiConfig from "src/api/ApiConfig";
import { Settings } from "src/contexts/types";
import { ILogService } from "./ILogService";


export default class ApiLogService implements ILogService {

    private logClient: LogClient;


    constructor(private readonly authService: IAuthService, settings: Settings) {

        this.logClient = new LogClient(new ApiConfig(this.authService), settings.API_URL);
    }

    async addLog(request: CreateLogRequest): Promise<void> {
        return this.logClient.addLog(request);
    }
}