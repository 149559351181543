import dayjs from 'dayjs';
import { IOrderService } from '@4r/mf-contracts-4services';
import { Settings } from '../../contexts/types';
import {
	AddOrUpdateNoteRequest,
	MutateOrderStateRequest,
	OrderClient,
	OrderDetailsResponse,
	SearchWorkOrdersRequest,
	OrderModel,
	QueryOrderFilesResponse,
} from '../../api/api';
import ApiConfig from '../../api/ApiConfig';
import { IAuthService } from '../../authentication/AuthService';

export default class ApiOrderService implements IOrderService {
	private orderClient: OrderClient;

	constructor(private readonly authService: IAuthService, settings: Settings) {
		this.orderClient = new OrderClient(new ApiConfig(this.authService), settings.API_URL);
	}

	async getOrders(today: string): Promise<OrderDetailsResponse[]> {
		return this.orderClient.query(today, false);
	}

	async getOrdersFiles(today: string): Promise<QueryOrderFilesResponse> {
		return this.orderClient.queryOrderFiles(today);
	}

	async findById(id: string): Promise<OrderDetailsResponse> {
		const today = dayjs().startOf('day');
		return this.orderClient.findById(id, today.toISOString());
	}

	async addNote(request: AddOrUpdateNoteRequest): Promise<void> {
		return this.orderClient.addNote(request);
	}

	async updateNote(request: AddOrUpdateNoteRequest): Promise<void> {
		return this.orderClient.updateNote(request);
	}

	async updateNoteStatus(orderId: string, id: string, isRead: boolean): Promise<void> {
		return this.orderClient.updateNoteStatus(id, isRead);
	}

	async mutateState(request: MutateOrderStateRequest): Promise<void> {
		return this.orderClient.mutateState(request);
	}

	async search(request: SearchWorkOrdersRequest): Promise<OrderModel[]> {
		return this.orderClient.search(request);
	}
}
