import ApiConfig from "src/api/ApiConfig";
import { CommandClient, FileParameter, SubmitOrderCommandRequest } from "src/api/api";
import { IAuthService } from "src/authentication/AuthService";
import { Settings } from "src/contexts/types";
import dayjs from "dayjs";
import { getService } from "@4r/mf-host";
import MessageBusService from "@4r/mf-host/dist/shared/src/services/MessageBusService";
import { IOrderCommandService } from "./IOrderCommandService";
import { ICommand } from "../Offline/ICommand";
import { FileMessageTypes } from "../FileEvents";
import MessageBusEvent from "../MessageBusEvents";





export default class ApiOrderCommandService implements IOrderCommandService {

    private commandClient: CommandClient;

    private messageBusService: MessageBusService;

    constructor(private authService: IAuthService, settings: Settings) {
        this.commandClient = new CommandClient(new ApiConfig(this.authService), settings.API_URL);
        this.messageBusService = getService('MessageBusService');
    }

    async submitCommand<T>(command: ICommand<T>): Promise<void> {

        const { orderId, commandId, _timestamp, commandType, payload } = command;
        const request: SubmitOrderCommandRequest = { commandType, payload, timestamp: dayjs(_timestamp).toISOString() }

        await this.commandClient.submitOrderCommand(orderId, commandId, request);
    }

    async submitCommandWithFile(command: ICommand<{ fileId: string, file: Blob }>): Promise<void> {
        const { orderId, commandId, _timestamp, payload } = command;

        this.onFileStateUpdated(payload.fileId, FileMessageTypes.FileUploadStartedEvent);

        const fileParameter: FileParameter = {
            fileName: payload.fileId,
            data: payload.file,
        };

        await this.commandClient.submitOrderFileCommand(orderId, commandId, payload.fileId, fileParameter, dayjs(_timestamp).toISOString());

        this.onFileStateUpdated(payload.fileId, FileMessageTypes.FileUploadResultEvent);
    }

    private onFileStateUpdated(fileId: string, type: FileMessageTypes): void {
        this.messageBusService.emit(MessageBusEvent.FileUploadStateUpdated, { fileId, type });
    }
}