import { IDBPDatabase } from 'idb';
import { OrderDetailsResponse } from '../../api/api';
import StoreName from '../StoreName';
import IOrderViewModelPersisterService from './IOrderViewModelPersisterService';

/**
 * Responsible for saving the orders into the local IndexedDB. Saves are performed with delay every certain interval.
 */
export default class OrderViewModelPersisterService implements IOrderViewModelPersisterService {
	private readonly orderQueueIds: string[] = [];

	private orders: OrderDetailsResponse[] = [];

	// eslint-disable-next-line no-useless-constructor, no-empty-function
	constructor(private db: IDBPDatabase<unknown>, private setData: (mutation: OrderDetailsResponse[]) => void) {
		this.run();
	}

	private async run() {
		if (this.orderQueueIds.length > 0) {
			const ids = this.orderQueueIds.splice(0);
			const orders = this.orders.filter((x) => ids.indexOf(x.order.id) !== -1);
			await Promise.all(orders.map((i) => this.saveOrderInternal(i)));
		}
		setTimeout(() => this.run(), 1000);
	}

	setOrders(orders: OrderDetailsResponse[]) {
		this.orders = orders;
		this.setData(orders);
	}

	async saveOrderInternal(order: OrderDetailsResponse) {
		await this.db.put(StoreName.Orders, order);
	}

	private enqueue(orderId: string) {
		const i = this.orderQueueIds.indexOf(orderId);
		if (i !== -1) {
			// replace with new copy
			this.orderQueueIds[i] = orderId;
		} else {
			this.orderQueueIds.push(orderId);
		}
	}

	async saveOrder(predicate: (x: OrderDetailsResponse) => boolean) {
		const filteredOrders = this.orders.filter(predicate);
		filteredOrders.forEach((x) => this.enqueue(x.order.id));
	}

	updateOrders(ordersToUpdate: OrderDetailsResponse[]) {
		const updatedOrders = this.orders.map((x) => {
			const updatedOrder = ordersToUpdate.find((o) => o.order.id === x.order.id);
			return updatedOrder ?? x;
		});

		this.setOrders(updatedOrders);
	}

	async saveOrders() {
		if (this.orders.length > 0) {
			await Promise.all(this.orders?.map((order) => this.saveOrderInternal(order)));
		}
	}
}
