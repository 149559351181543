import { RedoOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';

interface OfflineMessageProps {
	retry?: () => void;
}

// eslint-disable-next-line import/prefer-default-export
export const OfflineMessage = ({ retry }: OfflineMessageProps) => (
	<div className="offline-message">
		<span>Unable to load due to poor network connection. Please check your signal and try again</span>
		<Button type="primary" icon={<RedoOutlined />} onClick={retry}>
			Retry
		</Button>
	</div>
);
