import { CheckOutlined, StopOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Radio } from 'antd';
import React, { useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useApiLoad, useApiClient } from '@4r/module-common-mf-app';
import { ReviewClient } from '../../api/api';
import ReviewStatus from './ReviewStatus';
import './ApprovalPage.scss';
import { ReferenceDataContext } from '../../contexts/ReferenceDataContext';

const ApprovalPage: React.FunctionComponent = () => {
	const url: { id: string } = useParams();
	const history = useHistory();
	const apiClient = useApiClient(ReviewClient);
	const { globalData, vendorData } = useContext(ReferenceDataContext);
	const [data, dataLoading, refreshData, , hasError] = useApiLoad(async () => {
		const inspection = await apiClient.getInspection(url.id);
		return inspection;
	}, [url]);

	if (hasError) {
		history.push('/');
	}

	if (dataLoading || !data) {
		return <div className="page lg:shadow-xl rounded-lg bg-white p-6">Loading...</div>;
	}

	const { statusName = '' } = data;
	const buttonsDisabled = statusName === 'Approved' || statusName === 'Rejected';

	return (
		<div className="approval-page page lg:shadow-xl rounded-lg bg-white p-6">
			<div className="m-1 p-3 border rounded-lg flex justify-between items-center">
				<div>
					<h1 className="work-order-name mb-4 text-3xl font-bold">Work Order: {data.workOrderName}</h1>
					<div className="font-bold">{data.address?.street}</div>
					<div>
						{data.address?.city}, {data.address?.state} {data.address?.zipCode}
					</div>
					<ReviewStatus status={statusName} />
				</div>
				<div className="justify-end">
					<Radio.Group>
						<Radio.Button value="large">Issues</Radio.Button>
						<Radio.Button value="default">Work Orders Created</Radio.Button>
					</Radio.Group>
				</div>
			</div>
			<div className="m-1 p-3 border rounded-lg">
				<mf-scopingselector
					global_data={JSON.stringify(globalData)}
					vendor_data={JSON.stringify(vendorData)}
					order_id={data.workOrderId}
					mode="approval"
					status={statusName}
				/>
			</div>
			<div className="flex justify-end">
				<Popconfirm
					title="Are you sure you want to approve the review?"
					disabled={buttonsDisabled}
					onConfirm={async () => {
						await apiClient.approveReview(data.reviewId);
						refreshData();
					}}
					cancelButtonProps={{ type: 'dashed' }}
				>
					<Button key="approve" className="m-2" type="primary" disabled={buttonsDisabled}>
						<CheckOutlined /> Approve
					</Button>
				</Popconfirm>
				<Popconfirm
					title="Are you sure you want to reject the review?"
					disabled={buttonsDisabled}
					onConfirm={async () => {
						await apiClient.rejectReview(data.reviewId);
						refreshData();
					}}
					cancelButtonProps={{ type: 'dashed' }}
				>
					<Button key="reject" className="m-2" disabled={buttonsDisabled} danger>
						<StopOutlined /> Reject
					</Button>
				</Popconfirm>
			</div>
		</div>
	);
};

export default ApprovalPage;
