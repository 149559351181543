import { User, UserManager } from 'oidc-client-ts';

type UserChangeListener = (user: User | null) => void;

export interface IAuthStorage {
	getAccessToken(): string | undefined;
	getUserProfile(): User['profile'];
	getUser(): User | null;
	hasUser(): boolean;
	setUser(data: User): void;
	clearUser(): void;
	refreshStorage(): void;
	isUserTokenKey(claimsStorageKey: string | null): boolean;

	addEventListener(eventListener: UserChangeListener): void;
	removeEventListener(eventListener: UserChangeListener): void;
}

export default class AuthStorage implements IAuthStorage {
	private changeListeners: UserChangeListener[] = [];

	constructor(private userManager: UserManager, storage: Storage, private prefix: string = 'oidc.') {
		const { authority, client_id: clientId } = this.userManager.settings;
		this.storage = storage;
		this.claimsStorageKey = `${this.prefix}user:${authority}:${clientId}`;
		this.restoreFromStorage();
		this.userManager.events.addUserLoaded(this.handleUserManagerUserLoad);
		this.userManager.events.addUserUnloaded(this.handleUserManagerUserUnload);
	}

	private claimsStorageKey: string;

	private user: null | User = null;

	private storage: Storage;

	refreshStorage(): void {
		this.restoreFromStorage();
	}

	attachEventListener(): void {
		window.addEventListener('storage', this.handleStorageEvent);
	}

	detachEventListener(): void {
		window.removeEventListener('storage', this.handleStorageEvent);
	}

	hasUser(): boolean {
		return this.user != null;
	}

	getUser(): User | null {
		return this.user;
	}

	setUser(user: User): void {
		this.user = user;
		this.notifyListeners();
	}

	clearUser(): void {
		this.user = null;
		this.notifyListeners();
	}

	getAccessToken(): string | undefined {
		if (!this.user) {
			this.restoreFromStorage();
		}
		return this.user?.access_token || this.user?.id_token || undefined;
	}

	getUserProfile(): User['profile'] {
		if (!this.user) {
			this.restoreFromStorage();
			if (!this.user) {
				throw new Error('User is not authenticated.');
			}
		}

		return this.user.profile;
	}

	isUserTokenKey(claimsStorageKey: string) {
		return claimsStorageKey === this.claimsStorageKey;
	}

	private handleUserManagerUserLoad = () => {
		this.refreshStorage();
	};

	private handleUserManagerUserUnload = () => {
		this.storage.removeItem(this.claimsStorageKey);
		this.clearUser();
	};

	private restoreFromStorage(): void {
		const authDataJson: string | null = this.storage.getItem(this.claimsStorageKey);
		try {
			if (authDataJson) {
				// Will also throw when rawData is null
				const authData = JSON.parse(authDataJson);
				if (AuthStorage.isUserValid(authData)) {
					this.setUser(authData);
				}
			}
		} catch {
			// skip - could not parse the user data is invalid
		}
	}

	static isUserValid(authData: User): boolean {
		const expiresAt = new Date(authData.expires_at! * 1000);
		const now = new Date();
		return now <= expiresAt;
	}

	private handleStorageEvent = (evt: StorageEvent): void => {
		if (evt.storageArea !== this.storage) {
			return;
		}

		if (evt.key !== this.claimsStorageKey) {
			return;
		}

		this.restoreFromStorage();
	};

	addEventListener(eventListener: UserChangeListener): void {
		this.changeListeners.push(eventListener);
	}

	removeEventListener(eventListener: UserChangeListener): void {
		const i = this.changeListeners.indexOf(eventListener);
		if (i !== -1) {
			this.changeListeners.splice(i, 1);
		}
	}

	private notifyListeners() {
		this.changeListeners.forEach((listener) => {
			listener(this.user);
		});
	}
}
