import { IMessage } from './CommonEvents';

export type IFileAttributes = { [key: string]: any };

export interface IHasFileId {
	fileId: string;
}

export class FileMessageTypes {
	static FileUploadStartedEvent = 'upload-started';

	static FileUploadResultEvent = 'upload-result';

	static FileUploadErrorEvent = 'upload-error';

	static FileStoredInLocalDbEvent = 'stored-in-local-db';
}

export interface ISaveFileCommand extends IMessage, IHasFileId {
	file: Blob;
	attributes: IFileAttributes;
}

export interface IUploadFileCommand extends IMessage, IHasFileId {}

export interface IFileEvent extends IMessage, IHasFileId {}

export interface IGetSettingsResponse extends IMessage {
	value: any;
}
