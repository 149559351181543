import React, { useMemo } from 'react';
import { Table } from 'antd';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { COLOR, Pill } from '@4r/module-common-ant-components';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import {
	nameSorter,
	getStringPropertySorter,
	typeNameSorter,
	getDatePropertySorter,
	getNumberPropertySorter,
} from '@4r/module-common-mf-app';
import { AppointmentModel, OrderDetailsResponse, OrderModel } from '../../api/api';
import getCurrentBooking from '../../common/orders';
import { getOnFilterFn, getPropertyFilters } from '../../common/filters';
import { TIME_FORMAT, DATE_FORMAT, MappedCIs, mapAppointmentToOrderModel, NONE } from './common';

import ConditionIssuesTooltip from '../../components/ConditionIssueTooltip/ConditionIssuesTooltip';

import OrderListMobile from './OrderListMobile';
import { OfflineMessage } from './OfflineMessage';

import './OrderList.scss';

type OrderListProps = {
	ordersData: OrderDetailsResponse[];
	loading: boolean;
	selectedId: string | null;
	appointments?: AppointmentModel[];
	networkError?: boolean;
	retry?: () => void;
};

const statusNameSorter = getStringPropertySorter<OrderModel>((x) => x.statusName);
const propertyAddressSorter = getStringPropertySorter<OrderModel>((x) => x.property?.address.street);
const propertyNoSorter = getStringPropertySorter<OrderModel>((x) => x.property?.propertyNo);

const appointmentTimeSorter = getDatePropertySorter<OrderModel>((x) => getCurrentBooking(x.statusId, x.bookings)?.startTime);
const ciNumberSorter = getNumberPropertySorter<OrderModel>((x) => x.numberOfConditionIssues);

const nameFilterAccessor = (x: OrderModel) => x.name;
const typeFilterAccessor = (x: OrderModel) => x.typeName;
const statusFilterAccessor = (x: OrderModel) => x.statusName;
const addressFilterAccessor = (x: OrderModel) => (x.property?.address ? `${x.property.address.city}, ${x.property.address.state}` : null);
const addressIdFilterAccessor = (x: OrderModel) => x.property?.propertyNo;
const appointmentTimeAccessor = (x: OrderModel) => getCurrentBooking(x.statusId, x.bookings)?.startTime;

const OrderList = (props: OrderListProps) => {
	const screens = useBreakpoint();
	const isMobileOrTablet = !screens.xl;
	const isMobile = screens.xs;
	const { ordersData, loading, selectedId, appointments, networkError, retry } = props;
	const appointmentsMappedToOrders: OrderModel[] = appointments?.map(mapAppointmentToOrderModel) ?? [];
	const orders = [...ordersData.map((d) => d.order), ...appointmentsMappedToOrders].sort(appointmentTimeSorter);
	const mappedCIs = ordersData.reduce<MappedCIs>(
		(aggr, data) => ({ ...aggr, [data.order.id]: Object.values(data.condititionIssueInstances) }),
		{},
	);
	const nameFilters = useMemo(() => getPropertyFilters(orders, nameFilterAccessor), [orders]);
	const typeFilters = useMemo(() => getPropertyFilters(orders, typeFilterAccessor), [orders]);
	const statusFilters = useMemo(() => getPropertyFilters(orders, statusFilterAccessor), [orders]);
	const addressFilters = useMemo(() => getPropertyFilters(orders, addressFilterAccessor), [orders]);
	const addressIdFilters = useMemo(() => getPropertyFilters(orders, addressIdFilterAccessor), [orders]);
	const appointmentTimeFilters = useMemo(() => getPropertyFilters(orders, appointmentTimeAccessor), [orders]);

	if (isMobileOrTablet)
		return (
			<OrderListMobile
				orders={orders}
				mappedCIs={mappedCIs}
				isMobile={isMobile}
				loading={loading}
				networkError={networkError}
				retry={retry}
			/>
		);

	return (
		<Table<OrderModel>
			className="order-list-table"
			dataSource={loading ? [] : orders}
			rowKey="id"
			loading={loading}
			pagination={{ hideOnSinglePage: true, style: { paddingRight: '16px' } }}
			rowClassName={(item) => (selectedId === item.id ? 'ant-table-row-selected' : '')}
			locale={networkError ? { emptyText: <OfflineMessage retry={retry} /> } : undefined}
		>
			<Table.Column<OrderModel>
				title="Order"
				dataIndex="name"
				sorter={nameSorter}
				filters={nameFilters}
				onFilter={getOnFilterFn(nameFilterAccessor)}
				render={(_, item) => (item.typeName === NONE ? item.name : <Link to={`/orders/${item.id}`}>{item.name}</Link>)}
			/>
			<Table.Column<OrderModel>
				title="Type"
				dataIndex="typeName"
				sorter={typeNameSorter}
				filters={typeFilters}
				onFilter={getOnFilterFn(typeFilterAccessor)}
			/>
			<Table.Column<OrderModel>
				title="Status & Sub-Status"
				dataIndex="statusName"
				sorter={statusNameSorter}
				filters={statusFilters}
				onFilter={getOnFilterFn(statusFilterAccessor)}
				render={(_, item) =>
					item.typeName === NONE ? (
						NONE
					) : (
						<div className="order-status-cell">
							<Pill.Generic label={item.statusName} size="small" fillColor={COLOR.Blue} />
							{item.subStatusName ? (
								<Pill.Generic
									label={item.subStatusName}
									size="small"
									color={COLOR.DarkBlue}
									borderColor={COLOR.Blue}
									fillColor={COLOR.LightBlue}
								/>
							) : null}
						</div>
					)
				}
			/>
			<Table.Column<OrderModel>
				title="Property Address"
				key="propertyAddress"
				sorter={propertyAddressSorter}
				filters={addressFilters}
				onFilter={getOnFilterFn(addressFilterAccessor)}
				responsive={['lg']}
				render={(_, item) =>
					item.typeName === NONE
						? NONE
						: item.property?.address && (
								<div className="order-status-cell">
									<span>
										{item.property.address.street}, {item.property.address.city}
									</span>
									<span className="additional-description">
										{item.property.address.state} {item.property.address.zipCode}
									</span>
								</div>
						  )
				}
			/>
			<Table.Column<OrderModel>
				title="Property ID"
				dataIndex={['property', 'propertyNo']}
				sorter={propertyNoSorter}
				filters={addressIdFilters}
				onFilter={getOnFilterFn(addressIdFilterAccessor)}
				render={(_, { property }) => property?.propertyNo ?? NONE}
			/>
			<Table.Column<OrderModel>
				title="# of issues"
				sorter={ciNumberSorter}
				render={(record) =>
					record.typeName === NONE ? (
						NONE
					) : (
						<ConditionIssuesTooltip orderId={record.id} conditionIssues={mappedCIs[record.id]} count={record.numberOfConditionIssues} />
					)
				}
				responsive={['lg']}
				width="100px"
			/>
			<Table.Column<OrderModel>
				title="Scheduled"
				sorter={appointmentTimeSorter}
				filters={appointmentTimeFilters}
				onFilter={getOnFilterFn(appointmentTimeAccessor)}
				render={(_, item) => {
					const currentBooking = getCurrentBooking(item.statusId, item.bookings);
					return (
						<div className="order-status-cell">
							<span>{dayjs(currentBooking?.startTime).format(DATE_FORMAT)}</span>
							<span className="additional-description">
								{dayjs(currentBooking?.startTime).format(TIME_FORMAT)} - {dayjs(currentBooking?.endTime).format(TIME_FORMAT)}
							</span>
						</div>
					);
				}}
			/>
		</Table>
	);
};

export default OrderList;
