import React, { useContext } from 'react';
import { MFContext } from '@4r/mf-host';
import { AccessContext } from '@4r/module-common-authorization';
import { useGlobalState } from '../../state/Store';
import { PageStatus } from '../../state/Actions';
import OfflineMessage from '../../components/OfflineMessage/OfflineMessage';

const TerritoryManagementPage: React.FC = () => {
	const { TerritoryManagementMicrofrontend } = useContext(MFContext);
	const [onlineStatus] = useGlobalState('online');
	const access = useContext(AccessContext);
	const PAGE_TITLE = 'Territory Management';

	if (onlineStatus === PageStatus.offline) {
		return <OfflineMessage pageTitle={PAGE_TITLE} />;
	}

	if (!TerritoryManagementMicrofrontend) {
		return null;
	}

	return (
		<TerritoryManagementMicrofrontend
			currentUser={access.user}
		/>
	);
};

export default TerritoryManagementPage;
