import React, { useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Result, Spin } from 'antd';
import { User } from 'oidc-client-ts';
import useMountEffect from '../../common/use-mount-effect';
import { AuthContext } from '../AuthProvider';
import { LocationState } from '../../routes/types';
import { AuthRedirectState } from '../AuthRedirectState';

const OIDCRedirectCallbackView: React.FunctionComponent<RouteComponentProps<any, any, LocationState>> = ({ history }) => {
	const authContext = useContext(AuthContext);
	const [authError, setAuthError] = React.useState<string | null>(null);

	const handleSuccess = (user: User) => {
		if (history.location.state && history.location.state.from) {
			return history.push(history.location.state.from);
		}
		const { path } = user.state as AuthRedirectState;
		return history.push(path);
	};

	useMountEffect(() =>
		(async () => {
			try {
				if (authContext.authService) {
					const result = await authContext.authService.signInRedirectCallback();
					handleSuccess(result);
				}
			} catch (e) {
				setAuthError((e as Error).message);
			}
		})(),
	);
	return (
		<div className="page lg:shadow-xl rounded-lg bg-white p-6">
			{authError ? (
				<div>{authError}</div>
			) : (
				<Result
					status="success"
					title="Authentication in progress"
					extra={
						<>
							<Spin /> Authenticating...
						</>
					}
				/>
			)}
		</div>
	);
};

export default OIDCRedirectCallbackView;
