import { IDBPDatabase } from 'idb';
import { IServiceTaskService } from '@4r/mf-contracts-4services';
import BaseOfflineCapableService from '../Offline/BaseOfflineCapableService';
import { ICommandHandlerCollection } from '../Offline/ICommandHandlerCollection';
import { CommandTitle, CommandType } from '../CommandTypes';
import { ICommand } from '../Offline/ICommand';
import { UserInventory } from './ApiServiceTaskService';
import StoreName from '../StoreName';
import {
	EvaluateServiceTaskStatusCommand,
	IssueInventoryCommand,
	OrderInventory,
	ReceiveInventoryCommand,
	TransactionInventoryModel,
	UpdateServiceTaskCommand,
} from '../../api/api';
import fetchWithRetries from '../../common/fetchWithRetries';
import { IOrderCommandService } from '../OrderCommandService/IOrderCommandService';

// We put a const instead of user id to keep data only of logged in user
const STORE_USER_INVENTORY_ID = 'current-user';

export default class OfflineServiceTaskServiceDecorator extends BaseOfflineCapableService implements IServiceTaskService {
	constructor(
		private target: IServiceTaskService,
		private commandService: IOrderCommandService,
		db: IDBPDatabase,
		handlers: ICommandHandlerCollection,
	) {
		super(db);

		handlers.add(CommandType.InventoryIssue, (command: ICommand<IssueInventoryCommand>) => this.commandService.submitCommand(command));

		handlers.add(CommandType.InventoryReceive, (command: ICommand<ReceiveInventoryCommand>) => this.commandService.submitCommand(command));

		handlers.add(CommandType.ServiceTaskEvaluateStatus, (command: ICommand<EvaluateServiceTaskStatusCommand>) =>
			this.commandService.submitCommand(command),
		);

		handlers.add(CommandType.ServiceTaskUpdate, (command: ICommand<UpdateServiceTaskCommand>) =>
			this.commandService.submitCommand(command),
		);
	}

	async updateServiceTask(orderId: string, serviceTaskId: string, remedyId: string, description: string): Promise<void> {
		await this.addCommand({
			payload: {
				orderId,
				serviceTaskId,
				description,
				remedyId
			},
			commandType: CommandType.ServiceTaskUpdate,
			commandTitle: CommandTitle.ServiceTaskUpdate,
		});
	}

	async findOrderInventory(orderId: string) {
		try {
			const result = await fetchWithRetries(() => this.target.findOrderInventory(orderId));
			this.db?.put(StoreName.OrderInventory, { orderId, ...result });
			return result;
		} catch (error) {
			const orderInventory = await this.db?.get(StoreName.OrderInventory, orderId);
			return orderInventory;
		}
	}

	async getUserAvailableInventory(userId: string) {
		try {
			const response = await fetchWithRetries(() => this.target.getUserAvailableInventory(userId));
			await this.db?.put(StoreName.UserInventory, response, STORE_USER_INVENTORY_ID);
			return response;
		} catch (error) {
			const userInventory = await this.db?.get(StoreName.UserInventory, STORE_USER_INVENTORY_ID);
			if (!userInventory) {
				return { inventoryItems: [], locations: [] };
			}
			return userInventory as UserInventory;
		}
	}

	async assignInventory(
		orderId: string,
		locationId: string,
		lineItems: TransactionInventoryModel[],
		propertyId: string,
		serviceTaskId: string,
	) {
		const orderInventory = (await this.db?.get(StoreName.OrderInventory, orderId)) as OrderInventory | undefined;
		const userInventory = (await this.db?.get(StoreName.UserInventory, STORE_USER_INVENTORY_ID)) as UserInventory | undefined;

		const mutated: OrderInventory = {
			products: orderInventory?.products || [],
			locations: orderInventory?.locations || [],
			serviceTasksInventory: (orderInventory &&
				orderInventory.serviceTasksInventory.length > 0 &&
				orderInventory.serviceTasksInventory.map((data) => {
					if (data.serviceTaskId === serviceTaskId) {
						let elements = [...data.inventory];
						lineItems.forEach((item) => {
							const existing = elements.find((el) => el.productId === item.productId);
							if (existing) {
								elements = elements.map((it) => {
									if (it.productId === item.productId) {
										return {
											...it,
											quantity: it.quantity + item.quantity,
										};
									}
									return it;
								});
							} else {
								elements.push(item);
							}
						});
						return { ...data, inventory: elements };
					}
					return data;
				})) || [{ serviceTaskId, inventory: lineItems }],
		};

		if (userInventory) {
			let reducedUserInventory = { ...userInventory };
			lineItems.forEach((lineItem) => {
				const inventoryItems = reducedUserInventory.inventoryItems.map((it) =>
					lineItem.productId === it.productId ? { ...it, quantity: it.quantity - lineItem.quantity } : it,
				);
				reducedUserInventory = {
					...reducedUserInventory,
					inventoryItems,
				};
			});
			await this.db?.put(StoreName.UserInventory, reducedUserInventory, STORE_USER_INVENTORY_ID);
		}
		this.db?.put(StoreName.OrderInventory, { orderId, ...mutated });

		return this.addCommand({
			payload: {
				orderId,
				locationId,
				lineItems,
				propertyId,
				serviceTaskId,
			},
			commandType: CommandType.InventoryIssue,
			commandTitle: CommandTitle.InventoryIssue,
		}) as Promise<void>;
	}

	async removeInventory(
		orderId: string,
		locationId: string,
		lineItems: TransactionInventoryModel[],
		propertyId: string,
		serviceTaskId: string,
	) {
		const orderInventory = (await this.db?.get(StoreName.OrderInventory, orderId)) as OrderInventory | undefined;
		const userInventory = (await this.db?.get(StoreName.UserInventory, STORE_USER_INVENTORY_ID)) as UserInventory | undefined;
		const [{ productId, quantity }] = lineItems;

		const mutated: OrderInventory = {
			products: orderInventory?.products || [],
			locations: orderInventory?.locations || [],
			serviceTasksInventory:
				orderInventory?.serviceTasksInventory.map((data) => {
					if (data.serviceTaskId === serviceTaskId) {
						return {
							...data,
							inventory: data.inventory.map((inv) =>
								inv.productId === productId
									? {
										...inv,
										quantity: inv.quantity - quantity,
									}
									: inv,
							),
						};
					}

					return data;
				}) || [],
		};

		if (userInventory) {
			const increasedUserInventory = {
				...userInventory,
				inventoryItems: userInventory.inventoryItems.map((it) =>
					productId === it.productId ? { ...it, quantity: it.quantity + quantity } : it,
				),
			};
			await this.db?.put(StoreName.UserInventory, increasedUserInventory, STORE_USER_INVENTORY_ID);
		}
		this.db?.put(StoreName.OrderInventory, { orderId, ...mutated });

		return this.addCommand({
			payload: {
				orderId,
				locationId,
				lineItems,
				propertyId,
				serviceTaskId,
			},
			commandType: CommandType.InventoryReceive,
			commandTitle: CommandTitle.InventoryReceive,
		}) as Promise<void>;
	}

	evaluateServiceTaskStatus(orderId: string, serviceTaskId: string, actionName: string) {
		return this.addCommand({
			payload: {
				orderId,
				serviceTaskId,
				actionName,
			},
			commandType: CommandType.ServiceTaskEvaluateStatus,
			commandTitle: CommandTitle.ServiceTaskEvaluateStatus,
		}) as Promise<void>;
	}
}
