// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useMemo, useState } from 'react';
import { User, UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';
import { getService } from '@4r/mf-host';
import AuthService, { IAuthService } from './AuthService';
import AuthStorage, { IAuthStorage } from './AuthStorage';
import { Settings } from '../contexts/types';
import BASE_PATH from '../common/base-path';
import ROUTES from '../routes/constants';
import LoggerService from '../services/LoggerService';

// Note: Uncomment when you want to debug
/*
Log.setLogger(console);
Log.setLevel(Log.DEBUG);
*/

export type Auth = {
	authService?: IAuthService;
	userManager?: UserManager;
	isAuthenticated: boolean;
	isAuthenticationInProgress: boolean;
	getUser(): User['profile'] | undefined;
};

export type AuthServices = {
	userManager: UserManager;
	authStorage: IAuthStorage;
	authService: IAuthService;
};

const AuthContext = React.createContext<Auth>({
	authService: undefined,
	isAuthenticated: false,
	isAuthenticationInProgress: false,
	getUser: () => undefined,
});

const baseUrl = `${window.origin}${BASE_PATH.substring(0, BASE_PATH.length - 1)}`;
const logger = new LoggerService('Auth');

const TOKEN_REFRESH_RETRY_SECONDS = 2;

const AuthProvider: React.FunctionComponent<{ settings: Settings }> = ({ children, settings }) => {
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [isAuthenticationInProgress, setIsAuthenticationInProgress] = useState(false);
	const [isTokenRefreshInProgress, setIsTokenRefreshInProgress] = useState(false);

	const updateIsAuthenticationInProgress = (value: boolean) => {
		setIsAuthenticationInProgress(value);
	};

	const auth = useMemo<AuthServices>(() => {
		const flow = localStorage.getItem('sso_flow') || settings.IDENTITY_SERVER.FLOW || '';

		// See https://github.com/IdentityModel/oidc-client-js/wiki
		const oidcClientConfig: UserManagerSettings = {
			client_id: settings.IDENTITY_SERVER.CLIENT_ID,
			authority: settings.IDENTITY_SERVER.AUTHORITY.replace('{0}', flow),
			redirect_uri: `${baseUrl}${ROUTES.auth.redirectView}`,
			post_logout_redirect_uri: `${baseUrl}/logout`,
			scope: `openid profile offline_access ${settings.IDENTITY_SERVER.SCOPE}`,
			response_type: 'code',
			automaticSilentRenew: true,
			filterProtocolClaims: true,
			loadUserInfo: false,
			monitorSession: true,
			staleStateAgeInSeconds: 5,
			stateStore: new WebStorageStateStore({ store: window.localStorage }),
			userStore: new WebStorageStateStore({ store: window.localStorage }),
		};

		const userManager = new UserManager(oidcClientConfig);

		const authStorage = new AuthStorage(userManager, localStorage);
		const authService = new AuthService(userManager, authStorage, updateIsAuthenticationInProgress);

		const refreshAuthState = () => {
			// If the user is authenticated, or it expired but the refresh is in progress (e.g. coming from offline to online we need to wait on the token to refresh)
			setIsAuthenticated(authStorage.hasUser() || isTokenRefreshInProgress);

			// Note: Send new token to micro frontends
			const token = authService.getAccessToken();
			if (token) {
				getService('TokenService').setToken(token);
			}
		};

		const retryTokenRefresh = (e: unknown) => {
			const response = e as Response;
			if (response.status) {
				// When online and refresh failed - the user has to re-auth again
				logger.logInfo(`Could not refresh token, most likely the refresh token expired and user has to sign in`, e);
				setIsTokenRefreshInProgress(false);
				refreshAuthState();
				return;
			}

			// When token refresh error while in offline - keep trying...
			logger.logInfo(`Will retry token refresh in %d seconds`, TOKEN_REFRESH_RETRY_SECONDS);
			setIsTokenRefreshInProgress(true);
			setTimeout(async () => {
				if (!navigator.onLine) {
					// When not online yet, just check in the next iteration
					retryTokenRefresh(e);
					return;
				}
				// When online, try to refresh token
				try {
					await userManager.signinSilent();
					setIsTokenRefreshInProgress(false);
				} catch (ex) {
					retryTokenRefresh(ex);
				}
			}, TOKEN_REFRESH_RETRY_SECONDS * 1000);
		};

		userManager.events.addAccessTokenExpiring(() => {
			logger.logInfo('AccessTokenExpiring');
		});

		userManager.events.addSilentRenewError((e: Error) => {
			logger.logInfo('SilentRenewError', e);
			if (!isTokenRefreshInProgress) {
				retryTokenRefresh(e);
			}
		});

		userManager.events.addUserLoaded((user: User) => {
			logger.logInfo('UserLoaded', user);
		});

		authStorage.addEventListener(() => {
			refreshAuthState();
		});

		authStorage.refreshStorage();

		return { userManager, authStorage, authService };
	}, [settings]);

	const value = useMemo(
		() => ({
			authService: auth.authService,
			userManager: auth.userManager,
			isAuthenticated,
			isAuthenticationInProgress,
			getUser: () => {
				if (!auth.authStorage.hasUser()) {
					auth.authStorage.refreshStorage();
				}
				return auth.authStorage.getUserProfile();
			},
		}),
		[auth, isAuthenticated, isAuthenticationInProgress],
	);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export { AuthProvider, AuthContext };
