import { ReactElement, useEffect } from 'react';
import { useLocation } from 'react-router';
import { dispatch } from '../state/Store';
import { PATH_CONFIG } from '../routes/constants';

function usePageName(header: string, subheader: string | null = null, actions?: ReactElement | null): void {
	useEffect(() => {
		dispatch({ type: 'setPageName', pageName: { header, subheader, actions } });
	}, [header, subheader, actions]);
}

export default usePageName;

const idRegexp = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

const pathConfigMap = Object.values(PATH_CONFIG).reduce<Record<string, string | null>>(
	(aggr, { path, pageName }) => ({ ...aggr, [path]: pageName }),
	{},
);

export function usePageTitle() {
	// const [title, setTitle] = useState<string | null>(null);
	const location = useLocation();

	useEffect(() => {
		const pathChunks = location.pathname.split('/');
		const pathnameFromURL = pathChunks.map((chunk) => (idRegexp.test(chunk) ? ':id' : chunk)).join('/');
		const matchedTitle = pathConfigMap[pathnameFromURL];
		dispatch({ type: 'setPageName', pageName: { header: matchedTitle ?? '', subheader: null } });
	}, [location.pathname]);
}
