import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import { groupBy, useApiClient, useQueryString } from '@4r/module-common-mf-app';
import './OrdersPage.scss';
import { Card, Input, Space, Spin } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { OrderDetailsResponse, AppointmentClient, OrderClient, AppointmentModel } from '../../api/api';
import { TabDesc, FilterTabs } from '../../components/Tabs';
import OrderList from './OrderList';
import OrderMap from './OrderMap';
import OrderVendor from './OrderVendor';
import { OfflineOrderContext } from '../../contexts/OfflineOrderContext';
import TopBar from './TopBar';
import useSessionStorage from '../../common/useSessionStorageDayjs';
import fetchWithRetries from '../../common/fetchWithRetries';

dayjs.extend(isToday);
dayjs.extend(isTomorrow);

const tabs: TabDesc<string>[] = [
	{
		name: 'Schedule',
		id: 'schedule',
	},
	{
		name: 'Incomplete',
		id: 'incomplete',
	},
	{
		name: 'Assigned To Vendors',
		id: 'assignedToVendors',
	},
];

const defaultTabId = 'schedule';
const SESSION_KEY = 'APP_4SERVICES_SELECTED_ORDERS_DATE';

interface IOrderPage {
	dataLoading: boolean;
}

const OrdersPage = ({ dataLoading }: IOrderPage) => {
	const [tabId, setTabId] = useQueryString('tabId', defaultTabId, { allowedValues: tabs.map((x) => x.id) });
	const [selectedId, setSelectedId] = useQueryString('id');
	const [searchText, setSearchText] = useState<string | null>(null);
	const { orders, appointments } = useContext(OfflineOrderContext);
	const [isMapVisible, setIsMapVisible] = useState(false);
	const [ordersData, setOrdersData] = useState<OrderDetailsResponse[]>([]);
	const [selectedDate, setSelectedDate] = useSessionStorage(SESSION_KEY, dayjs().tz('UTC', true));
	const [appointmentsData, setAppointmentsData] = useState<AppointmentModel[]>([]);
	const [loading, setLoading] = useState(false);
	const [networkError, setNetworkError] = useState(false);
	const abortController = useRef<AbortController>();
	const screens = useBreakpoint();
	const isMobileOrTablet = !screens.xl;

	const orderApiClient = useApiClient(OrderClient);
	const appointmentClient = useApiClient(AppointmentClient);

	const onTabChange = (id: string) => {
		setSelectedId(undefined);
		setTabId(id);
	};

	const cachedData = useMemo(() => {
		if (selectedDate.isToday())
			return {
				orders: orders.filter((x) => x.properties?.isAssignedForToday),
				appointments: appointments.filter((x) => x.properties?.isAssignedForToday),
			};
		if (selectedDate.isTomorrow())
			return {
				orders: orders.filter((x) => x.properties?.isAssignedForTomorrow),
				appointments: appointments.filter((x) => x.properties?.isAssignedForTomorrow),
			};
		return { orders: [], appointments: [] };
	}, [selectedDate, orders, appointments]);

	const fetchData = useCallback(
		async (date: Dayjs) => {
			setNetworkError(false);
			setLoading(true);

			const isCached = date.isToday() || date.isTomorrow();

			fetchWithRetries(
				async () => {
					abortController.current?.abort();
					abortController.current = new AbortController();

					const orderResponse = await (!isCached
						? orderApiClient.queryByDate(date.startOf('day').toISOString(), false, abortController.current.signal)
						: cachedData.orders);
					const appointmentResponse = await (!isCached
						? appointmentClient.getUserAppointmentsByDate(date.startOf('day').toISOString(), abortController.current.signal)
						: cachedData.appointments);

					abortController.current = undefined;
					return { orderResponse, appointmentResponse };
				},
				0,
				30 * 1000,
			)
				.then((res) => {
					setOrdersData(res.orderResponse);
					setAppointmentsData(res.appointmentResponse);
					setLoading(false);
				})
				.catch((e) => {
					if (e.name !== 'AbortError') {
						setOrdersData([]);
						setAppointmentsData([]);
						// We show network error only if it's not abort error, and keep loading when it's abort error
						setNetworkError(true);
						setLoading(false);
					}
				});
		},
		[orderApiClient, appointmentClient, cachedData],
	);

	const tabFilteredData = useMemo(() => {
		switch (tabId) {
			case 'schedule':
			default:
				return ordersData.filter((x) => x.properties?.isAssignedForToday || x.properties?.isAssignedForTomorrow || x.properties === null);
			case 'incomplete':
				return orders.filter((x) => x.properties?.isIncomplete);
			case 'assignedToVendors':
				return orders.filter((x) => x.order.isVendorInternal === false);
		}
	}, [tabId, ordersData, orders]);

	const searchFilteredData = useMemo(() => {
		if (!searchText) return tabFilteredData;
		return tabFilteredData.filter((x) =>
			[x.order.name, x.order.property.propertyNo, ...Object.values(x.order.property.address)]
				.join(' ')
				.toLowerCase()
				.includes(searchText.toLowerCase()),
		);
	}, [tabFilteredData, searchText]);

	const vendorGroups = useMemo(
		() =>
			groupBy(
				searchFilteredData.filter((x) => !!x.order.vendor?.id),
				(x) => x.order.vendor?.id ?? '',
				(x) => x,
			),
		[searchFilteredData],
	);

	const appointmentByTab = useMemo(() => {
		if (tabId === 'incomplete' || networkError) return [];

		return appointmentsData;
	}, [appointmentsData, tabId, selectedDate, networkError]);

	const shouldHideDate = useMemo(() => tabId === 'assignedToVendors' || tabId === 'incomplete', [tabId]);

	useEffect(() => {
		fetchData(selectedDate);
	}, [selectedDate, fetchData]);

	return (
		<div className="orders-page page">
			<FilterTabs tabs={tabs} selected={tabId || defaultTabId} onChanged={onTabChange}>
				<Space direction="vertical" size="middle">
					<Input
						placeholder="Search by Order ID, Property ID or Property Address..."
						onChange={(e) => setSearchText(e.target.value)}
						allowClear
						size="large"
						className="property-search-input"
					/>
					<Card
						bodyStyle={{ padding: 0, borderRadius: 4 }}
						style={{ border: isMobileOrTablet ? 'none' : '1px solid #E5E7E9', background: 'transparent' }}
					>
						<TopBar
							isMapVisible={isMapVisible}
							setIsMapVisible={setIsMapVisible}
							selectedDate={selectedDate}
							setSelectedDate={setSelectedDate}
							hideDate={shouldHideDate}
							isMobileOrTablet={isMobileOrTablet}
						/>
						<OrderMap
							orders={searchFilteredData
								.filter((x) => x.order.property && x.order.property.geoCoordinates)
								.map((x) => ({
									id: x.order.id,
									latitude: x.order.property.geoCoordinates.latitude,
									longitude: x.order.property.geoCoordinates.longitude,
								}))}
							selectedId={selectedId || null}
							onSelect={(x) => setSelectedId(x || undefined)}
							isMapVisible={isMapVisible}
							isMobileOrTable={isMobileOrTablet}
						/>
						{tabId === 'assignedToVendors' ? (
							<>
								{dataLoading && <Spin className="flex justify-center" />}
								{Array.from(vendorGroups, ([key, value]) => (
									<OrderVendor key={key} orders={value} selectedId={selectedId || null} />
								))}
							</>
						) : (
							<OrderList
								ordersData={searchFilteredData}
								loading={dataLoading || loading}
								selectedId={selectedId || null}
								appointments={appointmentByTab}
								networkError={networkError}
								retry={() => fetchData(selectedDate)}
							/>
						)}
					</Card>
				</Space>
			</FilterTabs>
		</div>
	);
};

export default OrdersPage;
