import delay from './delay';

async function fetchWithRetriesInternal<T>(
	fetch: () => Promise<T>,
	intervalMs: number,
	retryMax: number,
	retryNo: number,
	callOnFail?: () => void,
): Promise<T> {
	try {
		if (retryNo > 0) {
			await delay(intervalMs);
		}
		const value = await fetch();
		return value;
	} catch (e) {
		const api = e as Response;
		if (retryNo < retryMax && (api.status !== undefined || api.status === 401 || navigator.onLine)) {
			// retry if online or 401
			return fetchWithRetriesInternal<T>(fetch, intervalMs, retryMax, retryNo + 1, callOnFail);
		}
		// else rethrow error

		callOnFail?.();
		throw e;
	}
}

function fetchWithRetries<T>(fetch: () => Promise<T>, retryMax = 3, intervalMs = 2000, callOnFail?: () => void): Promise<T> {
	return fetchWithRetriesInternal<T>(fetch, intervalMs, retryMax, 0, callOnFail);
}

export default fetchWithRetries;
