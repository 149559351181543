import {Col, Row, Typography} from "antd";
import React, {useContext} from "react";
import {useGlobalState} from "../state/Store";
import {UserContext} from "../contexts/UserContext";

const PageHeader = () => {
	const [pageName] = useGlobalState('pageName');
	const user = useContext(UserContext);

	// separate flag to extend if more conditions are needed
	const showHeader = !user.current?.vendor;

	return (
		<>
			{showHeader && <Row align="middle" className="mb-2 ml-3" justify="space-between">
				<Col>
					<Typography.Title level={2} style={{marginBottom: 0}}>
						{pageName.header}
					</Typography.Title>
					{pageName.subheader && (
						<Typography.Title
							level={3}
							type="secondary"
							style={{margin: '0 0 0 10px', paddingLeft: '10px', borderLeft: '2px #bbb solid'}}
						>
							{pageName.subheader}
						</Typography.Title>
					)}
				</Col>
				{pageName.actions && <Col>{pageName.actions}</Col>}
			</Row>}
		</>
	)
}

export default PageHeader;