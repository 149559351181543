import { I4EnterpriseContract, I4EnterpriseContracts, I4EnterpriseLeftMenu, I4EnterpriseLeftMenus } from '@4r/mf-contracts';

type LDFlagSet = Record<string, any>;

const getLowestPriority = (prio1: number, prio2: number): number => (prio1 <= prio2 ? prio1 : prio2);

/** Recursively enable/disable menu sub items based on LD flags */
const validateSubMenus = (leftMenus: I4EnterpriseLeftMenus, flags: LDFlagSet): I4EnterpriseLeftMenus =>
	Object.fromEntries(
		Object.entries(leftMenus).map<[string, I4EnterpriseLeftMenu]>(([key, leftMenu]) => [
			key,
			{
				...leftMenu,
				enabled: !leftMenu.enabledLDFlag || !!flags[leftMenu.enabledLDFlag],
				subMenus: leftMenu.subMenus ? validateSubMenus(leftMenu.subMenus, flags) : undefined,
			},
		]),
	);

/** Enable/disable all menu items based on LD flags */
export const validateMenus = (contracts: I4EnterpriseContracts, flags: LDFlagSet): I4EnterpriseContracts =>
	Object.fromEntries(
		Object.entries(contracts).map<[string, I4EnterpriseContract]>(([key, contract]) => [
			key,
			{
				...contract,
				enabled: !contract.enabledLDFlag || !!flags[contract.enabledLDFlag],
				leftMenus: validateSubMenus(contract.leftMenus, flags),
			},
		]),
	);

const mergeMicrofrontends = (menus: I4EnterpriseContracts, newMenu: I4EnterpriseContracts) => {
	const v = { ...menus };
	Object.entries(newMenu).forEach(([name, newValue]) => {
		const existingValue = v[name];
		if (existingValue) {
			v[name] = { ...existingValue, ...newValue };
		} else {
			v[name] = newValue;
		}
	});
	return v;
};

export const mergeMenus = (menus: I4EnterpriseContracts, newMenu: I4EnterpriseContracts): I4EnterpriseContracts => {
	const [newMenuMFKey] = Object.keys(newMenu);
	const [newMenuMFValue] = Object.values(newMenu);
	const [newMenuKey] = Object.keys(newMenuMFValue.leftMenus);
	const menusKeyList = Object.entries(menus).find(([, v]) => Object.keys(v.leftMenus).includes(newMenuKey));

	// New MFs without overlapping leftMenu
	if (Object.keys(menus).includes(newMenuMFKey) || !menusKeyList) {
		return mergeMicrofrontends(menus, newMenu);
	}

	// Adjustment to existing MF
	const [keyToCopy, valueToCopy] = menusKeyList;
	const newMenuValue = newMenuMFValue.leftMenus[newMenuKey];

	const subMenus = Object.fromEntries(
		Object.entries({
			...(valueToCopy.leftMenus[newMenuKey].subMenus ?? {}),
			...(newMenuValue.subMenus ?? {}),
		}).sort(([keyA], [keyB]) => keyA.localeCompare(keyB)),
	);

	const configCopy: I4EnterpriseContracts = {
		[keyToCopy]: {
			...valueToCopy,
			priority: getLowestPriority(newMenuMFValue.priority, valueToCopy.priority) ?? valueToCopy.priority,
			leftMenus: {
				...valueToCopy.leftMenus,
				[newMenuKey]: {
					...valueToCopy.leftMenus[newMenuKey],
					allPermissions: [...(valueToCopy.leftMenus[newMenuKey].allPermissions ?? []), ...(newMenuValue.allPermissions ?? [])],
					subMenus,
				},
			},
		},
	};

	return { ...menus, ...configCopy };
};
