enum StoreName {
	Appointments = 'appointments',
	Commands = 'commands',
	Common = 'common',
	Data = 'data',
	User = 'user',
	OrderInventory = 'order-inventory',
	UserInventory = 'user-inventory',
	Orders = 'orders',
	FileCommands = 'file-commands',
	Assets = 'assets',
	AssetTypes = 'asset-types',
	AssetTypesAttributes = 'asset-types-attributes',
	AssetTypesAllAttributes = 'asset-types-all-attributes',
	PropertyInfo = 'property-info',
}

export default StoreName;
