export interface ICurrentOrderIdService {
    getCurrentOrderId(): string | null;
    setCurrentOrderId(orderId: string | null): void;
}


export default class CurrentOrderIdService implements ICurrentOrderIdService {

    private currentOrderId: string | null = null;

    public getCurrentOrderId(): string | null {
        return this.currentOrderId;
    }

    public setCurrentOrderId(orderId: string | null): void {
        this.currentOrderId = orderId;
    }
}