import { Card, CardProps } from 'antd';
import React from 'react';

const BACKGROUND_COLOR = '#041624';
const FONT_COLOR = 'white';
const TOOLTIP_WIDTH = 340;
const FONT_WEIGHT = 600;

const ConditionIssueTooltipCard = ({ children, ...rest }: Omit<CardProps, 'size' | 'bordered' | 'style' | 'bodyStyle' | 'headStyle'>) => (
	<Card
		size="small"
		bordered={false}
		style={{
			width: TOOLTIP_WIDTH,
			background: BACKGROUND_COLOR,
			color: FONT_COLOR,
		}}
		bodyStyle={{
			paddingLeft: 0,
			paddingRight: 0,
			paddingTop: 0,
		}}
		headStyle={{
			color: FONT_COLOR,
			border: 'none',
			fontWeight: FONT_WEIGHT,
			padding: 0,
		}}
		// eslint-disable-next-line react/jsx-props-no-spreading
		{...rest}
	>
		{children}
	</Card>
);

export default ConditionIssueTooltipCard;
