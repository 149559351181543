import { ICommand, ICommandService } from '@4r/mf-contracts-4services';
import { IDBPDatabase } from 'idb';
import CommandHandlerCollection from '../CommandHandlerCollection';
import BaseOfflineCapableService from '../BaseOfflineCapableService';
import { ICommand as ICommandHost } from '../ICommand';

/**
 * Implements the @see ICommandService and adapts it to the 4Services offline infrastructure.
 */
class CommmandServiceAdapter extends BaseOfflineCapableService implements ICommandService {
    /**
     *
     */
    // eslint-disable-next-line no-useless-constructor
    constructor(private commandHandlerCollection: CommandHandlerCollection, db: IDBPDatabase) {
        super(db);
    }

    async submitCommand(command: ICommand): Promise<unknown | null> {
        const cmd = command as ICommandHost<unknown>;

        const handler = this.commandHandlerCollection.handlers[command.commandType];
        if (!handler) {
            throw new Error(`Handler for command type ${command.commandType} is not registered`);
        }

        let result: unknown;
        try {
            // Try handle if online
            result = await handler(cmd);
        } catch (e) {
            // If error, then detect if offline and if so record command for later, else rethrow exception.
            await this.handleError(e, cmd);
            // signify it is offline
            result = null;
        }
        return result;
    }
}

export default CommmandServiceAdapter;
