import React, { ReactElement, useContext } from 'react';
import { Card, Col, Row, Typography } from 'antd';
import { WarningFilled } from '@ant-design/icons';
import { AccessContext } from '@4r/module-common-authorization';
import { useHistory } from 'react-router-dom';

const NoAccessPage = (): ReactElement => {
	const access = useContext(AccessContext);
	const history = useHistory();

	React.useEffect(() => {
		if (access.loaded && access.permissions.length > 0) {
			history.push('/');
		}
	}, [access]);

	return (
		<Card bordered>
			<Row justify="center" gutter={16}>
				<Col xs={24}>
					<Row justify="center">
						<Typography.Paragraph type="warning">
							<WarningFilled style={{ fontSize: '4rem' }} />
						</Typography.Paragraph>
					</Row>
				</Col>
				<Typography.Title level={4}>Looks like your account doesn&apos;t have any permissions.</Typography.Title>
			</Row>
		</Card>
	);
};

export default NoAccessPage;
