import React from 'react';
import { useConfig } from '@4r/module-common-mf-app';
import './Map.scss';
import GoogleMapReact, { Coords, ClickEventValue } from 'google-map-react';
import { PinRed } from './Icon';
import { mapStyles } from '../common/mapStyle';

export type Pin = {
	// eslint-disable-next-line react/no-unused-prop-types
	id: string;
	isActive: boolean;
} & Coords;

type MapProps = {
	pins?: Pin[];
	center?: Coords;
	zoom?: number;
	selectedId?: string | null;
	onClick?: (pinId: string | null) => void;
};

const PinComponent = ({ isActive }: Pin) => (
	<div className={`pin ${isActive ? 'pin--active' : ''}`}>
		<PinRed />
	</div>
);

export const Map: React.FunctionComponent<MapProps> = ({ pins, onClick, center, zoom, selectedId }) => {
	const settings = useConfig();

	const [pinSelected, setPinSelected] = React.useState<{
		[key: string]: boolean;
	}>(selectedId ? { [selectedId]: true } : {});
	const [maps, setMaps] = React.useState<{
		map: google.maps.Map;
		maps: typeof google.maps;
		ref: Element | null;
	}>();

	const handleChildClick = (key: string) => {
		const v: { [key: string]: boolean } = {};
		v[key] = true;

		setPinSelected(v);

		if (onClick) {
			onClick(key);
		}
	};

	const handleClick = (value: ClickEventValue) => {
		if (value.event.target.closest('.pin') === null) {
			setPinSelected({});

			if (onClick) {
				onClick(null);
			}
		}
	};

	React.useEffect(() => {
		const panAndZoom = () => {
			if (pins && maps && maps.map && maps.maps) {
				// See https://stackoverflow.com/questions/19304574/center-set-zoom-of-map-to-cover-all-visible-markers

				const bounds = new maps.maps.LatLngBounds();

				pins.forEach((pin) => {
					bounds.extend(pin);
				});

				maps.map.fitBounds(bounds);
				const zoomValue = maps.map.getZoom() ?? 10;
				maps.map.setZoom(Math.min(16, zoomValue));
			}
		};

		panAndZoom();
	}, [pins, maps]);

	React.useEffect(() => {
		maps?.map.setOptions({ styles: mapStyles });
	}, [maps?.map]);

	return (
		<div className="map">
			<GoogleMapReact
				bootstrapURLKeys={{
					key: settings?.GOOGLE_MAP_KEY || '',
					libraries: 'places',
				}}
				defaultCenter={center}
				defaultZoom={zoom}
				onGoogleApiLoaded={(mapsObj) => setMaps(mapsObj)}
				onClick={handleClick}
				onChildClick={handleChildClick}
			>
				{pins?.map((pin) => (
					<PinComponent id={pin.id} key={pin.id} lat={pin.lat} lng={pin.lng} isActive={pinSelected[pin.id]} />
				))}
			</GoogleMapReact>
		</div>
	);
};
