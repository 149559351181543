import { getService } from '@4r/mf-app';
import MessageBusService from '@4r/mf-app/dist/shared/src/services/MessageBusService';
import { IDBPDatabase } from 'idb';
import { v1 as uuidv1 } from 'uuid';
import { IPayload } from '../../contexts/OfflineContext';
import { ICommand } from './ICommand';
import StoreName from '../StoreName';
import MessageBusEvent from '../MessageBusEvents';
import { ICurrentOrderIdService } from '../CurrentOrderIdService';
import ServiceNames from '../Services';

export enum OfflineEvents {
	CommandAddedEvent = 'command-added-event',
	CommandPendingEvent = 'command-pending-event',
	CommandRemovedEvent = 'command-removed-event',
	QueueStartEvent = 'command-queue-started',
	QueueStopEvent = 'command-queue-stopped',
	QueueRunStartEvent = 'command-queue-run-started',
	QueueRunStopEvent = 'command-queue-run-stopped',
}

abstract class BaseOfflineCapableService {
	protected db: IDBPDatabase | null;

	private readonly messageBusService: MessageBusService;

	constructor(db: IDBPDatabase) {
		this.db = db;
		this.messageBusService = getService('MessageBusService');

	}

	protected async handleError<T>(error: any, payload: ICommand<T>, storeName?: StoreName): Promise<unknown> {
		const event = error as Response;

		if (event.status && event.status !== 401) {
			this.messageBusService.emit(MessageBusEvent.UploadLogsEvent, {
				command: payload,
				error,
			});
			throw error;
		}

		return this.addCommand(payload, storeName ?? StoreName.Commands);
	}


	protected async addCommand<T>(value: IPayload<T>, storeName: StoreName = StoreName.Commands): Promise<unknown> {
		const commandId = uuidv1();
		let currentOrderId = value.orderId;
		if (!currentOrderId) {
			currentOrderId = (getService(ServiceNames.CurrentOrderIdService) as ICurrentOrderIdService).getCurrentOrderId();
			if (!currentOrderId) {
				console.log('Order Id is not available');
				currentOrderId = "00000000-0000-0000-0000-000000000000";
			}
		}

		const command: ICommand<T> = {
			...value,
			commandId,
			orderId: currentOrderId,
			_timestamp: new Date().getTime(),
			_status: 'Pending',
		};

		try {
			await this.db?.add(storeName, command, `${value.commandType}-${commandId}`);
			console.log('Command added to queue', command);
			this.messageBusService.emit(OfflineEvents.CommandAddedEvent, command);
		} catch (e) {
			this.messageBusService.emit(MessageBusEvent.OfflineFileUploadFailed, {
				storeName,
				command: value.commandType,
			});
		}
		return null;
	}
}

export default BaseOfflineCapableService;
