import { IDBPDatabase } from 'idb';
import { IOrderService } from '@4r/mf-contracts-4services';
import BaseOfflineCapableService from '../Offline/BaseOfflineCapableService';
import { ICommandHandlerCollection } from '../Offline/ICommandHandlerCollection';
import { CommandTitle, CommandType } from '../CommandTypes';
import { ICommand } from '../Offline/ICommand';
import {
	MutateOrderStateRequest,
	AddOrUpdateNoteRequest,
	OrderDetailsResponse,
	SearchWorkOrdersRequest,
	OrderModel,
	MutateOrderStateCommand,
	CreateOrderNoteCommand,
	UpdateOrderNoteCommand,
	UpdateOrderNoteStatusCommand,
	QueryOrderFilesResponse,
} from '../../api/api';
import StoreName from '../StoreName';
import fetchWithRetries from '../../common/fetchWithRetries';
import { IOrderCommandService } from '../OrderCommandService/IOrderCommandService';

export default class OfflineOrderServiceDecorator extends BaseOfflineCapableService implements IOrderService {
	constructor(
		private target: IOrderService,
		private commandService: IOrderCommandService,
		db: IDBPDatabase,
		handlers: ICommandHandlerCollection,
	) {
		super(db);

		handlers.add(CommandType.OrderMutateState, (command: ICommand<MutateOrderStateCommand>) => this.commandService.submitCommand(command));

		handlers.add(CommandType.OrderNoteCreate, (command: ICommand<CreateOrderNoteCommand>) => this.commandService.submitCommand(command));

		handlers.add(CommandType.OrderNoteUpdate, (command: ICommand<UpdateOrderNoteCommand>) => this.commandService.submitCommand(command));

		handlers.add(CommandType.OrderNoteStatusUpdate, (command: ICommand<UpdateOrderNoteStatusCommand>) =>
			this.commandService.submitCommand(command),
		);
	}

	async mutateState(request: MutateOrderStateRequest) {
		await this.addCommand<MutateOrderStateCommand>({
			payload: request,
			commandType: CommandType.OrderMutateState,
			commandTitle: CommandTitle.OrderMutateState,
		});
	}

	async addNote(request: AddOrUpdateNoteRequest) {
		await this.addCommand<CreateOrderNoteCommand>({
			payload: request,
			commandType: CommandType.OrderNoteCreate,
			commandTitle: CommandTitle.OrderNoteCreate,
		});
	}

	async updateNote(request: AddOrUpdateNoteRequest) {
		await this.addCommand<UpdateOrderNoteCommand>({
			payload: request,
			commandType: CommandType.OrderNoteUpdate,
			commandTitle: CommandTitle.OrderNoteUpdate,
		});
	}

	async updateNoteStatus(orderId: string, id: string, isRead: boolean) {
		await this.addCommand<UpdateOrderNoteStatusCommand>({
			payload: {
				id,
				isRead,
			},
			commandType: CommandType.OrderNoteStatusUpdate,
			commandTitle: CommandTitle.OrderNoteStatusUpdate,
		});
	}

	async findById(id: string) {
		try {
			const order = await fetchWithRetries(() => this.target.findById(id));
			return order;
		} catch (error) {
			const order = await this.db?.get(StoreName.Orders, id);
			return order;
		}
	}

	async getOrders(today: string): Promise<OrderDetailsResponse[]> {
		try {
			const orders = await fetchWithRetries(() => this.target.getOrders(today, false));
			return orders;
		} catch (error) {
			const orders = await this.db?.getAll(StoreName.Orders);
			return orders as OrderDetailsResponse[];
		}
	}

	async getOrdersFiles(today: string): Promise<QueryOrderFilesResponse> {
		const orderFiles = await fetchWithRetries(() => this.target.getOrdersFiles(today));
		return orderFiles;
	}

	async search(request: SearchWorkOrdersRequest): Promise<OrderModel[]> {
		const orders = ((await this.db?.getAll(StoreName.Orders)) ?? []) as OrderDetailsResponse[];
		return orders
			.filter(
				({ order }) =>
					request.statusIds?.includes(order.statusId) &&
					order.bookings.some((booking) => request.resourceIds?.includes(booking.resourceId)),
			)
			.map(({ order }) => order);
	}
}
