/* eslint-disable no-underscore-dangle */
import React, { ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Progress, Result, Row, Table } from 'antd';
import { getService } from '@4r/mf-app';
import { SyncOutlined } from '@ant-design/icons';
import { ICommandStatus, OfflineContext } from '../../contexts/OfflineContext';
import StoreName from '../../services/StoreName';
import CommandQueueService from '../../services/Offline/CommandQueueService';
import { OfflineEvents } from '../../services/Offline/BaseOfflineCapableService';
import { ICommand } from '../../services/Offline/ICommand';

const operationsColumns = [
	{
		title: 'Operation',
		dataIndex: 'commandTitle',
		sorter: true,
		key: 'commandTitle',
	},
	{
		title: 'Status',
		sorter: true,
		width: 200,
		key: '_status',
		render: (v: any, x: any) => (
			<Row align="middle" gutter={6} key={x.commandId}>
				<Col>
					{x._status === ICommandStatus.Syncing ? (
						<div className="status-page__icon status-page__icon--syncing" />
					) : (
						<div className="status-page__icon" />
					)}
				</Col>
				<Col>{x._status || ICommandStatus.Pending}</Col>
			</Row>
		),
	},
];

type QueueStatusCardProps = {
	commandQueueService: CommandQueueService;
	label: string;
};

const QueueStatusCard: React.FC<QueueStatusCardProps> = ({ label, commandQueueService }) => {
	const [commands, setCommands] = useState<ICommand<unknown>[]>([]);
	const [isRunning, setIsRunning] = useState(false);
	const [error, setError] = useState<string | undefined>(undefined);

	const commandsArray = useMemo(() => Object.values(commands), [commands]);
	const syncingCommands = useMemo(() => Object.values(commands).filter((cmd) => cmd._status === ICommandStatus.Syncing), [commandsArray]);

	const refreshCommands = async () => {
		const cmds = await commandQueueService.getCommands();
		setCommands(cmds);
	};

	const onCommandPending = (commandId: string) => {
		setCommands((oldCommands) => {
			const newCommands = [...oldCommands];
			const i = newCommands.findIndex((x) => x.commandId === commandId);
			if (i !== -1) {
				newCommands[i]._status = ICommandStatus.Syncing;
			}
			return newCommands;
		});
	};

	const onCommandDelete = (commandId: string) => {
		setCommands((oldCommands) => {
			const newCommands = [...oldCommands];
			const i = newCommands.findIndex((x) => x.commandId === commandId);
			if (i !== -1) {
				newCommands.splice(i);
			}
			return newCommands;
		});
	};

	useEffect(() => {
		refreshCommands();
		setIsRunning(commandQueueService.isStarted());
	}, [commandQueueService]);

	useEffect(() => {
		const messageBus = getService('MessageBusService');

		const sub1 = messageBus.on(OfflineEvents.CommandAddedEvent, () => refreshCommands());
		const sub2 = messageBus.on(OfflineEvents.CommandPendingEvent, (e) => {
			onCommandPending(e.detail);
		});
		const sub3 = messageBus.on(OfflineEvents.CommandRemovedEvent, (e) => {
			onCommandDelete(e.detail);
		});
		const sub4 = messageBus.on(OfflineEvents.QueueStartEvent, (e) => {
			if (e.detail === commandQueueService.storeName) {
				setIsRunning(true);
				setError(commandQueueService.getLastError());
			}
		});
		const sub5 = messageBus.on(OfflineEvents.QueueStopEvent, (e) => {
			if (e.detail === commandQueueService.storeName) {
				setIsRunning(false);
				setError(commandQueueService.getLastError());
			}
		});

		return () => {
			sub1.removeListener();
			sub2.removeListener();
			sub3.removeListener();
			sub4.removeListener();
			sub5.removeListener();
		};
	}, [commandQueueService]);

	return (
		<div className="status-page__card__column status-page__card__column--bordered">
			<Row justify="center" align="middle">
				<Col flex="auto">Status: {isRunning ? 'Started' : 'Paused'}</Col>
				<Col>
					<Button type="primary" disabled={isRunning} onClick={() => commandQueueService.start()} icon={<SyncOutlined />}>
						Resume Sync
					</Button>
				</Col>
			</Row>
			<Row justify="center" align="middle">
				<Col flex="auto">{error}</Col>
			</Row>
			<Row justify="center" className="status-page__progress">
				<Col>
					<Progress
						type="circle"
						percent={syncingCommands.length / commandsArray.length}
						width={240}
						format={() => (
							<>
								<div>
									{syncingCommands.length}/{commandsArray.length}
								</div>
								<span className="status-page__progress__title">{label}</span>
							</>
						)}
					/>
				</Col>
			</Row>
			<Row justify="center">
				<Col xs={24}>
					{commandsArray.length ? (
						<Table rowKey="commandId" columns={operationsColumns} dataSource={commandsArray} />
					) : (
						<Result icon={() => null} subTitle={`There are no ${label} yet needing to be synced.`} />
					)}
				</Col>
			</Row>
		</div>
	);
};

const StatusTab = (): ReactElement => {
	const { commandQueues } = useContext(OfflineContext);

	return (
		<Card bordered className="status-page__card">
			<Row gutter={[16, 16]}>
				<Col xs={12}>
					<QueueStatusCard label="operations" commandQueueService={commandQueues[StoreName.Commands]} />
				</Col>
				<Col xs={12}>
					<QueueStatusCard label="files" commandQueueService={commandQueues[StoreName.FileCommands]} />
				</Col>
			</Row>
		</Card>
	);
};

export default StatusTab;
