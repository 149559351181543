import './Badge.scss';
import React from 'react';

interface BadgeProps {
	text?: string;
	colorClass?: string;
	className?: string;
}

const Badge: React.FC<BadgeProps> = ({ text, colorClass, className }) => (
	<span className={`badge ${colorClass} ${className}`.trim()}>{text}</span>
);

export default Badge;
