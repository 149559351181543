export function isObject(object: unknown) {
	return object != null && typeof object === 'object';
}

export default function deepEqual(a: unknown, b: unknown) {
	if (isObject(a)) {
		if (!isObject(b)) {
			return false;
		}
		const object1 = a as Record<string, unknown>;
		const object2 = b as Record<string, unknown>;

		const keys1 = Object.keys(object1);
		const keys2 = Object.keys(object2);

		if (keys1.length !== keys2.length) {
			return false;
		}

		for (const key of keys1) {
			const val1 = object1[key];
			const val2 = object2[key];
			if (!deepEqual(val1, val2)) {
				return false;
			}
		}
		return true;
	}

	if (Array.isArray(a)) {
		if (!Array.isArray(b)) {
			return false;
		}

		const array1 = a as unknown[];
		const array2 = b as unknown[];

		if (array1.length !== array2.length) {
			return false;
		}

		for (let i = 0; i < array1.length; i++) {
			if (!deepEqual(array1[i], array2[i])) {
				return false;
			}
		}
		return true;
	}

	return Object.is(a, b);
}
