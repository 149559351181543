import { Table, Input, Tabs, Space, Radio } from 'antd';
import React, { useState } from 'react';
import { Link, withRouter, useHistory } from 'react-router-dom';
import { FormOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import { useApiLoad, useApiClient, dateSorter, nameSorter, stringSorter } from '@4r/module-common-mf-app';
import { ReviewClient, ReviewListItem, SearchReviewsRequest } from '../../api/api';
import ReviewStatus from './ReviewStatus';

dayjs.extend(isoWeek);

const ApprovalsPage: React.FunctionComponent = () => {
	const history = useHistory();
	const [query, setQuery] = useState('');
	const [createdFrom, setCreatedFrom] = useState<string | undefined>();
	const [createdTo, setCreatedTo] = useState<string | undefined>();
	const apiClient = useApiClient(ReviewClient);
	const [data, dataLoading, refreshData, , hasError] = useApiLoad(async () => {
		const request: SearchReviewsRequest = {
			targetType: 'ORDER',
			query,
			createdFrom,
			createdTo,
		};
		const searchReviewsResponse = await apiClient.searchReviews(request);
		return searchReviewsResponse.items;
	}, []);
	const { Search } = Input;
	const applyDateFilter = (from: string | undefined, to: string | undefined) => {
		setCreatedFrom(from);
		setCreatedTo(to);
		refreshData();
	};

	if (hasError) {
		history.push('/');
	}

	return (
		<div className="page lg:shadow-xl rounded-lg bg-white p-6">
			<Tabs defaultActiveKey="1">
				<Tabs.TabPane tab="Inspections" key="1" />
				<Tabs.TabPane tab="PTO" key="2" />
				<Tabs.TabPane tab="Bids / Quotes" key="3" />
			</Tabs>
			<Search
				className="my-2"
				placeholder="search by work order name or technician"
				allowClear
				onChange={(e) => setQuery(e.target.value)}
				onSearch={() => {
					refreshData();
				}}
			/>
			<Space style={{ marginBottom: 16 }}>
				<Radio.Group defaultValue="all">
					<Radio.Button value="all" onClick={() => applyDateFilter(undefined, undefined)}>
						All
					</Radio.Button>
					<Radio.Button value="this-week" onClick={() => applyDateFilter(dayjs().startOf('isoWeek').format(), undefined)}>
						This week
					</Radio.Button>
					<Radio.Button value="this-month" onClick={() => applyDateFilter(dayjs().startOf('month').format(), undefined)}>
						This month
					</Radio.Button>
					<Radio.Button value="older" onClick={() => applyDateFilter(undefined, dayjs().startOf('month').format())}>
						Older
					</Radio.Button>
				</Radio.Group>
			</Space>
			<Table<ReviewListItem> dataSource={data} rowKey={(record) => record.reviewId} loading={dataLoading}>
				<Table.Column<ReviewListItem> title="Work order" dataIndex="name" key="name" sorter={nameSorter} />
				<Table.Column<ReviewListItem> title="Address" dataIndex="address" key="address" />
				<Table.Column<ReviewListItem>
					title="Technician"
					dataIndex="technician"
					key="technician"
					sorter={(a, b) => stringSorter(a.technician, b.technician)}
				/>
				<Table.Column<ReviewListItem>
					title="Submitted On"
					dataIndex="submitted"
					key="submitted"
					sorter={(a, b) => dateSorter(a.submitted, b.submitted)}
					render={(key, record) => dayjs(record.submitted).format('MM-DD-YYYY')}
				/>
				<Table.Column<ReviewListItem> title="# Of Issues" dataIndex="numberOfIssues" key="numberOfIssues" />
				<Table.Column<ReviewListItem> title="# Of Remedies" dataIndex="numberOfRemedies" key="numberOfRemedies" />
				<Table.Column<ReviewListItem>
					title="Status"
					dataIndex="status"
					key="status"
					width={150}
					sorter={(a, b) => stringSorter(a.status, b.status)}
					render={(key, record) => <ReviewStatus status={record.status} />}
				/>
				<Table.Column<ReviewListItem>
					title="Actions"
					render={(key, record) => (
						<Link to={`/approvals/${record.reviewId}`}>
							<FormOutlined />
						</Link>
					)}
				/>
			</Table>
		</div>
	);
};

export default withRouter(ApprovalsPage);
