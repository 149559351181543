import { useState, useEffect, useContext } from 'react';
import { ReferenceDataContext } from '../contexts/ReferenceDataContext';
import { TaxonomyModel } from '../api/api';

function useTaxonomy(name: string): [TaxonomyModel | null] {
	const { globalData } = useContext(ReferenceDataContext);
	const [data, setData] = useState<TaxonomyModel | null>(null);

	useEffect(() => {
		const fetch = async () => {
			const { taxonomies } = globalData;
			if (taxonomies) {
				setData(taxonomies[name]);
			}
		};

		fetch();
	}, [name, globalData]);

	return [data];
}

export default useTaxonomy;
