import { ICommand, ICommandHandlerMethod, ICommandHandlerRegistryService } from '@4r/mf-contracts-4services';
import { getService } from '@4r/mf-host';
import CommandHandlerCollection from '../CommandHandlerCollection';
import { COMMAND_HANDLER_REGISTERED_EVENT, CommandHandlerRegisteredEvent } from './CommandHandlerRegisteredEvent';

class CommandHandlerRegistryServiceAdapter implements ICommandHandlerRegistryService {
	// eslint-disable-next-line no-useless-constructor, no-empty-function
	constructor(private commandHandlerCollection: CommandHandlerCollection) {}

	registerCommandHandlers(name: string, handlers: { [commandType: string]: ICommandHandlerMethod<ICommand> }): () => void {
		Object.keys(handlers).forEach((commandType) => this.commandHandlerCollection.add(commandType, handlers[commandType]));

		// notify the particular MF registerd its handlers
		getService('MessageBusService').emit(COMMAND_HANDLER_REGISTERED_EVENT, { name } as CommandHandlerRegisteredEvent);

		return () => {
			Object.keys(handlers).forEach((commandType) => this.commandHandlerCollection.remove(commandType, handlers[commandType]));
		};
	}
}

export default CommandHandlerRegistryServiceAdapter;
