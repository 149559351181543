import { message } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useApiClient } from '@4r/module-common-mf-app';
import { AuthContext } from '../authentication/AuthProvider';
import { GlobalReferenceDataModel, ReferenceClient, VendorModel } from '../api/api';

interface IReferenceData {
	globalData: GlobalReferenceDataModel;
	vendorData: VendorModel[];
}

const initialData: IReferenceData = {
	globalData: {},
	vendorData: [],
};

const ReferenceDataContext = React.createContext<IReferenceData>(initialData);

const ReferenceDataProvider: React.FunctionComponent = ({ children }) => {
	const { isAuthenticated } = useContext(AuthContext);
	const [data, setData] = useState<IReferenceData>(initialData);
	const referenceClient = useApiClient(ReferenceClient);

	const getData = async () => {
		try {
			if (!Object.keys(data.globalData).length || !data.vendorData.length) {
				const [globalData, userData] = await Promise.all([referenceClient.getGlobalData(), referenceClient.getUserData()]);
				setData({ globalData, vendorData: userData.vendors });
			}
		} catch (e) {
			message.error('Error getting data.');
		}
	};

	useEffect(() => {
		if (isAuthenticated) {
			getData();
		}
	}, [isAuthenticated]);

	return <ReferenceDataContext.Provider value={data}>{children}</ReferenceDataContext.Provider>;
};

export { ReferenceDataProvider, ReferenceDataContext };
