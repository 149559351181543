import { ReactElement } from 'react';

export interface PageName {
	header: string;
	subheader: string | null;
	actions?: ReactElement | null;
}

export enum PageStatus {
	online,
	offline,
	syncing,
	error,
}

export type Action = { type: 'setPageName'; pageName: PageName } | { type: 'setOnlineStatus'; status: PageStatus };
