import { createStore } from 'react-hooks-global-state';
import preval from 'preval.macro';
import reducer from './Reducer';
import { PageName, PageStatus } from './Actions';

export type AppState = {
	online: PageStatus;
	version: string;
	pageName: PageName;
};

const initialState: AppState = {
	online: navigator.onLine ? PageStatus.online : PageStatus.offline,
	version: `${process.env.REACT_APP_VERSION}-${preval`module.exports = new Date().toISOString().slice(0, -5).replace(/T|-|:/gi, '');`}`,
	pageName: { header: '', subheader: null },
};

export const { dispatch, useGlobalState } = createStore(reducer, initialState);
