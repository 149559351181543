import { ICommandHandlerMethod } from './ICommandHandlerMethod';
import { ICommand } from './ICommand';
import { ICommandHandlerCollection } from './ICommandHandlerCollection';

export default class CommandHandlerCollection implements ICommandHandlerCollection {
	public readonly handlers: { [commandType: string]: ICommandHandlerMethod<ICommand<unknown>> } = {};

	add<T extends ICommand<unknown>>(commandType: string, commandHandler: ICommandHandlerMethod<T>): void {
		this.handlers[commandType] = <ICommandHandlerMethod<ICommand<unknown>>>commandHandler;
	}

	remove(commandType: string, commandHandler: ICommandHandlerMethod<ICommand<unknown>>) {
		const existingHandler = this.handlers[commandType];
		// Remove only when same instance still registered
		if (existingHandler === commandHandler) {
			delete this.handlers[commandType];
		}
	}
}
