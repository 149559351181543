import { AccessContext, GuardedRoute } from '@4r/module-common-authorization';
import { handleCommonError } from '@4r/module-common-error-handler';
import React, { useContext } from 'react';
import { withRouter, RouteComponentProps, useHistory } from 'react-router-dom';
import { AuthContext } from './AuthProvider';

const Challenge: React.FC<RouteComponentProps> = ({ location }) => {
	const auth = React.useContext(AuthContext);

	React.useEffect(() => {
		if (!auth.isAuthenticationInProgress) {
			const redirectPath = location.pathname + location.search;
			handleCommonError(async () => auth.authService?.login(redirectPath));
		}
	});

	return null;
};

interface Props {
	path: string;
	permissionType?: string;
	anyPermissionTypes?: string[];
}

const GuardedRouteAutoLogin: React.FC<Props> = ({ path, permissionType, anyPermissionTypes, children }) => {
	const auth = React.useContext(AuthContext);
	const history = useHistory();
	const access = useContext(AccessContext);

	React.useEffect(() => {
		if (access.loaded && access.permissions.length === 0) {
			history.push('/no-access');
		}
	}, [path, auth, access]);

	return (
		<GuardedRoute
			path={path}
			permissionType={permissionType}
			anyPermissionTypes={anyPermissionTypes}
			redirectToPage
			isAuthenticated={auth.isAuthenticated}
			notAuthenticatedComponent={withRouter(Challenge)}
		>
			{children as React.ReactElement}
		</GuardedRoute>
	);
};

export default GuardedRouteAutoLogin;
