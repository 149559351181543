import dayjs from 'dayjs';
import { getDatePropertySorter } from '@4r/module-common-mf-app';
import {
	OrderBookingModel,
} from '../api/api';
import { ResourceBookingStatusId, WorkOrderStatusId } from './consts';

function getCurrentBooking(orderStatusId?: string, bookings?: OrderBookingModel[]): OrderBookingModel | undefined {
	// matches logic on backend
	if (!orderStatusId || !bookings?.length) return undefined;
	const getClosestBooking = (filteredBookings: OrderBookingModel[]) => {
		const currentUTC = dayjs.utc();
		filteredBookings.sort(getDatePropertySorter((x) => x.startTime));
		return (
			// first that falls into the booked timeframe
			filteredBookings.find((x) => currentUTC.isBetween(dayjs.utc(x.startTime), dayjs.utc(x.endTime), 'date', '[]')) ||
			// otherwise take the last that had the job (in case he took more time than needed for the job)
			filteredBookings.reverse().find((x) => currentUTC.isAfter(dayjs.utc(x.endTime))) ||
			// otherwise take the next future booking
			filteredBookings.reverse().find((x) => currentUTC.isBefore(dayjs.utc(x.endTime)))
		);
	};

	let booking: OrderBookingModel | undefined;

	switch (orderStatusId) {
		case WorkOrderStatusId.InProgress:
			booking = getClosestBooking(
				bookings.filter((x) => [ResourceBookingStatusId.InProgress, ResourceBookingStatusId.Traveling].includes(x.statusId)),
			);
			break;
		case WorkOrderStatusId.Scheduled:
			booking = getClosestBooking(bookings.filter((x) => x.statusId === ResourceBookingStatusId.Scheduled));
			break;
		default:
	}

	return booking || getClosestBooking(bookings);
}

export default getCurrentBooking;