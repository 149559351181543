import React, { ReactElement, useContext, useEffect } from 'react';
import { Button, Modal, Row, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import { ReactComponent as LogoutSVG } from '../../assets/svg/logout.svg';
import { AuthContext } from '../../authentication/AuthProvider';

const LogoutPage = (): ReactElement => {
	const { isAuthenticated } = useContext(AuthContext);
	const history = useHistory();

	useEffect(() => {
		if (isAuthenticated) {
			history.push('/');
		}
	}, [isAuthenticated]);

	const onClose = () => {
		history.push('/');
	};

	return (
		<Modal
			className="fullscreen-modal"
			title="Logout"
			okText="Logout"
			visible
			onCancel={onClose}
			cancelButtonProps={{ type: 'dashed' }}
			footer={[
				<Button key="submit" type="primary" onClick={onClose}>
					Ok
				</Button>,
			]}
		>
			<Row justify="center" style={{ marginBottom: 24 }}>
				<Typography.Text type="success">
					<LogoutSVG />
				</Typography.Text>
			</Row>
			<Row justify="center">
				<Typography.Title style={{ fontWeight: 700 }} level={4}>
					You have successfully logged out.
				</Typography.Title>
			</Row>
			<Row justify="center">Thank you for using 4Services dashboard.</Row>
			<Row justify="center">Have a nice day!</Row>
		</Modal>
	);
};

export default LogoutPage;
