enum MessageBusEvent {
	OrderUpdatedEvent = 'order-updated-event',
	LockboxListUpdatedEvent = 'lockbox-list-updated-event',
	InventoryUpdatedEvent = 'inventory-updated-event',
	FileUploadStateUpdated = 'file-upload-state-updated-event',
	FilesSelected = 'file-upload-selected',
	AddConditionIssue = 'add-condition-issue',
	DeleteConditionIssue = 'delete-condition-issue',
	AddRemedy = 'add-remedy',
	DeleteRemedy = 'delete-remedy',
	EscalateConditionIssue = 'escalate-condition-issue',
	RemoveFile = 'remove-file',
	AddBid = 'add-bid',
	OfflineFileUploadFailed = 'offline-file-upload-failed',
	OfflineCommandSyncingFailed = 'offline-command-syncing-failed',
	UploadLogsEvent = 'upload-logs-event'
}

export default MessageBusEvent;
