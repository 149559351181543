import { SignalRHub } from '@4r/module-common-signalr';

export type BookingChangedNotificationModel = {
	resourceId: string;
	resourceBookingId: string;
	orderId: string;
	statusId: string;
};

export type NotifyBookingChangedHandler = (message: BookingChangedNotificationModel) => void;

export class UserOrdersHub extends SignalRHub {
	static id = 'UserOrdersHub';

	onNotifyBookingChanged(handler: NotifyBookingChangedHandler): (() => void) | null {
		return this.on('NotifyBookingChanged', handler);
	}
}
