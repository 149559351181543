import React, { useState, useCallback, useMemo } from 'react';
import { Button, DatePicker, ButtonProps, Grid, Switch } from 'antd';
import { CalendarOutlined, ChevronLeft, ChevronRight } from '@4r/module-common-ant-components';
import dayjs, { Dayjs } from 'dayjs';

import utc from 'dayjs/plugin/utc';
import tiz from 'dayjs/plugin/timezone';

import './TopBar.scss';

dayjs.extend(utc);
dayjs.extend(tiz);

export const isDisabledDate = (date: Dayjs) => {
	const today = dayjs().endOf('day').tz('UTC', true);
	const thirtyDaysBack = today.subtract(31, 'days');
	const thirtyDaysForward = today.add(30, 'days');
	return date.isBefore(thirtyDaysBack) || date.isAfter(thirtyDaysForward);
};

interface ButtonWithIconProps extends Pick<ButtonProps, 'size' | 'onClick' | 'type' | 'disabled'> {
	icon: React.ReactNode;
}

const ButtonWithIcon: React.FC<ButtonWithIconProps> = (props) => {
	const { icon: Icon, size = 'middle', type = 'dashed', onClick, disabled = false } = props;

	const sizeClassName = ` size-${size}`;

	return (
		<Button className={`button-with-icon${sizeClassName}`} type={type} onClick={onClick} disabled={disabled}>
			{Icon}
		</Button>
	);
};

interface TopBarProps {
	hideDate: boolean;
	isMapVisible: boolean;
	isMobileOrTablet: boolean;
	setIsMapVisible: React.Dispatch<React.SetStateAction<boolean>>;
	selectedDate: Dayjs;
	setSelectedDate: React.Dispatch<React.SetStateAction<Dayjs>>;
}

const TopBar = (props: TopBarProps) => {
	const { hideDate, isMapVisible, isMobileOrTablet, setIsMapVisible, selectedDate, setSelectedDate } = props;

	const [isDatePickerOpen, setDatePickerOpen] = useState<boolean>(false);

	const screens = Grid.useBreakpoint();
	const isMobile = !screens.md && !screens.lg && !screens.xl;

	const isToday = useMemo(() => selectedDate.isToday(), [selectedDate]);

	const handleDateChagne = useCallback(
		(date: Dayjs) => {
			// This is fix for AntD 'Today" button, which improperly returns Dayjs object
			setSelectedDate(dayjs(date));
		},
		[setSelectedDate],
	);

	const className = useMemo(
		() => `orders-top-bar${isMobileOrTablet ? ' mobile' : ''}${hideDate ? ' hidden-left' : ''}`,
		[isMobileOrTablet, hideDate],
	);

	return (
		<div className={className}>
			{!hideDate ? (
				<div className="orders-top-bar__column">
					<ButtonWithIcon
						icon={<ChevronLeft size="small" />}
						onClick={() => setSelectedDate((prevState) => prevState.subtract(1, 'day'))}
						data-testid="previous-day-button"
						disabled={isDisabledDate(selectedDate.subtract(1, 'day'))}
					/>
					<div className="orders-top-bar__date">
						<div className="orders-top-bar__date-display">
							{!isMobile ? `${selectedDate.format('dddd')} - ` : ''}
							{selectedDate.format('M/D/YYYY')}
						</div>
						<DatePicker
							allowClear={false}
							onChange={handleDateChagne}
							onOpenChange={setDatePickerOpen}
							value={selectedDate}
							className={`orders-top-bar__date-picker${isDatePickerOpen ? ` open` : ''}`}
							suffixIcon={<CalendarOutlined size="small" />}
							disabledDate={isDisabledDate}
							showToday={false}
							getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
						/>
					</div>
					<ButtonWithIcon
						icon={<ChevronRight size="small" />}
						onClick={() => setSelectedDate((prevState) => prevState.add(1, 'day'))}
						data-testid="next-day-button"
						disabled={isDisabledDate(selectedDate.add(1, 'day'))}
					/>
					<Button icon={<CalendarOutlined />} disabled={isToday} onClick={() => setSelectedDate(dayjs().tz('UTC', true))}>
						{isMobile ? 'Today' : 'Go to today'}
					</Button>
				</div>
			) : null}
			<div className="orders-top-bar__column">
				<span className="switch-label"> {isMobile ? 'Map' : 'Show area map'}</span>
				<Switch size="small" checked={isMapVisible} onChange={setIsMapVisible} />
			</div>
		</div>
	);
};

export default TopBar;
