import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { MFContext, getSharedComponent } from '@4r/mf-host';
import { useConfig } from '@4r/mf-app';
import { UserContext } from '../../contexts/UserContext';
import { AuthContext } from '../../authentication/AuthProvider';

import style from './PropertyLifecycleDetailsPage.module.scss';
import './PropertyLifecycleDetailsPage.scss';

const PropertyLifecycleDetailsPage = () => {
	const { LifecycleMicrofrontend } = React.useContext(MFContext);
	const config = useConfig('LifecycleMicrofrontend');
	const { id } = useParams<{ id: string }>();

	if (!LifecycleMicrofrontend) {
		return null;
	}
	const LifecycleDetailsPage = getSharedComponent('LifecycleDetailsPage');
	if (!LifecycleDetailsPage) {
		return null;
	}

	const user = useContext(UserContext);
	const { getUser } = useContext(AuthContext);
	const currentUser = getUser();

	return (
		<div className={style['lifecycle-detail-page']}>
			<LifecycleDetailsPage
				config={config[0]}
				lifecycleId={id}
				currentUser={user.current}
				currentUserName={currentUser ? `${currentUser.given_name} ${currentUser.family_name}` : undefined}
			/>
		</div>
	);
};

export default PropertyLifecycleDetailsPage;
