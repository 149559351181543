import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { AccessContext } from '@4r/module-common-authorization';
import { MFContext } from '@4r/mf-host';
import { useConfig } from '@4r/module-common-mf-app';

const VendorPage: React.FunctionComponent = () => {
	const { VendorMicrofrontend } = useContext(MFContext);

	const access = useContext(AccessContext);
	const settings = useConfig();

	if (!VendorMicrofrontend) {
		return null;
	}

	return (
		<div className="relative page dashboard-page">
			<VendorMicrofrontend currentUser={access.user} googleMapKey={settings?.GOOGLE_MAP_KEY} />
		</div>
	);
};

export default withRouter(VendorPage);
