/* eslint-disable no-console */
/* eslint-disable no-useless-constructor */
/* eslint-disable no-empty-function */

export enum LogLevel {
	DEBUG = 1,
	INFO = 2,
	WARN = 3,
	ERROR = 4,
}

class LoggerService {
	constructor(private readonly name: string, private readonly level: LogLevel = LogLevel.INFO) {}

	logDebug(msg: string, ...args: any[]) {
		this.log(LogLevel.DEBUG, msg, ...args);
	}

	logInfo(msg: string, ...args: any[]) {
		this.log(LogLevel.INFO, msg, ...args);
	}

	logWarn(msg: string, ...args: any[]) {
		this.log(LogLevel.WARN, msg, ...args);
	}

	logError(msg: string, ...args: any[]) {
		this.log(LogLevel.ERROR, msg, ...args);
	}

	private log(level: LogLevel, msg: string, ...args: any[]) {
		if (this.level >= level) {
			const m = `${this.name}: ${msg}`;

			switch (level) {
				case LogLevel.WARN:
					console.warn(m, ...args);
					break;
				case LogLevel.ERROR:
					console.error(m, ...args);
					break;
				case LogLevel.INFO:
					console.info(m, ...args);
					break;
				default:
					console.log(m, ...args);
					break;
			}
		}
	}
}

export default LoggerService;
