import { AccessContext } from '@4r/module-common-authorization';
import { Col, Row, Space, Spin, Tabs } from 'antd';
import React, { useContext, useMemo } from 'react';
import { useApiLoad, useApiClient, useQueryString } from '@4r/module-common-mf-app';
import { NotificationClient, UserModel } from '../../api/api';
import usePageName from '../../common/use-page-name';
import Notification from './Notification';

enum Tab {
	All = 'all',
	Pinned = 'pinned',
}

const VendorHomePage: React.FC = () => {
	const access = useContext(AccessContext);
	const user = access.user as UserModel;
	const vendor = user?.vendor;

	usePageName(vendor?.name ?? '', vendor?.vendorNo);

	const apiClient = useApiClient(NotificationClient);
	const [notifications, isLoading] = useApiLoad(
		() => (access.loaded ? apiClient.get() : Promise.resolve([])),
		[access.loaded && vendor?.id],
	);

	const [tabId, setTabId] = useQueryString('tabId', Tab.All, { allowedValues: [Tab.All, Tab.Pinned] });

	const filteredNotifications = useMemo(() => {
		switch (tabId) {
			case Tab.Pinned:
				return notifications?.filter((x) => x.isPinned || x.isPinnedByUser);
			case Tab.All:
			default:
				return notifications;
		}
	}, [notifications, tabId]);

	return filteredNotifications ? (
		<>
			<Tabs activeKey={tabId} onChange={(key) => setTabId(key)}>
				<Tabs.TabPane tab="All" key={Tab.All} />
				<Tabs.TabPane tab="Pinned" key={Tab.Pinned} />
			</Tabs>
			{!access.loaded || isLoading ? (
				<Row justify="center">
					<Spin size="large" />
				</Row>
			) : (
				<Row gutter={[16, 16]}>
					<Col xs={{ span: 24 }}>
						<Space direction="vertical" style={{ display: 'flex' }}>
							{filteredNotifications?.map((x) => (
								<Notification key={x.id} notification={x} />
							))}
						</Space>
					</Col>
				</Row>
			)}
		</>
	) : null;
};

export default VendorHomePage;
