import { AccessContext } from '@4r/module-common-authorization';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../authentication/AuthProvider';
import VendorHomePage from './VendorHomePage';

const HomePage: React.FunctionComponent = () => {
	const { isAuthenticated } = useContext(AuthContext);

	const access = useContext(AccessContext);
	const history = useHistory();

	React.useEffect(() => {
		if (access.loaded && access.permissions.length === 0) {
			history.push('/no-access');
		}
	}, [access, isAuthenticated]);

	if (!isAuthenticated) {
		return null;
	}

	return <VendorHomePage />;
};

export default HomePage;
