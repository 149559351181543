import React, { useContext, useEffect, useMemo } from 'react';
import { MFContext, getService } from '@4r/mf-host';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isBetween from 'dayjs/plugin/isBetween';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import CurrentOrderIdService from '../../services/CurrentOrderIdService';
import { PageStatus } from '../../state/Actions';
import { OfflineOrderContext } from '../../contexts/OfflineOrderContext';
import { UserContext } from '../../contexts/UserContext';
import { ReferenceDataContext } from '../../contexts/ReferenceDataContext';
import { AuthContext } from '../../authentication/AuthProvider';
import { useGlobalState } from '../../state/Store';

import './WorkOrderPage.scss';

dayjs.extend(utc);
dayjs.extend(localizedFormat);
dayjs.extend(isBetween);

const Listing: React.FunctionComponent = () => {
	const { WorkOrderMicrofrontend } = useContext(MFContext);
	const { id } = useParams<{ id: string }>();
	const { orders, orderPersisterService, reload } = useContext(OfflineOrderContext);
	const referenceData = useContext(ReferenceDataContext);
	const [onlineStatus] = useGlobalState('online');

	const isOnline = useMemo(() => onlineStatus !== PageStatus.offline, [onlineStatus]);
	const user = useContext(UserContext);
	const { getUser } = useContext(AuthContext);
	const currentUser = getUser();

	const currentOrderIdService = getService('CurrentOrderIdService') as CurrentOrderIdService;

	useEffect(() => {
		currentOrderIdService.setCurrentOrderId(id);
		return () => currentOrderIdService.setCurrentOrderId(null);
	});

	if (!WorkOrderMicrofrontend) return null;

	return (
		<WorkOrderMicrofrontend
			workOrderId={id}
			orders={orders.map((o) => o.order)}
			workOrders={orders}
			reloadOrders={reload}
			user={user.current}
			vendorId={user.current?.vendor?.id}
			online={isOnline}
			orderPersisterService={orderPersisterService}
			hostReferenceData={referenceData}
			currentUserName={currentUser ? `${currentUser.given_name} ${currentUser.family_name}` : undefined}
		/>
	);
};

export default Listing;
