import React, { useContext, useMemo } from 'react';
import { ContactInfo } from '@4r/module-common-ant-components';
import { useLocation } from 'react-router-dom';
import { useApiClient, useApiLoad } from '@4r/module-common-mf-app';
import { Layout } from 'antd';
import { UmbracoContentClient } from '../api/api';
import { useGlobalState } from '../state/Store';
import { UserContext } from '../contexts/UserContext';
import { ReactComponent as LogoFullSVG } from '../assets/svg/logo_full.svg';

import './Footer.scss';

const Footer = () => {
	const [version] = useGlobalState('version');

	const user = useContext(UserContext);
	const location = useLocation();
	const showContactInfo = useMemo(
		() => !!user.current?.vendor && ['/', '/vendor-user-orders', '/vendor-admin-orders'].includes(location.pathname),
		[user, location],
	);
	const umbracoApiClient = useApiClient(UmbracoContentClient);
	const [info] = useApiLoad(
		() => (showContactInfo ? umbracoApiClient.getVendorOfficeInfo() : Promise.resolve(undefined)),
		[showContactInfo],
	);

	return (
		<>
			<Layout.Footer style={{ textAlign: 'center', background: '#F8F8F8' }}>
				{showContactInfo && info && (
					<ContactInfo
						title="Contact Us"
						logo={<LogoFullSVG style={{ alignItems: 'baseline' }} />}
						email={info.email}
						phone={info.phone}
						hours={info.shortBusinessHours}
						className="footer__contact-info"
					/>
				)}
				<div className="footer__item footer__item--version">Version {version}</div>
			</Layout.Footer>
		</>
	);
};

export default Footer;
