import { ColumnFilterItem } from 'antd/lib/table/interface';

const emptyText = '(empty)';

export function getPropertyFilters<T>(array: T[], getProperty: (x: T) => string | number | boolean | null | undefined): ColumnFilterItem[] {
	return Array.from(new Set(array.map((x) => getProperty(x) ?? emptyText)))
		.sort()
		.map((x) => ({ text: x, value: x }));
}

export function getOnFilterFn<T>(
	getProperty: (x: T) => string | number | boolean | null | undefined,
): (value: string | number | boolean, item: T) => boolean {
	return (value, item) => value === (getProperty(item) ?? emptyText);
}