import { Badge, Tabs } from 'antd';
import './Tabs.scss';
import React, { PropsWithChildren, ReactElement } from 'react';

export type TabDesc<T> = { name: string; id: T; badgeCount?: number };

type FilterTabsProps<T> = {
	tabs: TabDesc<T>[];
	selected: string;
	className?: string;
	onChanged(id: string): void;
};

export const FilterTabs = <T extends string>(props: PropsWithChildren<FilterTabsProps<T>>): ReactElement | null => {
	const { tabs, selected, className, onChanged, children } = props;
	return (
		<>
			<Tabs activeKey={selected} onChange={(id) => onChanged(id)} className={className}>
				{tabs.map((t) => (
					<Tabs.TabPane
						key={t.id}
						tab={
							<>
								{t.name}
								<Badge count={t.badgeCount} offset={[2, -14]} />
							</>
						}
					/>
				))}
			</Tabs>
			{children}
		</>
	);
};
