import React, { useContext, useEffect, useMemo, useState } from 'react';
import { IDBPDatabase } from 'idb';
import { getService } from '@4r/mf-app';
import { CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useConfig } from '@4r/module-common-mf-app';
import { OfflineEvents } from '../services/Offline/BaseOfflineCapableService';
import ServiceLocator from '../services/ServiceLocator';
import { AuthContext } from '../authentication/AuthProvider';
import { OfflineOrderProvider } from './OfflineOrderContext';
import { CommandTitle, CommandType } from '../services/CommandTypes';
import LoadingPlaceholder from '../components/LoadingPlaceholder/LoadingPlaceholder';
import { NotificationContext } from './NotificationContext';
import StoreName from '../services/StoreName';
import CommandQueueService from '../services/Offline/CommandQueueService';

export enum ICommandStatus {
	Pending = 'Pending',
	Syncing = 'Syncing',
}

export interface IPayload<T> {
	commandTitle: CommandTitle;
	commandType: CommandType;
	payload: T;
	orderId?: string | null;
}

interface IOfflineContext {
	db: void | IDBPDatabase<unknown> | null;
	commandQueues: Record<string, CommandQueueService>;
	setIsLoaded: React.Dispatch<React.SetStateAction<boolean>>;
	isLoading?: boolean;
	setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialData: IOfflineContext = {
	db: null,
	commandQueues: {} as Record<string, CommandQueueService>,
	setIsLoaded: () => null,
};

const OfflineContext = React.createContext<IOfflineContext>(initialData);

const OfflineProvider: React.FunctionComponent = ({ children }) => {
	const notificationContext = useContext(NotificationContext);
	const [db, setDb] = useState<void | IDBPDatabase<unknown> | null>(null);
	const [isLoaded, setIsLoaded] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const settings = useConfig();
	const { authService, isAuthenticated } = useContext(AuthContext);
	const [serviceLocator, setServiceLocator] = useState<ServiceLocator | undefined>(undefined);

	useEffect(() => {
		const initialize = async () => {
			if (authService && settings) {
				const sl = new ServiceLocator(authService, settings);
				setServiceLocator(sl);

				const dbHandle = await sl.init();
				setDb(dbHandle);
			}
		};
		initialize();
	}, [settings, authService]);

	const value = useMemo(() => {
		const commandQueues: Record<string, CommandQueueService> = {};
		if (serviceLocator && serviceLocator.commandsQueueService) {
			commandQueues[StoreName.Commands] = serviceLocator.commandsQueueService;
		}
		if (serviceLocator && serviceLocator.fileCommandsQueueService) {
			commandQueues[StoreName.FileCommands] = serviceLocator.fileCommandsQueueService;
		}
		return {
			...initialData,
			db,
			commandQueues,
			setIsLoaded,
			serviceLocator,
			isLoading,
			setIsLoading,
		};
	}, [initialData, db, serviceLocator, isLoading]);

	useEffect(() => {
		const messageBus = getService('MessageBusService');

		const offlineSyncingError = messageBus.on(OfflineEvents.QueueStopEvent, ({ detail }) => {
			notificationContext?.open({
				message: `Error during syncing (${detail})`,
				description: `One of the offline operation, image or video failed to sync. Please check the sync status area on the left for more details.`,
				duration: 10,
				closeIcon: <CloseOutlined />,
				className: 'toastNotification',
				icon: <ExclamationCircleOutlined style={{ fontSize: '18px', color: '#e32d20' }} />,
			});
		});

		return () => {
			offlineSyncingError.removeListener();
		};
	});

	return (
		<OfflineContext.Provider value={value}>
			<OfflineOrderProvider>{isLoaded || !isAuthenticated ? children : <LoadingPlaceholder />}</OfflineOrderProvider>
		</OfflineContext.Provider>
	);
};

export { OfflineProvider, OfflineContext };
