import React, { ReactElement } from 'react';
import { Tabs } from 'antd';
import StatusTab from './StatusTab';
import './StatusPage.scss';

const StatusPage = (): ReactElement => (
	<Tabs defaultActiveKey="1">
		<Tabs.TabPane tab="Status" key="1">
			<StatusTab />
		</Tabs.TabPane>
	</Tabs>
);

export default StatusPage;
