import { GeoCoordinatesModel } from '@4r/mf-contracts-4services/dist/api/api';
import { SignalRHub } from '@4r/module-common-signalr';
import { utcNow } from '@4r/module-common-mf-app';

export type ResourceGeoCoordinatesNotificationModel = {
	resourceId: string;
	geoCoordinates: GeoCoordinatesModel;
};

export type NotifyGeoCoordinatesChangeHandler = (message: ResourceGeoCoordinatesNotificationModel) => void;

export class CurrentGeocoordinatesHub extends SignalRHub {
	static id = 'CurrentGeocoordinatesHub';

	async updateCurrentGeoCoordinates(latitude: number, longitude: number): Promise<void> {
		const request = {
			latitude,
			longitude,
			timestamp: utcNow(),
		};

		await this.call('UpdateCurrentGeoCoordinates', request);
	}

	async subscribeToNotifyGeoCoordinatesChange(): Promise<void> {
		await this.call('SubscribeToNotifyGeoCoordinatesChange', null);
	}

	async unsubscribeFromNotifyGeoCoordinatesChange(): Promise<void> {
		await this.call('UnsubscribeFromNotifyGeoCoordinatesChange', null);
	}

	onNotifyGeoCoordinatesChange(handler: NotifyGeoCoordinatesChangeHandler): (() => void) | null {
		return this.on('NotifyGeoCoordinatesChange', handler);
	}
}
