import React from 'react';
import { Badge } from 'antd';

interface Props {
	status: string;
}

const ReviewStatus: React.FC<Props> = ({ status }: Props) => {
	if (status === 'Approved') {
		return <Badge status="success" text={status} />;
	}
	if (status === 'Rejected') {
		return <Badge status="error" text={status} />;
	}
	return <Badge status="default" text={status} />;
};

export default ReviewStatus;
