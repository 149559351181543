// eslint-disable-next-line max-classes-per-file
import { IDBPDatabase } from 'idb';
import { v4 as uuid } from 'uuid';
import { IStorageService, StorageMutator, StorageObserver } from '@4r/mf-contracts-4services';
import StoreName from '../../StoreName';
import deepEqual from './deepEqual';

class IndexedDbStorageService {
	// eslint-disable-next-line no-useless-constructor, no-empty-function
	constructor(private db: IDBPDatabase<unknown>) {}

	async set<T>(key: string, value: T): Promise<void> {
		await this.db.put(StoreName.Data, value, key);
	}

	get<T>(key: string): Promise<T | undefined> {
		return this.db.get(StoreName.Data, key);
	}

	remove(key: string): Promise<void> {
		return this.db.delete(StoreName.Data, key);
	}
}

type Subscription = { id: string; observer: StorageObserver<unknown>; key?: string };

class StorageServiceAdapter implements IStorageService {
	private readonly storageService: IndexedDbStorageService;

	private readonly recordById: Record<string, unknown> = {};

	private recordVersionToken: string;

	private readonly subscriptions: Subscription[] = [];

	/**
	 *
	 */
	constructor(db: IDBPDatabase<unknown>) {
		this.storageService = new IndexedDbStorageService(db);
		this.recordVersionToken = uuid();
	}

	get versionToken(): string {
		return this.recordVersionToken;
	}

	getVersion(key: string): unknown {
		return this.recordById[key];
	}

	private async tryLoad<T>(key: string, load: () => Promise<T | undefined>): Promise<T | undefined> {
		let value: T | undefined;
		try {
			value = await load();
		} catch (error) {
			const event = error as Response;

			if (event.status) {
				// rethrow HTTP error
				throw error;
			}

			// the app is offline
		}
		if (value !== undefined) {
			const existingValue = this.recordById[key] as T | undefined;
			// We prevent from updating with the same value if the records are equal.
			// This will avoid useEffect and useMemo retriggers
			if (deepEqual(existingValue, value)) {
				return existingValue;
			}
			await this.set<T>(key, value);
		}
		return value;
	}

	async loadOrGet<T>(key: string, load: () => Promise<T | undefined>): Promise<T | undefined> {
		let value = await this.tryLoad<T>(key, load);
		if (value !== undefined) {
			return value;
		}
		value = await this.get<T>(key);
		return value;
	}

	async getOrLoad<T>(key: string, load: () => Promise<T | undefined>, refresh?: boolean): Promise<T | undefined> {
		let value = await this.get<T>(key);
		if (value !== undefined) {
			if (refresh) {
				// no await, in the background refresh the record if possible
				this.tryLoad<T>(key, load);
			}
			return value;
		}
		value = await this.tryLoad<T>(key, load);
		return value;
	}

	async get<T>(key: string): Promise<T | undefined> {
		// try get from memory first
		let value = this.recordById[key] as T | undefined;
		if (value === undefined) {
			// if not then try get from indexedDB
			value = await this.storageService.get(key);
			if (value) {
				this.recordById[key] = value;
				this.notifyChanged(key);
			}
		}
		return value;
	}

	async upsert<T>(key: string, mutator: StorageMutator<T>): Promise<void> {
		const value = await this.get<T>(key);
		const newValue = mutator(value);
		if (newValue !== undefined) {
			await this.set<T>(key, newValue);
		}
	}

	async set<T>(key: string, value: T): Promise<void> {
		await this.storageService.set(key, value);
		this.recordById[key] = value;
		this.notifyChanged(key);
	}

	async remove(key: string): Promise<void> {
		await this.storageService.remove(key);
		delete this.recordById[key];
		this.notifyChanged(key);
	}

	private notifyChanged(key: string) {
		// generate a new version token
		this.recordVersionToken = uuid();

		const value = this.recordById[key];
		for (let i = 0; i < this.subscriptions.length; i++) {
			const subscription = this.subscriptions[i];
			if (subscription.key === undefined || subscription.key === key) {
				subscription.observer(key, value);
			}
		}
	}

	subscribe<T>(observer: StorageObserver<T>, key?: string): () => void {
		const subscription: Subscription = {
			id: uuid(),
			observer: observer as StorageObserver<unknown>,
			key,
		};
		this.subscriptions.push(subscription);

		// return unsubscribe function
		return () => this.unsubscribe(subscription.id);
	}

	private unsubscribe(subscriptionId: string): void {
		const i = this.subscriptions.findIndex((x) => x.id === subscriptionId);
		if (i !== -1) {
			this.subscriptions.splice(i, 1);
		}
	}
}

export default StorageServiceAdapter;
