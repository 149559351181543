import {
	COLOR,
	CardHeader,
	CardHeaderAlwaysMobile,
	Pill,
	HouseholdIdOutlined,
	ArticleOutlined,
	CalendarOutlined,
	HandymanOutlined,
	OpenInNew,
} from '@4r/module-common-ant-components';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { ConditionIssueInstanceModel, OrderModel } from '../../api/api';
import ConditionIssuesTooltip from '../../components/ConditionIssueTooltip/ConditionIssuesTooltip';
import { DATE_FORMAT, NONE, TIME_FORMAT } from './common';
import getCurrentBooking from '../../common/orders';

interface OrderCardProps {
	order: OrderModel;
	cCIs: ConditionIssueInstanceModel[];
	isMobile?: boolean;
}

const ComponentsTooltip = ({ order, cCIs, isMobile }: OrderCardProps) => (
	<>
		{isMobile ? <HandymanOutlined /> : null}
		<ConditionIssuesTooltip orderId={order.id} conditionIssues={cCIs} count={order.numberOfConditionIssues ?? 0} />
	</>
);

const OrderCard = (props: OrderCardProps) => {
	const { order, cCIs, isMobile } = props;

	const Card = useMemo(() => (isMobile ? CardHeaderAlwaysMobile : CardHeader), [isMobile]);

	const address = useMemo(
		() =>
			`${order.property.address.street}, ${order.property.address.city}, ${order.property.address.state} ${order.property.address.zipCode}`,
		[order.property],
	);
	const currentBooking = useMemo(() => getCurrentBooking(order.statusId, order.bookings), [order]);
	const appointment = useMemo(
		() =>
			`${dayjs(currentBooking?.startTime).format(DATE_FORMAT)} ${dayjs(currentBooking?.startTime).format(TIME_FORMAT)} - ${dayjs(
				currentBooking?.endTime,
			).format(TIME_FORMAT)}`,
		[currentBooking],
	);

	return order.typeName === NONE ? (
		<Card
			title={order.name}
			cardType="CS"
			className="order-list-mobile__card"
			address={order.description}
			infoData={[
				{
					label: 'Scheduled',
					icon: <CalendarOutlined />,
					value: appointment,
					className: isMobile ? 'order-list-mobile__schedule-mobile' : '',
				},
			]}
		/>
	) : (
		<Card
			title={order.name}
			cardType="WO"
			address={address}
			className="order-list-mobile__card"
			statusName={<Pill.Generic label={order.statusName} size="small" fillColor={COLOR.Blue} />}
			subStatusName={
				order.subStatusName ? (
					<Pill.Generic
						label={order.subStatusName}
						size="small"
						color={COLOR.DarkBlue}
						borderColor={COLOR.Blue}
						fillColor={COLOR.LightBlue}
					/>
				) : undefined
			}
			infoData={[
				{
					label: 'Property ID',
					icon: <HouseholdIdOutlined />,
					value: order.property.propertyNo ?? '',
				},
				{
					label: 'Type',
					icon: <ArticleOutlined />,
					value: order.typeName,
				},
				{
					label: 'Issues',
					value: '',
					icon: <ComponentsTooltip order={order} cCIs={cCIs} isMobile={isMobile} />,
					className: isMobile ? 'order-list-mobile__issues-mobile' : '',
					disableTooltip: true,
				},
				{
					label: 'Scheduled',
					icon: <CalendarOutlined />,
					value: appointment,
					className: isMobile ? 'order-list-mobile__schedule-mobile' : '',
				},
			]}
		>
			<Link to={`/orders/${order.id}`} style={{ padding: 16 }}>
				<OpenInNew />
			</Link>
		</Card>
	);
};

export default OrderCard;
